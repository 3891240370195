<template>
    <div class="d-lg-flex signin-layout">
        <div class="h-100 d-lg-flex flex-column justify-content-center h-100">
            <verify-form ref="verifyForm" :form="form" :callingcodes="callingcodes" :redirectPath="url(redirectPath)"
                :event="event" @goToLoginForm="goToLoginForm" v-if="isVerifyOTP" />
            <div v-else class="form-signin">
                <div class="text-center">
                    <h1 class="mb-3 fw-700" @click="autoComplete">{{ $t('Create New Account') }}</h1>
                    <p class="text-muted">{{ $t('Already a member') }} ? <a class="fw-600" :href="url('/login')">{{
                            $t('Login')
                    }}</a></p>
                </div>

                <div class="signin-section d-flex flex-row justify-content-center">
                    <form @submit.prevent="singup" class="signin-with-email">

                        <div class="mb-3">
                            <label for="firstName">{{ $t('First Name') }}</label>
                            <input id="firstName" type="text" required="required" autocomplete="off"
                                class="form-control form-control-lg" v-model="form.firstName"
                                :class="[isLoad && `disabled`, errors.firstName && `is-invalid`]" :disabled="isLoad" />

                            <div class="invalid-feedback" v-if="errors.firstName">{{ errors.firstName }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="lastName">{{ $t('Last Name') }}</label>
                            <input id="lastName" type="text" autocomplete="off" required="required"
                                class="form-control form-control-lg" v-model="form.lastName"
                                :class="[isLoad && `disabled`, errors.lastName && `is-invalid`]" :disabled="isLoad" />

                            <div class="invalid-feedback" v-if="errors.lastName">{{ errors.lastName }}</div>
                        </div>

                        <div class="mb-3">
                            <label for="email">{{ $t('email') }}</label>
                            <input id="email" type="text" required="required" autocomplete="off"
                                class="form-control form-control-lg" v-model="form.email"
                                :class="[isLoad && `disabled`, errors.email && `is-invalid`]" :disabled="isLoad" />

                            <div class="invalid-feedback" v-if="errors.email">{{ errors.email }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="new-pass">{{ $t('password') }}</label>
                            <input ref="inputPass" id="new-pass" type="password" required="required"
                                autocomplete="new-password" class="form-control form-control-lg" v-model="form.password"
                                :class="[isLoad && `disabled`, errors.password && `is-invalid`]" :disabled="isLoad" />

                            <div class="invalid-feedback" v-if="errors.password">{{ errors.password }}</div>
                        </div>

                        <button type="submit" class="btn btn-lg btn-primary btn-block mt-4"
                            :class="[isLoad && `disabled`, isError && `btn-error`]" :disabled="isLoad">
                            <span class="text-white fw-600">{{ isLoad ? `${$t('Loading')}...` : $t('Create Account')
                            }}</span>
                        </button>

                        <div class="signin-section-ro my-4 text-center fs-12 fw-700" v-if="loginWithSocial">
                            <span class="line"></span>
                            <span class="text">{{ $t('Or') }}</span>
                            <span class="line"></span>
                        </div>

                        <login-social-form :event="event" v-if="loginWithSocial" :isLoad="isLoad" />

                        <div class="text-muted mt-3 fs-12 text-center">
                            <p class="mb-0 fs-90p">การดำเนินการต่อหมายความว่าคุณยอมรับ<a href="https://educathai.com/privacy-policy"
                                    target="_blank">เงื่อนไขการให้บริการ</a>ของ Educa และรับทราบว่าคุณได้อ่าน<a
                                    :href="url('/privacy-policy')" target="_blank">นโยบายส่วนบุคคล</a>แล้ว</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VerifyForm from '../components/VerifyOtpFormAfterRegister'
import LoginSocialForm from '../components/AuthWithSocial'

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export default {
    name: 'RegisterPage',
    props: ['event', 'baseUrl', 'loginWithSocial'],

    components: { VerifyForm, LoginSocialForm },

    data() {
        return {
            form: {
                email: "",
                password: "",
                firstName: "",
                lastName: "",
            },

            error: null,
            isLoad: false,
            redirectPath: '/',

            callingcodes: [],

            isVerifyOTP: false,

            errors: {},
            isError: false,
        };
    },

    async mounted() {
        await this.getCountryCode()
    },

    methods: {
        url(path) {
            return `/${this.event.slug}${path}`
        },

        async getCountryCode() {
            const { data: response } = await axios.get('/api/country/code')

            if (response.success) {
                this.callingcodes = response.data
            }
        },

        async singup() {
            this.isLoad = true
            this.isError = false
            this.errors = {}

            let validated = this.registerValidator()

            if (validated) {
                validated = await this.emailValidator()
            }

            if (validated) {
                this.isVerifyOTP = true
                this.isLoad = false
                return true
            }

            this.isLoad = false
            this.isError = true
        },

        registerValidator() {
            const form = this.form

            if (form.firstName == '') {
                this.errors.firstName = "กรุณาระบุชื่อ"
            }

            if (form.lastName == '') {
                this.errors.lastName = "กรุณาระบุนามสกุล"
            }

            if (form.email == '') {
                this.errors.email = "กรุณาระบุอีเมล"
            }
            else if (!REGEX_EMAIL.test(form.email)) {
                this.errors.email = "อีเมลไม่ถูกต้อง"
            }


            if (form.password == '') {
                this.errors.password = "กรุณาระบุรหัสผ่าน"
            }
            else if (form.password < 6) {
                this.errors.password = "รหัสผ่านต้องมี 6 ตัวอักษรขึ้นไป"
            }

            return Object.keys(this.errors).length == 0;
        },

        async emailValidator() {
            try {
                await axios.post('/api/verify/check/email', { email: this.form.email })
                return true
            } catch (error) { }

            this.errors.email = "ไม่สามารถใช้อีเมลนี้ได้"
            return false
        },

        autoComplete(e) {
            if (!e.ctrlKey || !e.shiftKey) return false;

            // this.form = {
            //     email: "monkey.d.chong@gmail.com",
            //     password: "123456",
            //     firstName: "Puchong",
            //     lastName: "Suanjang",
            // }
        },

        goToLoginForm() {

            this.isError = false
            this.isVerifyOTP = false
        }
    }
}
</script>