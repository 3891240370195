<template>
    <div class="info-section">
        <div class="card card-info mb-4">
            <div class="card-header">
                <h3>Communication Channel</h3>
            </div>
            <div class="card-body">

                <div class="mb-3">
                    <label class="font-weight-bold d-flex">
                        <span class="mr-1">Newsletter Subscription / การรับข่าวสารจาก EDUCA</span>
                        <span class="text-danger">*</span>
                    </label>

                    <div :class="[errors.subscription && `is-invalid`]">
                        <div class="custom-control custom-radio mb-1" v-for="(item, index) in subscriptionList"
                            :key="index">
                            <input type="radio" :id="`subscription-${item.id}`" :value="item.id"
                                class="custom-control-input" v-model="form.subscription">
                            <label :for="`subscription-${item.id}`" class="custom-control-label">{{ item.name }}</label>
                        </div>
                    </div>
                    <div class="invalid-feedback" v-if="errors.subscription">{{ errors.subscription }}
                            </div>
                </div>
                <div v-if="form.subscription">
                    <label class="font-weight-bold d-flex mb-0">
                        <span class="mr-1">Newsletter Channel / ช่องทางการรับข่าวสาร</span>
                        <span class="text-danger">*</span>
                    </label>
                    <div class="text-muted mb-2">(you can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)</div>

                    <div class="d-flex" :class="[errors.subscribes && `is-invalid`]">
                        <div class="custom-control custom-checkbox mr-3" v-for="(item, index) in channelList"
                            :key="index">
                            <input type="checkbox" :id="`channel-${item.id}`" :value="item.id"
                                class="custom-control-input" v-model="form.subscribes">
                            <label :for="`channel-${item.id}`" class="custom-control-label">{{ item.name }}</label>
                        </div>
                    </div>

                    <div class="invalid-feedback" v-if="errors.subscribes">{{ errors.subscribes }}
                            </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['form', 'config', 'provinces', 'event', 'errors'],
    data() {
        const subscriptionList = [];
        subscriptionList.push({ id: 1, name: "Newsletter Subscription / ต้องการรับข่าวสาร" });
        subscriptionList.push({ id: 0, name: "I prefer not to receive newsletter / ไม่ต้องการรับข่าวสาร" });

        const channelList = [];
        channelList.push({ id: 1, name: "Email" });
        channelList.push({ id: 2, name: "SMS" });

        return {
            // -- step 4
            subscriptionList,
            channelList,
        }
    },
    methods: {

    }
}
</script>