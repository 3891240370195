<template>
    <div class="review-comment-container">

        <div v-if="windowWidth < minWidth" class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <span class="text-uppercase text-secondary font-weight-bold">ความคิดเห็น ({{commentTotal}})</span>
            </div>

            <div>
                <a href="#" class="d-flex align-items-center review-comment-link" @click.prevent="isComport=true"><i class="fa fa-pencil"></i><span class="ml-2">เขียนแสดงความคิดเห็น</span></a>
            </div>
        </div>

        <div class="review-comment-comport" v-if="checkComport" :class="windowWidth < minWidth && 'review-comment-comport-overlay'">

            <div class="review-comment-comport-back" v-if="windowWidth < minWidth" @click="isComport=false"></div>

            <div class="review-comment">
                <!-- <div class="review-comment-left" v-if="windowWidth >= minWidth">
                    <div class="review-comment-reviewer mb-1">
                        <strong>เขียนแสดงความคิดเห็น</strong>
                    </div>
                </div> -->

                <div class="review-comment-wrap">

                    <div class="review-comment-bubble">
                        <!-- <div class="review-comment-comport-title border-bottom p-2 mb-2">
                            <h3 class="fs-16 font-weight-bold">เขียนแสดงความคิดเห็น</h3>
                        </div> -->

                        <div class="review-comment-comport-wrap" :class="focusComport&&`focused`">
                            <div class="mb-2">
                                <textarea-autosize class="form-control" rows="2" placeholder="เขียนแสดงความคิดเห็น..." v-model="comport.text" @focus.native="focusComport=true" @blur.native="focusComport=false"></textarea-autosize>
                            </div>

                            <div class="review-comment-comport-footer d-md-flex justify-content-between align-items-center">
                                <div class="d-flex rangestart-wrap align-items-center mb-3 mb-md-0"></div>
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-light w-min-150 mr-2" v-if="windowWidth < minWidth" @click="isComport=false">ยกเลิก</button>
                                    <button class="btn btn-primary w-min-150" :disabled="disabledSave" @click="save">แสดงความคิดเห็น</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="review-comment-results">
            <div class="review-comment" v-for="comment in comments" :key="comment.id">
                <!-- <div class="review-comment-left" v-if="windowWidth >= minWidth">

                    <div class="review-comment-reviewer" v-if="comment.member">
                        <strong>{{ comment.member.prefix }}{{ comment.member.first_name }} {{ comment.member.last_name }}</strong>
                    </div>

                    <div class="review-comment-reviewer">
                        <span class="text-muted fs-13">{{ date_at(comment.created_at) }}</span>
                    </div>
                </div> -->

                <div class="review-comment-wrap">
                    <div class="review-comment-bubble">

                        <div class="review-comment-meta">
                            <span class="author">{{ comment.author }} <span class="badge" :class="[comment.badge=='Speaker'&&`speaker`, comment.badge=='Exhibitor'&&`exhibitor`]" v-if="comment.badge">{{ comment.badge }}</span></span><span class="mx-1">·</span><span class="date">{{ comment.dateStr }}</span>
                        </div>
                        <!-- <strong v-if="windowWidth < minWidth">{{ comment.member.prefix }}{{ comment.member.first_name }} {{ comment.member.last_name }}</strong>
                        <div class="review-comment-bodyTitle">
                            <div v-if="windowWidth < minWidth" class="d-flex justify-content-between fs-13 my-1 border-bottom pb-1">
                                <div><span class="text-muted">{{ date_at(comment.created_at) }}</span></div>
                                <div><span class="text-muted">{{ comment.score }} คะแนน</span></div>
                            </div>
                        </div> -->
                        <div class="review-comment-body">{{ comment.body }}</div>

                        
                        <Reply :learningId="learningId" :comment-id="comment.id" :replies="comment.replies" />
                    </div>

                   
                    
                </div>
            </div>

            <div v-if="!showall && commentTotal>commentLimitStart" @click="onShowall"  class="d-flex justify-content-center mt-4"><button type="button" class="btn btn-outline-primary w-min-200 border-dashed">แสดงความคิดเห็นทั้งหมด...</button> </div>
        </div>

        <!-- <div class="d-flex justify-content-center" v-if="showall">
            <sliding-pagination v-if="totalPages > 1"
                :current="currentPage"
                :total="totalPages"
                @page-change="pageChangeHandler"
            ></sliding-pagination>
        </div> -->
    </div>
</template>

<script>

import Reply from './Reply.vue'

export default {
    components: { Reply },
    props: ['learningId'],
    
    data(){
        return {

            rangestarts: [
                {id: 1, name: 'ไม่ชอบที่สุด'},
                {id: 2, name: 'ไม่ชอบ'},
                {id: 3, name: 'พอใช้ได้'},
                {id: 4, name: 'ชอบ'},
                {id: 5, name: 'ชอบที่สุด'},
            ],


            hoverScore: 0,
            hoverLabel: null,

            comments: [],

            comport: {},
            rangestartLabel: '',

            comportLoading: false,

            filters: {
                page: 1,
            },

            currentPage: 1,
            totalPages: 0,

            showall: false,
            commentLimit: 12,
            commentLimitStart: 2,
            commentTotal: 0,

            windowWidth: window.innerWidth,
            minWidth: 768,

            isComport: false,

            focusComport: false,
        }
    },

    computed: {
        disabledSave(){

            const title = this.comport.title;
            const text = this.comport.text;
            const score = this.comport.score;

            return text==''
            // return title=='' || text=='' || score==0
        },

        checkComport(){

            let is; // = this.windowWidth >= this.minWidth

            if( this.windowWidth >= this.minWidth ){
                is = true;
            }
            else{
                is = this.isComport

            }

            return is;
        }
    },

    created(){
        this.filters.limit = this.commentLimitStart;

        window.addEventListener("resize", this.resizeHandler);

        this.resetComport();
        this.getComment();
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },

    watch:{
        windowWidth(){

            if( this.windowWidth < this.minWidth ){
                this.isComport = false;
            }
        },
        isComport(){
            if( !this.isComport ){
                this.resetComport()
            }
        }
    },

    methods: {
        mouseenterStart( score ){
            this.hoverScore = score.id
            this.hoverLabel = score.name
        },

        mouseleaveStart(){
            this.hoverScore = 0
            this.hoverLabel = null
        },

        resetComport(){
            this.comport = {
                title: '',
                text: '',
                score: 0,
                learning: this.learningId,
            }

            this.rangestartLabel = '';
        },

        save(){
            const vm = this;
            let data = this.comport;

            vm.comportLoading = true;

            // data.token = $('meta[name=csrf-token]').attr('content')
            // data.text = this.comport.text;
            // data.method = 'post';
            // console.log( data );

            axios
                .post("/api/workshop/"+vm.learningId+'/comments', data)
                .then(response => {
                    vm.comportLoading = false;

                    vm.isComport = false;

                    if( vm.showall ){
                        vm.refComment();
                    }
                    else{
                        vm.onShowall();
                    }

                    this.resetComport()

                })
                .catch(error => {

                    let res = error.response.data
                    // let errors = res.errors || []
                    if( res.errors ){

                        this.$swal( 'เกิดข้อผิดพลาด!!', res.errors.text[0], 'error');
                    }

                    // console.log( 'error:', error.response.data, res.errors);

                    vm.comportLoading = false;
                });

            // console.log( 'save:', data );
            // reset data
        },

        getComment(){

            const vm = this;
            vm.loading = true;

            vm.currentPage = vm.filters.page;
            // console.log( vm.filters );

            axios
                .get("/api/workshop/"+vm.learningId+'/comments', {
                    params: vm.filters
                })
                .then(response => {
                    vm.loading = false;

                    const res = response.data


                    const meta = res.meta

                    vm.totalPages = Math.ceil(meta.total / vm.filters.limit);
                    vm.commentTotal = meta.total;
                    // console.log( res );

                    vm.comments = res.data


                    // highlight
                    // hi
                })
                .catch(error => {

                    vm.loading = false;
                });
        },

        refComment(){

            this.filters.page = 1;
            this.getComment();
        },

        score_label(score){

            score = this.rangestarts.find(n=>n.id==score);
            return score.name;
        },

        date_at( d ){
            const theDate = new Date(d)
            const months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

            let Hours = theDate.getHours();
            Hours = Hours < 10 ? '0'+Hours: Hours;

            let minutes = theDate.getMinutes();
            minutes = minutes < 10 ? '0'+minutes: minutes;

            return theDate.getDate() + ' ' + months[theDate.getMonth()] + ' ' + theDate.getFullYear() + ' ' + Hours + ':' + minutes + 'น.'
        },


        pageChangeHandler(selectedPage) {
            this.filters.page = selectedPage;
            this.getComment();
        },

        onShowall(){

            this.filters.page = 1;
            this.filters.limit = this.commentLimit;
            this.getComment();

            this.showall = true;
        },

        resizeHandler(){

            this.windowWidth = window.innerWidth;
        }
    }
}
</script>

<style lang="scss" scoped>
.review-comment-container{max-width: 960px;margin: 0 auto 30px}
 .review-comment{margin-bottom: 15px;}
 .review-comment-bubble{background-color: #f5f5f5; border-radius: 4px; padding: 8px;}
 
 @media (min-width: 768px) {
    .review-comment{display: flex; margin-bottom: 0;}
    .review-comment + .review-comment{border-top:1px solid #ecefff;}
    .review-comment-left{width: 25%; padding: 40px 15px 20px 20px; vertical-align: top;}
    .review-comment-right{width: 75%; display: inline-block; padding: 20px 20px 20px 15px;}
    .review-comment-comport{border-bottom:1px solid #ecefff;}
    .review-comment-bubble{border-radius: 15px; padding: 30px;}
 }

 .review-comment-wrap{
    max-width: 600px;
    width: 100%;
    margin: 15px auto;
 }
 .review-comment-meta{
     
        // font-size:  0%;
        margin-bottom: 3px;
        .author{
            color: #000;
            font-weight: bold;
        }
         .date{
             color: #636366;
         }
 }
 .review-comment-reply-comport{
     margin-top: 12px;
     border-top: 1px solid #ccc;
     padding-top: 12px;
 }
 
 .review-comment-body{
    font-weight: 400;
 }
    .review-comment-bodyTitle{
        position: relative;    
        h3{font-size: 18px; font-weight: bold;}
    }
    .review-comment-bodyText{font-size: 14px; line-height: 21px; position: relative;}
    .review-comment-leftHeader{display: flex;}
    .review-comment-leftScore{font-size: 36px; min-width: 10%; vertical-align: bottom;}
    .review-comment-leftScoreText{font-size: 16px; padding: 10px; margin-top: 10px; height: auto; vertical-align: bottom;}

    .review-comment-comport-wrap{
        position: relative;
        
        background-color: rgb(221 221 221);
        border-radius: 8px;
        padding: 5px;

        &.focused{
            background-color: #fff;
        }
        // .review-comment-comport-footer{
        //     position: absolute;
        //     width: 100%;
        //     bottom: 0;
        // }
    }
    .review-comment-comport{
        .form-control{
            border-color: rgb(221 221 221);
            background-color: rgb(221 221 221);
            border-width: 0;
            box-shadow: none;


            // padding-bottom: 40px;

            &:focus{background-color: #fff;}
        }
    }
    .review-comment-comport-overlay{
        align-items: center; background-color: rgba(0,0,0,.65); bottom: 0; flex-direction: column; left: 0; -webkit-overflow-scrolling: touch; overflow-y: auto; position: fixed; right: 0; top: 0; z-index: 100; justify-content: space-around; 
        
        .review-comment{align-items: stretch; display: flex; flex-direction: column; position: relative; justify-content: center; margin: 20px; max-height: calc(100% - 40px); height: 100%; z-index: 1;}
        .review-comment-bubble{border-radius: 12px;}
        .review-comment-comport-back{position: fixed; right: 0; top: 0; bottom: 0; top: 0;}
    }

    .review-comment-link{text-decoration: underline}
    
    .badge{
        background-color: #4caf50;
        color: #fff;
        font-size: 80%;
        font-weight: normal;
        padding: 3px 7px;

        &.speaker{
            background-color: #ff5722;
        }

        &.exhibitor{
            background-color: #2196f3;
        }
    }
</style>