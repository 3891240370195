<template>
    <div class="cookie-modal-dialog" v-if="show">
        <div class="backdrop"></div>

        <div class="cookie-consent" v-if="isConsent">

            <button type="button" class="cookie-consent-close btn btn-action" @click="show = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                        d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z">
                    </path>
                </svg>
            </button>

            <div class="cookie-consent-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24">
                    <path
                        d="M21.598 11.064a1.006 1.006 0 0 0-.854-.172A2.938 2.938 0 0 1 20 11c-1.654 0-3-1.346-3.003-2.937.005-.034.016-.136.017-.17a.998.998 0 0 0-1.254-1.006A2.963 2.963 0 0 1 15 7c-1.654 0-3-1.346-3-3 0-.217.031-.444.099-.716a1 1 0 0 0-1.067-1.236A9.956 9.956 0 0 0 2 12c0 5.514 4.486 10 10 10s10-4.486 10-10c0-.049-.003-.097-.007-.16a1.004 1.004 0 0 0-.395-.776zM12 20c-4.411 0-8-3.589-8-8a7.962 7.962 0 0 1 6.006-7.75A5.006 5.006 0 0 0 15 9l.101-.001a5.007 5.007 0 0 0 4.837 4C19.444 16.941 16.073 20 12 20z">
                    </path>
                    <circle cx="12.5" cy="11.5" r="1.5"></circle>
                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                    <circle cx="7.5" cy="12.5" r="1.5"></circle>
                    <circle cx="15.5" cy="15.5" r="1.5"></circle>
                    <circle cx="10.5" cy="16.5" r="1.5"></circle>
                </svg>
            </div>

            <div class="cookie-consent-content">
                {{ `คุกกี้ — เราใช้คุกกี้ในการวิเคราะห์การใช้งานเท่านั้น
                เพื่อให้แน่ใจว่าเว็บไซต์นี้ทำงานได้อย่างเหมาะสมที่สุด
                ศึกษาข้อมูลเพิ่มเติมได้ที่` }} <a :href="cookiePolicyLink" target="_blank">{{
                    `นโยบายความเป็นส่วนตัว` }}</a>
            </div>

            <div class="cookie-consent-buttons d-flex align-items-center justify-content-end gap-3">
                <button type="button" class="btn" @click="isConsent = false">{{ `ปรับแต่ง` }}</button>
                <button @click="accept" type="button" class="btn btn-primary min-w-120">{{ `ยอมรับ` }}</button>
                <!-- <button type="button" class="btn btn-primary min-w-120">{{ `คุกกี้ที่จำเป็นเท่านั้น` }}</button> -->
            </div>
        </div>
        <div class="cookie-outer" v-else>
            <div class="cookie-container">
                <div class="cookie-content">
                    <div class="cookie-header">

                        <a href="https://educathai.com" class="cookie-header-title" target="_blank">
                            <img class="cookie-header-logo" src="https://educathai.com/images/logo-educa-vertical.png"
                                alt="Educathai" />
                        </a>

                        <button type="button" class="cookie-consent-close btn btn-action" @click="show = false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z">
                                </path>
                            </svg>
                        </button>
                    </div>
                    <div class="cookie-body">
                        <h2 class="text-title mb-2">{{ title }}</h2>
                        <div class="text-desc fs-14px mb-2">{{ desc }}</div>
                        <a class="fs-14px" :href="cookiePolicyLink" target="_blank">{{ `นโยบายความเป็นส่วนตัว` }}</a>

                        <div class="my-3">
                            <button @click="allowAll" type="button" class="btn btn-primary min-w-120" tabindex="0">{{
                                `อนุญาตทั้งหมด` }}</button>
                        </div>

                        <div class="cookie-items">
                            <div class="cookie-item" v-for="(item, index) in items" :key="index">
                                <div class="cookie-item-title">

                                    <div class="cookie-item-title-text">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" :id="`cookie-${item.id}`"
                                                v-model="item.isAccept" :class="item.disabled && `readonly`"
                                                :disabled="item.disabled">
                                            <label class="custom-control-label" :for="`cookie-${item.id}`"></label>
                                        </div>
                                        <div class="flex-1">

                                            <label class="label" :for="`cookie-${item.id}`">{{ item.title }}</label>

                                            <div class="mt-1" v-if="item.disabled">
                                                <div class="always-active">{{ `Always Active` }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center gap-3">
                                        <div class="cookie-item-title-arrow" :class="item.isExpanded && `is-expanded`"
                                            @click="item.isExpanded = !item.isExpanded">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24">
                                                <path
                                                    d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="cookie-item-content pt-3" v-if="item.isExpanded">
                                    <div class="text-desc">{{ item.desc }}</div>
                                    <a :href="item.linkUrl" target="_blank"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cookie-footer d-flex align-items-center justify-content-end">
                        <div class="d-flex align-items-center gap-3">
                            <button @click="decline" type="button" class="btn">{{ `คุกกี้ที่จำเป็นเท่านั้น`
                            }}</button>
                            <button @click="accept" type="button" class="btn btn-primary min-w-120">{{ `ยืนยันที่เลือก`
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CookieConsent",
    props: {
        cookieName: {
            type: String,
            default: 'cookie_accepted'
        },
    },
    data() {
        return {
            show: false,

            title: `ตั้งค่านโยบายคุกกี้`,
            desc: `นโยบายการใช้คุกกี้นี้ จะอธิบายถึงความหมาย ประเภท ลักษณะการใช้งานคุกกี้และระยะเวลาการจัดเก็บคุกกี้ของเว็บไซต์เว็บไซต์นี้ใช้คุกกี้เพื่อช่วยให้เว็บไซต์ทำงานได้อย่างถูกต้อง แสดงเนื้อหาและโฆษณาที่ตรงกับความสนใจของผู้ใช้`,

            cookiePolicyLink: `https://educathai.com/privacy-policy`,


            items: [
                {
                    id: 1,
                    title: `คุกกี้เพื่อการวิเคราะห์และประเมินผลการใช้งาน (Performance Cookies)`,
                    desc: `คุกกี้ประเภทนี้ช่วยส่งเสริมประสิทธิภาพในการใช้บริการ เพื่อรวบรวมข้อมูลทางสถิติเกี่ยวกับการสนใจและพฤติกรรมการเยี่ยมชมเว็บไซต์ อีกทั้งยังใช้ข้อมูลนี้เพื่อการปรับปรุงและพัฒนาการทำงานของเว็บไซต์เพื่อให้มีคุณภาพที่ดีขึ้นและมีความเหมาะสมมากขึ้น ในส่วนของข้อมูลคุกกี้ที่เก็บรวบรวมนี้จะเป็นการเก็บข้อมูล เช่น จำนวนผู้ใช้งาน สัดส่วนผู้เข้าชมเว็บไซต์เพียงหน้าเดียว แหล่งที่มาของการเข้าเยี่ยมชมเว็บไซต์ เป็นต้น`,
                    linkText: `Cookies Details`,
                    linkUrl: `https://www.google.com`,
                    isAccept: true,
                    disabled: true,
                    isExpanded: false,
                },
                {
                    id: 2,
                    title: `คุกกี้เพื่อการใช้งานเว็บไซต์ (Function Cookies)`,
                    desc: `คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ จดจำตัวเลือกต่างๆ ที่ท่านได้ตั้งค่าและปรับเปลี่ยนไปตามพฤติกรรมและความพึงพอใจของผู้ใช้งานเว็บไซต์ เช่น จดจำการล็อกอินของท่าน, จดจำการตั้งค่าภาษา, จดจำข้อมูลล่าสุดที่ท่านเคยดู เพื่ออำนวยความสะดวกเมื่อท่านกลับมาใช้งานเว็บไซต์`,
                    linkText: `Cookies Details`,
                    linkUrl: `https://www.google.com`,
                    isAccept: true,
                    isExpanded: false,
                },
                {
                    id: 3,
                    title: `คุกกี้เพื่อการโฆษณาไปยังกลุ่มเป้าหมาย (Targeting Cookies)`,
                    desc: `คุกกี้ประเภทนี้เป็นคุกกี้ที่เกิดจากการเชื่อมโยงเว็บไซต์ของบุคคลที่สาม เช่น Google Analytics, Facebook Pixels, Line Tags เป็นต้น เพื่อเก็บข้อมูลการเข้าใช้งานและลิงก์ที่ท่านติดตามหรือเยี่ยมชม เพื่อให้เข้าใจความต้องการของท่านและใช้ในการปรับปรุงและพัฒนาเว็บไซต์ รวมถึงการโฆษณาให้เหมาะสมกับความสนใจของท่าน`,
                    linkText: `Cookies Details`,
                    linkUrl: `https://www.google.com`,
                    isAccept: true,
                    isExpanded: false,
                },
                {
                    id: 4,
                    title: `คุกกี้ที่มีความจำเป็น (Strictly Necessary Cookies)`,
                    desc: `คุกกี้ประเภทนี้ช่วยให้ท่านสามารถเข้าถึงข้อมูลและใช้งานเว็บไซต์ได้ เช่น การลงชื่อเข้าใช้งานเว็บไซต์ เป็นต้น และเป็นคุกกี้ที่สำคัญต่อเว็บไซต์ของเรา เนื่องจากเป็นคุกกี้ที่จะทำให้เราสามารถให้บริการเว็บไซต์ได้อย่างเหมาะสม และเพื่อให้มั่นใจว่าฟังชั่นและความปลอดภัยในการใช้งานเว็บไซต์ของเราเป็นไปอย่างได้มาตรฐาน`,
                    linkText: `Cookies Details`,
                    linkUrl: `https://www.google.com`,
                    isAccept: true,
                    isExpanded: false,
                }
            ],

            isConsent: true,
        }
    },

    mounted() {
        if (!this.$cookies.get(this.cookieName)) {
            this.show = true;
        }
    },

    watch: {
        show() {
            if (this.show) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        }
    },

    methods: {
        accept() {
            this.show = false;
            this.$cookies.set(this.cookieName, 'accept', this.expireDate());
        },
        decline() {
            this.show = false;
            this.$cookies.set(this.cookieName, 'decline', this.expireDate());
        },

        allowAll() {
            this.items.forEach(item => {
                item.isAccept = true;
            });
        },

        expireDate() {
            return new Date().setDate(new Date().getDate() + 120);
        }
    },
}
</script>

<style lang="scss" scoped>
.cookie-modal-dialog {
    z-index: 999999;
    position: fixed;
    inset: 0;


}


.cookie-outer {
    position: absolute;
    inset: 0;
    overflow-y: auto;
    padding-top: 20px;
}

.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    inset: 0;
}

.cookie-container {
    position: relative;
    margin: 0 auto;

    right: 0;
    left: 0;
    max-width: 575px;
    width: 100%;

    background-color: #fff;
    box-shadow: 0px 2px 10px -3px #999;

    border-radius: 15px 15px 0 0;

    height: 100%;
}

.text-secondary {
    display: flex;
    flex-direction: column;
}

.cookie-header {
    padding: 16px 16px 10px;
    border-bottom: 1px solid #ddd;
}

.cookie-footer {
    padding: 10px 16px 16px;
    border-top: 1px solid #ddd;
}

.text-title {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0;
}

.text-desc {
    color: #696969;
    font-size: 14px;
    line-height: 1.4;
}


.cookie-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cookie-body {
    padding: 16px;
    flex: 1;
    overflow-y: scroll;
}

.cookie-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cookie-item {
    padding: 10px 16px;
    border-radius: 10px;
    background-color: rgb(250, 250, 250);
}

.cookie-item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    min-height: 38px;
}

.cookie-item-title-text {
    display: flex;
    font-weight: bold;
    gap: 6px;


    .label {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        display: block;
    }
}

.cookie-item-title-arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    svg {
        width: 18px;
        height: 18px;
    }

    &.is-expanded {
        transform: rotate(180deg);
        color: #101010;
        background-color: #eee;
    }
}

.fs-14px {
    font-size: 14px;
}

.cookie-header-logo {
    width: auto;
    height: 50px;
}

.gap-3 {
    gap: 10px;
}

.custom-control-input.readonly {
    pointer-events: none;
}

.custom-switch .custom-control-input.readonly:disabled:checked~.custom-control-label::before {
    background-color: #f44336;
    border-color: #f44336;
}

.cookie-consent {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    background-color: #fff;
    padding: 15px;


    display: flex;
    flex-direction: column;

    // align-items: center;
    // justify-content: space-between;
    gap: 16px;

    max-height: 90%;
    box-shadow: 0 0 18px rgba(0, 0, 0, .2);
}

.always-active {
    font-weight: bold;

    display: inline-block;
    background-color: #f9cccc;
    color: #000000;
    border-radius: 10px;
    font-size: 14px;
    padding: 2px 6px;
}

.cookie-consent-icon {
    width: 52px;
    height: 52px;
    color: #214368;
}

.cookie-consent-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;

    background-color: transparent;
    border-radius: 50%;
    box-shadow: none;
}

@media only screen and (min-width: 768px) {
    .cookie-consent {
        bottom: 2em;
        left: 2em;
        max-width: 420px;
        border-radius: 20px;
        padding: 30px;
    }

    .cookie-outer {
        padding-bottom: 20px;
    }

    .cookie-container {
        top: 5%;
        border-radius: 15px;
        margin-bottom: 20px;
        height: auto;
    }

    .cookie-body {
        max-height: 600px;
    }
}
</style>