<template>
    <div
        id="workshop"
        class="module inner container"
        ref="container"
    >
        <div class="module-header" v-if="title">
            <div class="d-flex justify-content-between align-items-center">
                <h2 class="module-header-title text-uppercase">{{title}}</h2>
            </div>
        </div>

        <!-- <div class="mb-3 font-weight-bold" if="">
            Total Results: <span>{{  }}</span>
        </div> -->

        <div class="module-content">
            <Skeleton v-if="splash" />

            <!-- :src="thumbnail" -->

            <div v-else class="workshop-grid">
                <div class="workshop-item" v-for="(item, i) in posts" :key="i">
                    <a :href="item.url" class="workshop-card workshop-link">
                        <div class="workshop-media">
                            <div class="workshop-pic">
                                <img
                                    class="lazyload"
                                    :src="item.imageUrl"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="workshop-body">
                            <div class="workshop-content">
                                <h3 class="headline" v-html="item.title"></h3>

                                <div class="workshop-desc">{{ item.desc }}</div>
                            </div>
                            <div class="workshop-footer">
                                <div class="timestamp">{{ item.dateStr }}</div>
                                <div class="link-more">
                                    <span>เพิ่มเติม</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center my-4">
            
            <vue-pagination
                v-if="!maxW450"
                :current="currentPage"
                :total="totalPages"
                :last-page="lastPage"
                @page-change="pageChangeHandler"

            ></vue-pagination>

            <div class="d-flex justify-content-center" v-else-if="isMore">
                <button type="button" :class="isLoadMore&&`disabled`" :disabled="isLoadMore" class="btn btn-sm btn-outline-primary rounded-pill min-w-120" @click.prevent="loadMoreHandler">
                    <span v-if="isLoadMore">กำลังโหลด...</span>
                    <span v-else>โหลดเพิ่ม...</span>
                </button>
            </div>
        </div>
        
    </div>
</template>

<script>
import VuePagination from "./VuePagination/index.vue";
import Skeleton from "./Skeleton/index.vue";

export default {
    components: { VuePagination, Skeleton},
    props: ["thumbnail", "items", "storageMain", "limit", "linkMore", "showTitle", "tag", 'title','event'],
    data() {
        return {
            // items: []
            storage: `https://educathai.com/storage/`,

            filters: {
                page: 1,
                limit: 8,
                event_id : this.event.id
            },

            splash: true,

            currentPage: 1,
            totalPages: 0,
            lastPage: 0,

            isFilter: false,
            meta: {},
            posts: {},
            isPosition: false,

            isLoadMore: false,
            isMore: false,

            maxW450: false,
            w450: 450,

            // isAll: false
        };
    },

    created() {
        if (this.limit) {
            this.filters.limit = this.limit;
        }

        this.getPost();
    },

    mounted() {
        // console.log(this.total, this.current, this.lastPage);

        window.addEventListener("resize", this.resizeEventHandler);
        this.resizeEventHandler();
    },

    methods: {
        getPost() {
            this.isLoad = true;
            this.filters.page = this.currentPage;

            this.filters.tag = this.tag

            axios
                .get(`/api/online-workshop-hashtag`, {
                    params: this.filters
                })
                .then(response => {
                    const res = response.data;
                    const meta = res.meta;

                    this.isLoad = false;
                    this.totalPages = meta.last_page;
                    this.lastPage = meta.last_page;
                    this.currentPage = meta.current_page;

                    if( this.isLoadMore ){
                        this.isLoadMore = false 
                        this.posts.push(...res.data)
                    }
                    else{
                        this.posts = res.data;
                    }

                    this.splash = false;
                    this.isMore = res.links.next ? true : false

                    if (this.isPosition) {
                        this.isPosition = false;

                        this.$refs.container.scrollIntoView({
                            behavior: "smooth"
                        }); //, block: "end", inline: "nearest"
                    }
                })
                .catch(error => {
                    this.isLoad = false;
                });
        },

        pageChangeHandler(selectedPage) {
            this.currentPage = selectedPage;

            this.isPosition = true;
            this.getPost();
        },

        loadMoreHandler(){
            this.isLoadMore = true;
            this.currentPage ++
            this.getPost();
        },

        resizeEventHandler(){
            this.maxW450 = window.innerWidth < this.w450;
        },
    }
};
</script>

<style lang="scss" scoped>
.workshop-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    margin-right: -15px;
}

.workshop-item {
    margin-bottom: 15px;
    padding-right: 15px;

    position: relative;
    width: 100%;

    flex: 0 0 50%;
    max-width: 50%;
}
.workshop-card {
    background-color: #fff;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    height: 100%;
}
.workshop-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 4px;
}
.workshop-pic {
    position: relative;
    display: block;
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    background: #ffd5d6;
    border-radius: 8px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.headline {
    color: #214368;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;

    font-size: 14px;
    line-height: 1.16667;
    font-weight: 700;
    margin-bottom: 0;
    max-height: 32px;
}
.workshop-desc {
    display: none;
}
.workshop-link {
    text-decoration: none;
}
.timestamp {
    font-size: 13px;
    color: #6e6e73;
}

.workshop-footer {
    margin-top: 3px;

    .link-more {
        display: none;
    }
}

@media (min-width: 440px) {
    // .workshop-grid{
    //     margin-right: -15px;
    // }
    // .workshop-item {
    //     margin-bottom: 15px;
    //     padding-right: 15px;
    // }

    .headline {
        font-size: 16px;
        max-height: 46.4px;
    }
}

@media (min-width: 768px) {
    .workshop-card {
        // box-shadow: rgb(0 0 0 / 7%) 0px 2px 6px 0px;
        box-shadow: 0 1px 2px rgba(0,0,0,.2);
        transition: all 0.8s ease 0s;

        .workshop-pic img {
            transition: transform 400ms cubic-bezier(0.4, 0, 0.25, 1) 0ms,
                opacity 1s cubic-bezier(0.4, 0, 0.25, 1) 0ms;
        }

        .headline {
            transition: color 400ms cubic-bezier(0.4, 0, 0.25, 1) 0ms;
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 20px 0px;
            .workshop-pic img {
                transform: scale(1.05);
                // transform: rotate(2deg) scale(1.1);
            }

            .headline {
                color: #1d1d1f;
            }
            .link-more {
                color: #214368;
            }
            .link-more svg {
                width: 18px;
            }
        }
    }
    .workshop-item {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .workshop-body {
        padding: 12px;
    }
    .workshop-footer {
        display: flex;
        justify-content: space-between;

        .link-more {
            font-size: 13px;
            position: relative;
            transform: translate3d(0px, 0px, 0px);
            color: #777;

            display: flex;
            align-items: center;

            svg {
                transition: width 0.3s ease 0s;
                width: 0;
            }
        }
    }

    .workshop-pic {
        border-radius: 8px 8px 0 0;
    }
    .workshop-content {
        flex: 1 1 auto;
    }

    .headline {
        font-size: 18px;
        line-height: 1.3;
    }
    .workshop-desc {
        display: block;
        color: #777;
        font-size: 13px;
        line-height: 1.3;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-top: 3px;
    }

    .workshop-footer {
        margin-top: 6px;
    }
}
@media (min-width: 1024px) {
    .workshop-item {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .workshop-card {
        flex-direction: row;
        align-items: flex-start;
    }
    .workshop-media {
        width: 45%;
    }
    .workshop-body {
        flex: 1;
    }
    .workshop-pic {
        border-radius: 8px 0 0 8px;
    }
}
</style>
