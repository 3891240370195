<template>
<div class="review-comment-reply-wrap">

    <div class="review-comment-replies">
        <div class="review-comment-reply" v-for="(reply,i) in replies" :key="i">
            <div class="review-comment-reply--meta">
                <span class="author">{{ reply.author }} <span class="badge" :class="[reply.badge=='Speaker'&&`speaker`, reply.badge=='Exhibitor'&&`exhibitor`]" v-if="reply.badge">{{ reply.badge }}</span></span><span class="mx-1">·</span><span class="date">{{ reply.dateStr }}</span>
            </div>
            <div class="review-comment-reply--body">{{ reply.body }}</div>
        </div>
    </div>

    <div class="review-comment-reply-comport">

        <div class="review-comment-comport">
            <textarea-autosize class="form-control" placeholder="ตอบความคิดเห็น" rows="1" v-model="comport"></textarea-autosize>
            <div class="review-comment-reply-comport-btn">
                <button type="button" :class="comportLoading&&`btn-loading`" :disabled="!comport||comportLoading" class="btn btn-sm btn-primary w-min-150" @click="save">
                    <span>ตอบ</span>
                    <div class="loader" v-if="comportLoading"><div></div><div></div><div></div></div>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        props: ['learningId', 'commentId', 'replies'],
        data() {
            return {
                // replies: [],

                comport: '',
                comportLoading: false,
            }
        },

        created(){
            // this.replies.push({});
            // this.replies.push({});

            // console.log( 'replies', this.replies );
        },

        methods: {
            save(){

                const vm = this;

                const data = vm.comport;
                vm.comportLoading = true;

                axios
                    .post(`/api/workshop/${vm.learningId}/comments/${vm.commentId}/reply`, {
                        replyMessage: vm.comport
                    })
                    .then(response => {
                        vm.comportLoading = false;
                        vm.comport = '';

                        const res =  response.data

                        vm.replies.push( res.data )
                        // console.log( res );
                    })
                    .catch(error => {
                        vm.comportLoading = false;
                        // console.log( error );
                    })

            }
        }
    }
</script>

<style lang="scss" scoped>
.review-comment-replies{
    border-top: 1px solid #e1e1e1;
    margin-top: 10px;
}
.review-comment-reply{
    margin-top: 15px;
    margin-left: 30px;
    border-radius: 15px;

    // padding: 20px;
    // background-color: #f5f5f5;

    .review-comment-reply--meta{
        
        // 
        color: #000;
        font-size: 80%;
         margin-bottom: 3px;
        .author{
            font-weight: bold;
        }
         .date{
             color: #636366;
         }
    }
}
.review-comment-reply-comport{
    margin-top: 15px;
    margin-left: 30px;
}

.review-comment-comport{
    position: relative;

    .form-control{
        padding-right: 95px;
    }
}
.review-comment-reply-comport-btn{
    position: absolute;
    bottom: 4px;
    right: 5px;

    .btn{
        border-radius: 6px;
        min-width: 80px;
    }
}
.btn-submit{
    position: relative;

    &.btn-loading .loader{
        opacity: 1;
    }
}



.btn-submit .loader {
    position: absolute;
    height: auto;
    width: 100%;
    z-index: 2;
    left: 0;
    top: 50%;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);

    > div {
        display: inline-block;
        float: none;
        vertical-align: baseline;
        width: 8px;
        height: 8px;
        padding: 0;
        border: none;
        margin: 2px;
        opacity: .4;
        border-radius: 7px;
        background-color: rgba(255, 255, 255, 0.9);
        transition: background-color .2s;
        -webkit-animation: btn-submit-loading-animate 1s infinite;
        animation: btn-submit-loading-animate 1s infinite;
    }

    > div:nth-child(3n+2) {
        -webkit-animation-delay: .15s;
        animation-delay: .15s;
    }

    > div:nth-child(3n+3) {
        -webkit-animation-delay: .3s;
        animation-delay: .3s;
    }
}
.badge{
    background-color: #4caf50;
    color: #fff;
    font-size: 80%;
    font-weight: normal;
    padding: 3px 7px;

    &.speaker{
        background-color: #ff5722;
    }

    &.exhibitor{
        background-color: #2196f3;
    }
}
</style>