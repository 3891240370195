<template>
    <form @submit.prevent="updateOccupation" class="info-section" ref="form">
        <div class="card card-info mb-4">
            <div class="card-header">
                <h3 @click="autoComplete">Occupation or Position / อาชีพหรือตำแหน่ง</h3>
            </div>
            <div class="card-body">

                <div>
                    <label class="font-weight-bold d-flex" for="">
                        <span class="mr-1">Occupation or Poition / อาชีพหรือตำแหน่ง</span>
                        <span class="text-danger">*</span>
                    </label>

                    <select class="custom-select" v-model="form.occupation_id"
                        :class="[errors.occupation_id && `is-invalid`]">
                        <option value="" disabled>Please select / โปรดเลือก</option>
                        <option :value="item.id" v-for="(item, index) in occupations" :key="index">{{ item.name }}
                        </option>
                    </select>

                    <div class="invalid-feedback" v-if="errors.occupation_id">{{ errors.occupation_id }}</div>
                </div>

                <div class="mt-3" v-if="OCCUPATION_OUTER_ID.indexOf(form.occupation_id) >= 0">
                    <label class="font-weight-bold d-flex" for="">
                        <span class="mr-1">Please specify / โปรดระบุอาชีพ</span>
                        <span class="text-danger">*</span>
                    </label>

                    <input type="text" class="form-control" v-model="form.occupation_custom"
                        :class="[errors.occupation_custom && `is-invalid`]" />

                    <div class="invalid-feedback" v-if="errors.occupation_custom">{{ errors.occupation_custom }}</div>
                </div>

                <div class="mt-3 row align-items-start" v-else-if="OCCUPATION_STUDENT_ID == form.occupation_id">
                    <div class="col-md-5 mb-3">
                        <label for="universityFaculty" class="font-weight-bold mb-0 text-nowrap">
                            <span>Faculty / คณะ</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" id="universityFaculty" class="form-control" v-model="form.university_faculty"
                            :class="[errors.university_faculty && `is-invalid`]" />
                        <div class="invalid-feedback" v-if="errors.university_faculty">{{ errors.university_faculty }}
                        </div>
                    </div>

                    <div class="col-md-5 mb-3">
                        <label for="universityName" class="font-weight-bold mb-0 text-nowrap">
                            <span class="mr-1">University / มหาวิทยาลัย</span>
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" id="universityName" class="form-control" v-model="form.university_name"
                            :class="[errors.university_name && `is-invalid`]" />
                        <div class="invalid-feedback" v-if="errors.university_name">{{ errors.university_name }}</div>
                    </div>

                    <div class="col-md-2 mb-3">
                        <label for="universityYear" class="font-weight-bold mb-0 text-nowrap">
                            <span class="mr-1">Year / ชั้นปี</span>
                            <span class="text-danger">*</span>
                        </label>
                        <input type="tel" maxlength="2" id="universityYear" v-model="form.university_year"
                            class="form-control" :class="[errors.university_year && `is-invalid`]" />
                        <div class="invalid-feedback" v-if="errors.university_year">{{ errors.university_year }}</div>
                    </div>

                </div>

                <div v-else-if="form.occupation_id && form.occupation_id <= 8">
                    <div class="mt-3">
                        <label class="font-weight-bold" for="">Affiliation / สังกัด</label>
                        <select class="custom-select" v-model="form.affiliation_id"
                            :class="[errors.affiliation_id && `is-invalid`]">
                            <option value="" disabled>Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in affiliations" :key="index">{{ item.name }}
                            </option>
                        </select>

                        <div class="invalid-feedback" v-if="errors.affiliation_id">{{ errors.affiliation_id }}</div>
                    </div>

                    <div class="mt-2" v-if="form.affiliation_id == AFFILIATION_OUTER_ID">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Please specify / โปรดระบุสังกัด</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" class="form-control" v-model="form.affiliation_custom"
                            :class="[errors.affiliation_custom && `is-invalid`]" />

                        <div class="invalid-feedback" v-if="errors.affiliation_custom">{{ errors.affiliation_custom }}
                        </div>
                    </div>

                    <div class="mt-3">
                        <label class="font-weight-bold mb-0 d-flex" for="">
                            <span class="mr-1">Teaching Level / ระดับชั้นที่สอนหรือเกี่ยวข้อง</span>
                            <span class="text-danger">*</span>
                        </label>
                        <div class="text-muted mb-2">(you can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)
                        </div>

                        <div class="row" :class="[errors.levels && `is-invalid`]">
                            <div class="mb-2 col-md-5" v-for="(item, index) in levels" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" :id="`teaching-${item.id}`" :value="item.id"
                                        class="custom-control-input" v-model="form.levels" />
                                    <label :for="`teaching-${item.id}`" class="custom-control-label">{{ item.name
                                    }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="invalid-feedback" v-if="errors.levels">{{ errors.levels }}</div>

                        <div class="mt-1" v-if="form.levels.indexOf(LEVEL_OUTER_ID) >= 0">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Please specify / โปรดระบุระดับชั้นที่สอนหรือเกี่ยวข้อง</span>
                                <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control" v-model="form.other_teaching_group"
                                :class="[errors.other_teaching_group && `is-invalid`]" />
                            <div class="invalid-feedback" v-if="errors.other_teaching_group">{{
                                    errors.other_teaching_group
                            }}</div>
                        </div>

                    </div>

                    <div class="mt-3">
                        <label class="font-weight-bold mb-0 d-flex" for="">
                            <span class="mr-1">Subject or Related Field / กลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง</span>
                            <span class="text-danger">*</span>
                        </label>
                        <div class="text-muted mb-2">(you can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)
                        </div>

                        <div class="row" :class="[errors.subjects && `is-invalid`]">
                            <div class="mb-2 col-md-5" v-for="(item, index) in subjects" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" :id="`subject-${item.id}`" :value="item.id"
                                        class="custom-control-input" v-model="form.subjects" />
                                    <label :for="`subject-${item.id}`" class="custom-control-label">{{ item.name
                                    }}</label>

                                    <span class="custom-checkbox-bg"></span>
                                </div>
                            </div>
                        </div>
                        <div class="invalid-feedback" v-if="errors.subjects">{{ errors.subjects }}</div>

                        <div class="mt-1" v-if="form.subjects.indexOf(SUBJECT_OUTER_ID) >= 0">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Please specify / กลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง</span>
                                <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control" v-model="form.other_teaching_level"
                                :class="[errors.other_teaching_level && `is-invalid`]" />
                            <div class="invalid-feedback" v-if="errors.other_teaching_level">{{
                                    errors.other_teaching_level
                            }}</div>
                        </div>
                    </div>

                    <div class="mt-3 row">
                        <div class="col-md-4 mb-3">
                            <label for="work-id" class="font-weight-bold">Office Address
                                / สถานที่ทำงาน <span class="text-danger">*</span></label>

                            <select id="work-id" class="custom-select" v-model="form.work_id"
                                :class="[errors.work_id && `is-invalid`]">
                                <option value="">Please select / โปรดเลือก</option>
                                <option :value="item.id" v-for="(item, index) in workplaces" :key="index">{{ item.name
                                }}
                                </option>
                            </select>

                            <div class="invalid-feedback" v-if="errors.work_id">{{
                                    errors.work_id
                            }}</div>
                        </div>
                        <div class="col-md-8 mb-3">
                            <label for="workName" class="font-weight-bold">School or Institution
                                / ชื่อสถาบัน <span class="text-danger">*</span></label>
                            <input type="text" id="workName" class="form-control" v-model="form.work_name"
                                :class="[errors.work_name && `is-invalid`]" />

                            <div class="invalid-feedback" v-if="errors.work_name">{{
                                    errors.work_name
                            }}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="personal-information-footer-buttons">
            <button type="button" class="btn btn-lg btn-light min-w-120 font-weight-bold" @click="$emit('next', 1)"
                :disabled="isLoad">{{ $t('Back') }}</button>
            <button type="submit" class="btn btn-lg btn-primary min-w-120 font-weight-bold"
                :class="[isLoad && `disabled`, isError && `btn-error`]" :disabled="isLoad">{{ isLoad ?
                        `${$t('Loading')}...` : $t('Next')
                
                }}</button>
        </div>
    </form>
</template>

<script>
const CURRENT_STEP = 2
const OCCUPATION_STUDENT_ID = 9
const OCCUPATION_OUTER_ID = [10, 11]
const AFFILIATION_OUTER_ID = 8
const LEVEL_OUTER_ID = 7
const SUBJECT_OUTER_ID = 15

export default {
    props: ['form', 'occupations', 'affiliations', 'levels', 'subjects', 'workplaces'],
    data() {


        return {
            OCCUPATION_STUDENT_ID,
            OCCUPATION_OUTER_ID,

            AFFILIATION_OUTER_ID,
            LEVEL_OUTER_ID,
            SUBJECT_OUTER_ID,

            errors: {},
            isError: false,
            isLoad: false,
        }
    },

    mounted() {

        window.scrollTo({ top: 0, behavior: 'smooth' });

        // if (this.$refs.form) {
        //     this.$refs.form.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        // }
    },

    methods: {
        async updateOccupation() {
            this.isLoad = true
            this.isError = false

            await this.timeout(3);
            if (this.validateOccupation()) {
                this.$emit('done', CURRENT_STEP)
            }
            else {
                this.isError = true

                await this.timeout(3)
                this.focusInvalid()
            }

            this.isLoad = false
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        validateOccupation() {
            const form = this.form
            this.errors = {}

            if (!form.occupation_id) {
                this.errors.occupation_id = `กรุณาระบุอาชีพหรือตำแหน่ง`
            }
            else if (OCCUPATION_OUTER_ID.indexOf(form.occupation_id) >= 0) {

                if (!form.occupation_custom) {
                    this.errors.occupation_custom = `กรุณาระบุอาชีพหรือตำแหน่ง`
                }

            }
            else if (OCCUPATION_STUDENT_ID == form.occupation_id) {
                if (!form.university_faculty) this.errors.university_faculty = `กรุณาระบุคณะ`
                if (!form.university_name) this.errors.university_name = `กรุณาระบุมหาวิทยาลัย`
                if (!form.university_year) this.errors.university_year = `กรุณาระบุชั้นปี`
            }
            else {

                if (!form.affiliation_id) {
                    this.errors.affiliation_id = `กรุณาระบุสังกัด`
                } else if (form.affiliation_id == AFFILIATION_OUTER_ID && !form.affiliation_custom) {
                    this.errors.affiliation_custom = `กรุณาระบุสังกัด`
                }

                if (form.levels.length == 0) {
                    this.errors.levels = `กรุณาระบุระดับชั้นที่สอนหรือเกี่ยวข้อง`
                }

                if (form.levels.indexOf(LEVEL_OUTER_ID) >= 0 && !form.other_teaching_group) {
                    this.errors.other_teaching_group = `กรุณาระบุระดับชั้นที่สอนหรือเกี่ยวข้อง`
                }


                if (form.subjects.length == 0) {
                    this.errors.subjects = `กรุณาระบุกลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง`
                }

                if (form.subjects.indexOf(SUBJECT_OUTER_ID) >= 0 && !form.other_teaching_level) {
                    this.errors.other_teaching_level = `กรุณาระบุกลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง`
                }


                if (!form.work_id) {
                    this.errors.work_id = `กรุณาระบุสถานที่ทำงาน`
                }

                if (!form.work_name) {
                    this.errors.work_name = `กรุณาระบุชื่อสถาบัน`
                }

            }

            return Object.keys(this.errors).length == 0
        },

        focusInvalid() {
            const $invalid = $('.is-invalid')

            if ($invalid.length == 0) return
            $invalid[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        },

        autoComplete(e) {
            if (!e.ctrlKey || !e.shiftKey) return false;
            this.form.occupation_id = OCCUPATION_STUDENT_ID
            this.form.university_faculty = `คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี`
            this.form.university_name = `มหาวิทยาลัยมหิดล`
            this.form.university_year = `1`
        }
    }
}
</script>