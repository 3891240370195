<template>
    <div>
        <input type="hidden" name="_is_applied_promo" id="_is_applied_promo" value="N"  v-model="isAppliedPromo" />
        <div class="order-summary-main">
            <div class="content-form-center">
                <div class="order-description">
                    <div class="order-row" price="">
                        <div class="order-item-image">
                            <div class="order-image ticket"></div>
                        </div>
                        <div class="order-item-desc">
                            <span><b>{{ packageinfo.package_name }}</b></span>
                            <span v-html="packageinfo.package_description"></span>

                            <span v-if="show_onsite_detail" v-html="onsite_description" ></span>
                        </div>
                        <div class="order-item-group-col" >
                            <div class="order-item-qty"><b>Qty 1</b></div>
                            <div class="order-item-price"><b>{{packageinfo.package_price}} ฿</b></div>
                        </div>
                    </div>
                </div>
                <hr />
                <!-- is-promo-applied -->
                <div class="order-total subtotal" :class="{'is-promo-applied' : isPromoApplied}" >
                    <div class="order-total-description">
                        <div class="order-total-row">
                            <span>Subtotal</span>
                            <b style="color: #e65757;">{{package_price}} ฿ <span class="promo-discount-price"
                                    style="text-decoration: line-through;color: #8d8d8d;">{{discount_price}} ฿</span> </b>
                        </div>
                        <div class="order-total-row">
                            <span>Shipping</span>
                            <b style="color: #8d8d8d;">Free </b>
                        </div>
                        <div class="order-total-row promo-applied">
                            <div class="promotion-applied">
                                <b id="show-promo-code" >{{showPromoCode}}</b>
                                <span tabindex="10" style="color: #b8bf71;" id="promo_text_status">Promo Code Applied</span>
                            </div>
                            <div style="display: flex;justify-content: flex-end;width: 100%;">
                                <span class="btn-cancel-promotion"  @click="cancelPromotion">ยกเลิก</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="order-total">
                    <div class="order-total-description">
                        <div class="order-total-row">
                            <b>Total</b>
                            <b id="_totalprice_ss">{{payment_amount}} ฿</b>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="order-total">
                    <div class="order-total-description">
                        <div class="order-total-row"
                            style="height: 60px;justify-content: center;align-items: flex-start;">
                            <div style="margin-right: 10px;">
                                <!-- is-invalid -->
                                <input v-model="promotion_code"  ref="promotion_code" placeholder="Add Promo Code" type="text"
                                    class="form-control promo" 
                                    :class="{ 'is-invalid' : isinvalidPromotionCode}"
                                 />
                                <div class="invalid-feedback">รหัสโปรโมชั่นไม่ถูกต้อง</div>
                            </div>
                            <button @click="addPromoCode" 
                                    type="button" 
                                    class="btn ticket-select btn02 addpromo"
                                    style="width:150px">Add</button>
                        </div>
                    </div>
                </div>
                <div class="box-size-60"></div>
            </div>
        </div>

        <PersonalForm    
            :config="config" 
            :form="form" 
            :member="member" 
            @done="doneStep" 
            :prefixs="prefixs"
            :event="event"

            :occupations="occupations"
            :levels="levels"
            :subjects="subjects"
            :workplaces="workplaces"
            :affiliations="affiliations"
            :provinces="provinces"
            :billing="billing"
            ref="personalform"
        >
        </PersonalForm>

        <BillingAddressForm
            :config="config" 
            :form="form" 
            :member="member" 
            @done="doneStep" 
            :prefixs="prefixs"
            :event="event"

            :occupations="occupations"
            :levels="levels"
            :subjects="subjects"
            :workplaces="workplaces"
            :affiliations="affiliations"
            :provinces="provinces"
            :billing="billing"

            ref="billingform"
 
        >
        </BillingAddressForm>

        <div style="display: flex;justify-content: center;padding-bottom: 40px;">
            <span>
                <button @click="placeorder" type="button" class="btn btn-primary" style="width: 200px; padding: 10px;font-weight: bold;font-size: 18px;">Place Order</button>
            </span>
        </div>

    </div>
</template>
<script>
import Axios from 'axios';
const PREFIX_OTHER = 'other'
export default {
    props: ['token', 'event','packageinfo','baseapi','member' , 'occupations', 'levels', 'subjects', 'workplaces', 'affiliations', 'provinces', 'billing', 'code','onsiteDetailUrl'],

    data() {

        const prefixs = [];
        prefixs.push({ id: 'นาย', name: "Mr. (นาย)" });
        prefixs.push({ id: 'นาง', name: "Mrs. (นาง)" });
        prefixs.push({ id: 'นางสาว', name: "Ms. (นางสาว)" });
        prefixs.push({ id: PREFIX_OTHER, name: "Other (อื่น ๆ)" });

        return {
            show_onsite_detail : false ,
            onsite_description : '' ,
            total_summary : 0 ,
            package_price : 0,
            promotion_code : '',
            payment_amount : 0,
            package : {} ,
            isinvalidPromotionCode : false ,
            isPromoApplied : false,
            discount_price : 0 ,//จำนวนส่วนลด
            isAppliedPromo : 'N' ,
            showPromoCode : '',


            prefixs,
            config: {
                agree: false,

                // info
                day: '',
                month: '',
                year: '',
                prefixId: '',

                billingAddressSameCurrentAddress: false,
            },

            form: {

                // info
                prefix: '',
                first_name: '',
                last_name: '',

                prefix_en: '',
                first_name_en: '',
                last_name_en: '',

                gender: '',
                email:'',

                phone_number: '',
                birthday: '',

                // occupation
                occupation_id: '',
                occupation_custom: '',

                affiliation_id: '',
                affiliation_custom: '',

                levels: [], // ระดับชั้น
                other_teaching_group: '',


                subjects: [], // วิชาสอน
                other_teaching_level: '',

                university_faculty: '',
                university_name: '',
                university_year: '',

                // address
                //shipping_name: '',
                address_no: '',
                address_province_id: '',
                address_amphure_id: '',
                address_district_id: '',
                address_zip: '',

                // billing 
                billingAddress: {
                    full_name: '',
                    address: '',
                    province_id: '',
                    amphures_id: '',
                    district_id: '',
                    zipcode: '',
                    tel: '',
                },
                billingAddressSameCurrent: false,

                // Confirmation
                subscription: 1,
                notifications: [1, 2],

                work_id: '',
                work_name: '',

                acceptPDPA: null,
                is_applied_promo : 'N',
                promotioncode : '',
                package_id : this.packageinfo.id,
                package: {

                }
            }
        }
    },
    computed: {
        getTotal : function(){
            return (this.package_price - this.discount_price);
        }
    },
    watch: {},
    created() {
        this.form = { ...this.form, ...this.member }
        //console.log(this.provinces)
        if (this.member.birthday) {
            const birthday = this.member.birthday.split('-')

            this.config.year = birthday[0]
            this.config.month = birthday[1]
            this.config.day = birthday[2]
        }

        if (this.member.prefix) {
            const prefix = this.prefixs.find(n => n.id == this.member.prefix)
            this.config.prefixId = prefix ? prefix.id : PREFIX_OTHER
        }

        if (this.member.levels) {
            this.form.subjects = this.member.levels.map(n => n.id)
        }

        if (this.member.teachings) {
            this.form.levels = this.member.teachings.map(n => n.id)
        }

        if (this.billing) {
            this.form.billingAddressSameCurrent = false
            this.form.billingAddress = { ...this.form.billingAddress, ...this.billing }
        }else{
            this.form.billingAddressSameCurrent = true
        }
        
    },
    mounted() {
        this.package = this.packageinfo
        this.total_summary = parseFloat(this.package.package_price??0)
        this.package_price = parseFloat(this.package.package_price??0)
        this.payment_amount = parseFloat(this.package.package_price??0)

        this.getOnsiteCart();
    },
    methods: {
        async getOnsiteCart(){

            try {
                const response = await fetch(this.onsiteDetailUrl);
                const data = await response.json();

                //console.log(data);
                this.show_onsite_detail = true;
                this.onsite_description = data.onsite_description;  
            } catch (error) {
                console.error('Error fetching package details:', error);
            }

 
        },
        doneStep(stepIndex) {
            
        },
        getPayment : function(){
             this.payment_amount = (this.package_price - this.discount_price);
             
        },
        addPromoCode : async function(){
            if(this.promotion_code.trim() == ''){
                this.$refs.promotion_code.focus()
                this.isinvalidPromotionCode = true
                this.isPromoApplied = false;
                this.discount_price = 0
                this.isAppliedPromo = 'N'
                return
            }
            try {
                let payload = {
                    promocode : this.promotion_code,
                    total : this.package_price
                }
                const { data: response } = await axios.post(this.baseapi + 'registration/check_promotion', payload)
                if(response.is_promotion_available){
                    this.promotion_code = ''
                    this.isPromoApplied = true;
                    this.isinvalidPromotionCode = false
                    this.isAppliedPromo = 'Y'
                    this.showPromoCode  = response.promotion.promo_code
                    this.discount_price = response.promotion.discount  
                    this.form.is_applied_promo = 'Y'
                    this.form.promotioncode = this.showPromoCode
                }else{
                    this.$refs.promotion_code.focus()
                    this.isinvalidPromotionCode = true
                    this.isPromoApplied = false;
                    this.discount_price = 0
                    this.isAppliedPromo = 'N'
                    this.form.is_applied_promo = 'N'
                    this.form.promotioncode = this.promotion_code
                }
            } catch (error) {
                this.$refs.promotion_code.focus()
                this.isinvalidPromotionCode = true
                this.isPromoApplied = false;
                this.discount_price = 0
                this.isAppliedPromo = 'N'
                this.form.is_applied_promo = 'N'
                this.form.promotioncode = this.promotion_code
            }
            this.getPayment();

        },
        cancelPromotion:function(){
            this.isinvalidPromotionCode = false
            this.discount_price = 0
            this.isPromoApplied = false;
            this.isAppliedPromo = 'N'
            this.promotion_code = ''
            this.$refs.promotion_code.focus()
            this.form.is_applied_promo = 'N'
            this.form.promotioncode = this.promotion_code

            //var Code = $('#_promotion_code');
            //$('#_promotion_code').removeClass('is-invalid');
            //$('.promo-discount-price').html('0 ฿');
            //$('.subtotal').removeClass('is-promo-applied');
            //$('#_is_applied_promo').val('N');
            //$('#_promotioncode').val("");
            //init_form.discount = 0;
            //init_form.gettotal();
            //Code.focus();
            this.getPayment();
        },
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        placeorder : async function(){
 
            if(!this.$refs.personalform.validateInfo()){
              this.$refs.personalform.showForm()
              console.log('personalform')
              return;
            }
            if(!this.$refs.personalform.validateOccupation()){
              this.$refs.personalform.showForm()
              console.log('validateOccupation')
              return;
            }
            if(!this.$refs.billingform.validateAddress()){
              this.$refs.billingform.showForm()
              console.log('validateAddress')
              return;
            }
            const swal = this.$swal({
                // text: this.$t('Loading'),
                showConfirmButton: false,
                background: 'transparent',
                backdrop: `rgba(0,0,0,0.9)`,
                title: "<h3 style='color: white;'>" + 'กำลังบันทึกข้อมูล' + "...</h3>",
                html: "<div style='color: white;'>กำลังบันทึกข้อมูล, กรุณารอซักครู่</div>",
                showLoaderOnConfirm: false,
                allowOutsideClick: false,
           });
            const payload = { ...this.form, eventId: this.event.id ,'_token' : this.token}
            await this.timeout(800);
            try {
                const { data: response } = await axios.post( this.baseapi + 'registration/place_order', payload)
                if (response.success) {
                    swal.close()

                    this.$swal({
                        icon: 'success',
                        title: response.intro || this.$t('Success'),
                        text: response.message,
                    }).then(() => {

                        window.location.href = response.redirectUrl
                    })
                }
            } catch (error) {
                const { data } = error.response
                this.error = data

                this.$swal({
                    icon: 'error',
                    title: data.intro || this.$t('Oops'),
                    text: data.message,
                });
            }
           
       }
    }
}
</script>