<template>
    <nav class="nav-pagination-payment"   >
        <div id="jscontent" ></div>
               <form  method="POST" action="/checkout">
                  <button type="button" _kpayment class="pay-button">PAY NOW</button>
              </form>
    </nav>

</template>

<script>
export default {
    props: ["event"],
    data() {
        return {
            currentPage: 0,
            midRange: 1,
            numPages: 0,
            limit: 10, // default 10,

            prevLabel: 'ก่อนหน้า', // Previous
            nextLabel: 'ถัดไป', // Next

            pages: [],

            maxW450: false,
            w450: 450,
        };
    },
    created(){
  document.querySelectorAll('#jscontent')[0].innerHTML = '<a>AA <script><</a>';
    },
    mounted(){
          var d  =100;
       
            
        /*
        let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js')
            recaptchaScript.setAttribute('data-apikey', 'pkey_test_21428p3Q12e0NhWY1uozbKQBHqCSJzYROnjpq')
            recaptchaScript.setAttribute('data-amount', '1950.00')
            recaptchaScript.setAttribute('data-payment-methods', 'card')
            recaptchaScript.setAttribute('data-name', 'Educa 2022')
            recaptchaScript.setAttribute('data-mid', '401983832832001')
            document.head.appendChild(recaptchaScript)
            },
            */
    },
    watch: {
       
    },
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>

</style>
