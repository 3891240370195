<template>
    <div class="skeleton-grid">
        <div class="skeleton-item" v-for="(item, i) in items" :key="i">
            <div class="skeleton-card">
                <div class="skeleton-media">
                    <div class="skeleton-pic"></div>
                </div>
                <div class="skeleton-content">
                    <div class="skeleton-title"></div>
                    <div class="skeleton-desc"></div>
                    <div class="skeleton-desc w60"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        limit: {
            Type: Number,
            default: 8
        }
    },
    data() {
        return {
            items: []
        };
    },

    created() {
        const limit = this.limit || 8;

        for (let index = 0; index < limit; index++) {
            this.items.push({ id: index });
        }
    }
};
</script>

<style lang="scss" scoped>
.skeleton-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    margin-right: -15px;

    .w60 {
        width: 60%;
    }

    .w70 {
        width: 70%;
    }

    .w80 {
        width: 80%;
    }

    .w90 {
        width: 90%;
    }
}

.skeleton-item {
    margin-bottom: 15px;
    padding-right: 15px;

    position: relative;
    width: 100%;

    flex: 0 0 50%;
    max-width: 50%;
}

.skeleton-pic {
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    // border-top-right-radius: 12px;
    // border-top-left-radius: 12px;
}

.skeleton-title {
    height: 10px;
    width: 40%;
    margin-top: 8px;
    margin-bottom: 4px;
    border-radius: 8px;
}

.skeleton-desc {
    height: 4px;
    width: 80%;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 8px;
}

.skeleton-pic,
.skeleton-title,
.skeleton-desc {
    background-image: linear-gradient(to right,
            #f4f4f4 8%,
            rgba(229, 229, 229, 0.8) 18%,
            #f4f4f4 33%);
    background-size: 1200px;
    animation: skeleton-animation 2s infinite ease-out;
}

.skeleton-card {
    background-color: #fff;
    border-radius: 8px;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    height: 100%;
}

@keyframes skeleton-animation {
    0% {
        background-position: -600px;
    }

    100% {
        background-position: 600px;
    }
}

@media (min-width: 768px) {
    .skeleton-title {
        height: 18px;
        margin-bottom: 8px;
    }

    .skeleton-desc {
        height: 11px;
    }

    .skeleton-card {
        box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    }

    .skeleton-item {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .skeleton-content {
        flex: 1;
        padding: 4px 12px 22px;
    }

}

@media (min-width: 1024px) {
    .skeleton-item {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .skeleton-card {
        flex-direction: row;
        align-items: flex-start;
        border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
    }

    .skeleton-media {
        width: 45%;
    }
}
</style>