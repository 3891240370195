<template>
    <div class="header-wrap">
        <div class="header-mobile" v-if="menuMode == 'md'">
            <a :href="baseUrl" class="header-brand">
                <img :src="event.imageUrl" :alt="event.name" v-if="event.imageUrl" width="76" height="76" data-info="1" />
                <img :src="logoImage" :alt="event.name" width="76" height="76"  data-info="2" v-else />
            </a>
            <div class="header-right">
                <div class="header-nav">
                    <div class="header-nav-item" v-if="fullWidth > 380 && !isAuth && isLogin">
                        <a class="header-nav-link" :href="`${baseUrl}/login`">
                            <span class="header-nav-text">{{  $t("Login(Current member)")  }}</span>
                        </a>
                    </div>


                    <div class="header-nav-item" v-if="fullWidth > 380 && isAuth && isRegistration">
                        <a class="header-nav-link" :href="`${baseUrl}/ticket`">
                            <span class="header-nav-text">{{  $t("Registration")  }}</span>
                        </a>
                    </div>
                    <div class="header-nav-item" v-else-if="fullWidth > 380 && isAuth && memberName">
                        <a class="header-nav-link" :href="`${baseUrl}/account/profile`">
                            <span class="header-nav-text">{{  memberName  }}</span>
                        </a>
                        <!-- <div class="header-nav-link" v-else>
                            <span class="header-nav-text">{{  'Guest'  }}</span>
                        </div> -->
                    </div>

                    <div class="header-nav-item ml-2" v-if="fullWidth > 550 && !isAuth && isForgotPassword">
                        <a class="header-nav-link" :href="`${baseUrl}/reset-password`">
                            <span class="header-nav-text">{{  $t("Forgot Password")  }}</span>
                        </a>
                    </div>



                    <div class="header-nav-item ml-2">
                        <button type="button" class="btn btn-action btn-light" @click="mobileSearch">
                            <box-icon class="icon" name='search'></box-icon>
                        </button>
                    </div>
                    <div class="header-nav-item">
                        <button type="button" class="btn btn-action btn-light" @click="drawer = !drawer">
                            <box-icon class="icon" name='x' v-if="drawer"></box-icon>
                            <box-icon class="icon" name='menu' v-else></box-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="header" v-else>
            <a :href="baseUrl" class="header-brand">
                <img :src="event.imageUrl" :alt="event.name" v-if="event.imageUrl" width="76" height="76" d="1" />
                <img :src="logoImage" :alt="event.name" width="76" height="76" d="2" v-else />
            </a>

            <div class="header-right">

                <div class="header-nav" v-if="menuMode == 'lg'">
                    <div class="header-nav-item"> 
                        <a class="header-nav-link" :class="isActive('') && `active`" :href="baseUrl">
                            <box-icon name='home' type='solid'></box-icon>
                        </a>
                    </div>
                     
                    <div class="header-nav-item" v-for="(menu, index) in lgMenus" :key="index">
                        <a class="header-nav-link" :class="isActive(menu.path) && `active`"
                            :href="`${baseUrl}${menu.path}`" v-if="menu.path">
                            <span class="header-nav-text">{{  menu.name  }} </span>
                            <span class="header-nav-border"></span>
                        </a>

 
                        <div class="header-nav-link" :class="isActive(menu.path) && `active`" v-else>
                            <span class="header-nav-text">{{  menu.name  }} </span>
                            <span class="header-nav-arrow" v-if="menu.dropdown"></span>
                            <span class="header-nav-border"></span>
                        </div>

                        <span v-if="menu.live" class="nav-live">
                            <span class="dot"></span>
                            <span class="txt">LIVE</span>
                        </span>

                        <ul class="header-dropdown-menu" v-if="menu.dropdown">
                            <li v-for="(item, i) in menu.dropdown" :key="i">
                                <a class="header-dropdown-menu-link" :class="isActive(item.path) && `active`"
                                    :href="`${baseUrl}${item.path}`">

                                    <span class="header-dropdown-menu-text">{{  item.name  }}</span>

                                    <span v-if="item.live" class="nav-live">
                                        <span class="dot"></span>
                                        <span class="txt">LIVE</span>
                                    </span>
                                </a>


                            </li>
                        </ul>
                    </div>
                </div>

                <div class="header-nav" v-else>
                    <div class="header-nav-item">
                        <a class="header-nav-link" :class="isActive('') && `active`" :href="baseUrl">
                            <box-icon name='home' type='solid'></box-icon>
                        </a>
                    </div>

                    <div class="header-nav-item" v-for="(menu, index) in menus" :key="index">
                        <a class="header-nav-link" :class="isActive(menu.path) && `active`"
                            :href="`${baseUrl}${menu.path}`">
                            <span class="header-nav-text">{{  menu.name  }} </span>
                            <span class="header-nav-border"></span>
                        </a>

                        <span v-if="menu.live" class="nav-live">
                            <span class="dot"></span>
                            <span class="txt">LIVE</span>
                        </span>
                    </div>
                </div>

                <div class="header-nav">
                    <button type="button" class="header-nav-item nav-item-search" @click="openDesktopSearch()">
                        <box-icon class="icon" name='search'></box-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="mobile-navbar-container" :class="drawer && `active`" v-if="menuMode == 'md' && drawer">
            <div class="mobile-navbar-backdrop" @click="closeDrawer"></div>
            <div class="mobile-navbar-nav-container">
                <div class="mobile-navbar-nav-inner">

                    <div class="mobile-navbar-header">
                        <a :href="baseUrl" class="header-brand">
                            <img :src="event.imageUrl" :alt="event.name" v-if="event.imageUrl" width="40" height="40" />
                            <img :src="logoImage" :alt="event.name" width="40" height="40" v-else />
                        </a>
                        <div>
                            <button type="button" class="btn btn-action btn-light" :href="baseUrl"
                                @click="drawer = false">
                                <box-icon class="icon" name='x'></box-icon>
                            </button>
                        </div>
                    </div>
                    <div class="mobile-navbar-searchbar">
                        <form class="mobile-searchbar-form" :action="`${baseUrl}/search`">
                            <input type="hidden" autocomplete="off" name="mobile" value="1" />
                            <button type="button" class="searchbar-icon">
                                <box-icon name="search" class="icon"></box-icon>
                            </button>
                            <input ref="searchInput" type="text" name="src" placeholder="ค้นหา..." autocorrect="off"
                                autocomplete="off" autocapitalize="off" class="searchbar-input form-control" />
                        </form>
                    </div>
                    <div class="mobile-navbar-nav">
                        <div class="mobile-navbar-item">
                            <a class="mobile-navbar-link" :class="isActive('') && `active`" :href="`${baseUrl}`">
                                <span class="mobile-navbar-text">{{  `Home`  }}</span>
                            </a>
                        </div>
                        <div class="mobile-navbar-item" v-for="(menu, index) in menus" :key="index">
                            <a class="mobile-navbar-link" :class="isActive(menu.path) && `active`"
                                :href="`${baseUrl}${menu.path}`">
                                <span class="mobile-navbar-text">{{  menu.name  }}</span>

                                <span v-if="menu.live" class="nav-live">
                                    <span class="dot"></span>
                                    <span class="txt">LIVE</span>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="mobile-navbar-footer">

                        <!-- <div class="my-3" v-if="!verifypdpd">
                            <a :href="`${baseUrl}/account/logout?next=${baseUrl}`" v-if="isAuth"
                                class="btn btn-light btn-block">{{  $t("Logout")  }}</a>
                        </div> -->
                        <div class="my-3" v-if="isAuth">
                            <a :href="`${baseUrl}/registration`" v-if="!isRegistration"
                                class="btn btn-light btn-block">{{
                                 $t("Registration") 
                                }}</a>
                            <a :href="`${baseUrl}/account/profile`" class="btn btn-light btn-block">{{  "My Profile" 
                                }}</a>
                            <a :href="`${baseUrl}/account/billing`" class="btn btn-light btn-block">Billing</a>
                            <a :href="`${baseUrl}/account/logout?next=${baseUrl}`" v-if="isAuth"
                                class="btn btn-light btn-block">{{  $t("Logout")  }}</a>
                        </div>
                        <div class="my-3" v-else>
                            <a :href="`${baseUrl}/login`" class="btn btn-light btn-block">{{
                                 $t("Login(Current member)") 
                                }}</a>
                            <a :href="`${baseUrl}/register`" v-if="isRegister" class="btn btn-light btn-block">{{
                                 $t("Sign up(New member)") 
                                }}</a>
                            <a :href="`${baseUrl}/reset-password`" v-if="isForgotPassword"
                                class="btn btn-light btn-block">{{  $t("Forgot Password")  }}</a>
                        </div>

                        <div class="mt-3">
                            <button type="button" @click="$i18n.locale = `en`" class="btn btn-light btn-block"
                                :class="$i18n.locale == 'en' && `active`">EN</button>
                            <button :class="$i18n.locale == 'th' && `active`" type="button" @click="$i18n.locale = `th`"
                                class="btn btn-light btn-block">TH</button>
                        </div>

                        <div class="mt-4">
                            <a class="text-muted" href="https://educathai.com/privacy-policy" target="_blank">{{
                                 $t('Privacy Policy') 
                                }}</a>
                            <span> · </span>
                            <a class="text-muted" href="https://educathai.com/privacy-policy" target="_blank">{{
                                 $t('Terms-and-Conditions') 
                                }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="desktop-search-container" :class="desktopSearchShow && `active`" v-show="desktopSearch">
            <div class="desktop-search-backdrop" @click="closeDesktopSearchHandler"></div>
            <div class="desktop-search-content">
                <form class="desktop-search-form" :action="`${baseUrl}/search`" id="frmDesktopSearch">
                    <input type="hidden" autocomplete="off" name="desktop" value="1" />
                    <button type="button" class="searchbar-icon">
                        <box-icon name="search" class="icon"></box-icon>
                    </button>
                    <input ref="desktopSearchInput" type="text" name="src" :placeholder="$t('Search')" autocorrect="off"
                        autocomplete="off" autocapitalize="off" class="searchbar-input form-control form-control-lg" />

                    <button type="submit" ref="btnsubmit" class="btn btn-lg btn-primary">{{  $t('Search')  }}</button>
                </form>
            </div>

            <button type="button" class="desktop-search-close btn btn-action btn-light"
                @click="closeDesktopSearchHandler">
                <box-icon class="icon" name='x'></box-icon>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'event',
        'menus', 'lgMenus',
        'isAuth',
        'isRegistration',

        'isRegister', 'isForgotPassword', 'isLogin',

        'verifypdpd',

        'memberName',
        'logo' ,
    ],

    computed: {
        baseUrl() {
            return `/${this.event.slug}`
        }
    },

    data() {
        return {
            lgWidth: 1450,
            mdWidth: 796,

            menuMode: '',

            fullWidth: 0,

            drawer: false,
            desktopSearch: false,
            desktopSearchShow: false,

            thumbnailLogo: `/static/logos/educa-2021.png` ,
            logoImage :  `/static/logos/educa-2021.png` 
        }
    },

    created() {
        window.addEventListener("resize", this.resizeHandler);
        window.addEventListener("scroll", this.scrollHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
        window.removeEventListener("scroll", this.scrollHandler);
    },

    mounted() {
        if( this.logo ){
            this.logoImage = this.logo
        } 
        this.resizeHandler()
        this.scrollHandler()

    },

    watch: {
        drawer(val) {
            const body = document.body;

            if (val) {
                body.classList.add("overflow-hidden");
            }
            else {
                body.classList.remove("overflow-hidden");
            }
        },

        desktopSearch(val) {
            const body = document.body;

            if (val) {
                body.classList.add("overflow-hidden");
            }
            else {
                body.classList.remove("overflow-hidden");
            }
        }
    },

    methods: {
        resizeHandler() {
            this.fullWidth = window.innerWidth
            // this.drawer = false
            this.closeDesktopSearchHandler()

            // if( this.fullWidth  ){

            // }

            if (this.fullWidth < this.mdWidth) {
                this.menuMode = 'md'
            } else if (this.fullWidth < this.lgWidth) {
                this.menuMode = 'lg'
            }
            else {
                this.menuMode = null
            }
        },
        scrollHandler() {
            // this.$store.dispatch("ui/setScrollY", window.scrollY);
        },

        mobileSearch() {
            // this.drawer = true

            // setTimeout(() => {
            //     if (this.$refs.searchInput) {
            //         this.$refs.searchInput.focus()
            //     }
            // }, 1);
            window.location = `${this.baseUrl}/search?mobile=1&src=`
        },

        closeDrawer() {

            setTimeout(() => {
                this.drawer = false
            }, 1);
        },

        openDesktopSearch(){
            $('#frmDesktopSearch').submit()
        },

        openDesktopSearchHandler() {
            this.desktopSearch = true

            setTimeout(() => {
                this.desktopSearchShow = true

                setTimeout(() => {
                    if (this.$refs.desktopSearchInput) {
                        this.$refs.desktopSearchInput.focus()
                    }
                }, 1);

            }, 1);
        },

        closeDesktopSearchHandler() {
            this.desktopSearchShow = false

            setTimeout(() => {
                this.desktopSearch = false
            }, 1);
        },

        isActive(path) {
            const currentPath = location.pathname.substring(1);
            return `${this.baseUrl}${path}` == `/${currentPath}`
        }
    }
}
</script>