<template>
    <div class="soon-page">
        <div class="container">
            <div class="row">
                <div class="col-md-5 mb-3 text-center text-md-left font-weight-bold">
                    <h1>Coming Soon</h1>
                    <p>เตรียมพบกับ EDUCA 2022 มหกรรมทางการศึกษาเพื่อพัฒนาวิชาชีพครูประจำปี 2565 ในรูปแบบออนไลน์
                        ภายใต้แนวคิด "ร่วมสร้างคุณค่าใหม่...เรียนรู้เพื่อชีวิต Value Creation: Learn for Life "</p>
                    <p>เปิดโลกการเรียนรู้ใหม่ ที่ไม่เหมือนเดิมอีกต่อไป </p>
                </div>
                <div class="col-md-7 mb-3 soon-countdown-container">
                    <div class="soon-countdown-row" :class="display && `active`">
                        <div class="soon-countdown-box" v-if="days > 0">
                            <div class="counter">{{ days }}</div>
                            <div class="label">Days</div>
                        </div>
                        <div class="soon-countdown-box">
                            <div class="counter">{{ hours }}</div>
                            <div class="label">Hours</div>
                        </div>
                        <div class="soon-countdown-box">
                            <div class="counter">{{ minutes }}</div>
                            <div class="label">Minutes</div>
                        </div>
                        <div class="soon-countdown-box">
                            <div class="counter">{{ seconds }}</div>
                            <div class="label">Seconds</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="d-flex mt-5 justify-content-center justify-content-md-start">
                <a :href="eventBastUrl('')" class="btn btn-lg btn-primary min-w-120">กลับสู่หน้าหลัก</a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: ['event', 'countdownDate'],
    data() {
        return {
            days: '',
            hours: '',
            minutes: '',
            seconds: '',

            isExpired: false,
            display: false,

            countInterval: null,

            countdown: null,
        }
    },

    mounted() {

        const theDate = new Date()
        theDate.setDate(theDate.getDate() + 7)

        let countdownDate = theDate
        if (this.countdownDate) {
        }


        this.distance = new Date(countdownDate).getTime();
        this.randerTime()

        // const countDownDate = new Date(countdownDate).getTime();
        // this.countInterval = setInterval(() => {

        //     // Get today's date and time
        //     const now = new Date().getTime();

        //     // Find the distance between now and the count down date
        //     const distance = countDownDate - now;

        //     // Time calculations for days, hours, minutes and seconds
        //     this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        //     this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        //     this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        //     this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

        //     this.display = true

        //     // Display the result in the element with id="demo"
        //     // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
        //     //     + minutes + "m " + seconds + "s ";

        //     // If the count down is finished, write some text
        //     if (distance < 0) {
        //         clearInterval(this.countInterval);

        //         this.isExpired = true
        //         this.display = false
        //         // document.getElementById("demo").innerHTML = "EXPIRED";
        //     }
        // }, 1000);
    },

    methods: {
        eventBastUrl(path) {
            return `/${this.event.slug}${path}`
        },

        async randerTime() {

            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = this.distance - now;

            // Time calculations for days, hours, minutes and seconds
            this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

            this.display = true

            // Display the result in the element with id="demo"
            // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
            //     + minutes + "m " + seconds + "s ";

            // If the count down is finished, write some text
            if (distance < 0) {
                this.isExpired = true
                this.display = false
            }
            else {

                await this.timeout(1000);
                this.randerTime()
            }
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    }
}
</script>

<style lang="scss" scoped>
.soon-page {
    padding-top: 60px;
    padding-bottom: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 300px);
    background-color: var(--soon-page-bg, #e673d7);

    h1 {
        font-weight: bold;
        font-size: 42px;
        margin-bottom: 24px;
    }

    .soon-countdown-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }




}

.soon-countdown-row {
    display: flex;
    gap: 16px;
    transition: opacity .2s ease-out;
    opacity: 0;

    &.active {
        opacity: 1;
    }
}

.soon-countdown-box {
    padding: 8px;
    text-align: center;
    background-color: var(--soon-countdown-bg, rgba(255, 255, 255, .55));
    min-width: 70px;


    border-radius: 8px;

    .counter {
        font-size: 42px;
        font-weight: bold;
    }

    .label {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    .soon-countdown-box {
        min-width: 140px;
        padding: 24px;

        .counter {
            font-size: 72px;
        }

        .label {
            font-size: 24px;
        }
    }
}
</style>