import { initializeApp } from "firebase/app";

// Initialize Firebase
/**
 * event 2022
 */
const firebaseConfig2022 = {
    apiKey: "AIzaSyCMRBB48rPaCQI9vgw0AmECuiZjG8jXUx8",
    authDomain: "educa-2022.firebaseapp.com",
    projectId: "educa-2022",
    storageBucket: "educa-2022.appspot.com",
    messagingSenderId: "269512681274",
    appId: "1:269512681274:web:62f1c6570d8b4716ad5203",
    measurementId: "G-61PCSMFNCC"
}

/**
* event 2021
*/
const firebaseConfig2021 = {
    apiKey: "AIzaSyD1Of70VuAJWlOlU5xUG05V8GQUw9TN3dI",
    authDomain: "educa-2021.firebaseapp.com",
    projectId: "educa-2021",
    storageBucket: "educa-2021.appspot.com",
    messagingSenderId: "542109653368",
    appId: "1:542109653368:web:d5e25ae1a99b1fdc77908d"
}

const app = initializeApp(firebaseConfig2021);
export default app