<template>
    <nav class="nav-pagination" v-if="numPages > 0">
        <ul class="pagination">
            <li v-for="(page, i) in pages" class="page-item" :class="[page.isActive && `active`]" :key="i">
                <a class="page-link" href="#" @click.prevent="goToPage(page.page, page.disabled)"
                    :class="[page.disabled && `disabled`, page.isActive && `active`]" :aria-disabled="page.disabled">{{
                            page.label
                    }}</a>
            </li>
        </ul>
    </nav>

</template>

<script>
export default {
    props: ["total", "current", 'lastPage'],
    data() {
        return {
            currentPage: 0,
            midRange: 1,
            numPages: 0,
            limit: 10, // default 10,

            prevLabel: 'ก่อนหน้า', // Previous
            nextLabel: 'ถัดไป', // Next

            pages: [],

            maxW450: false,
            w450: 450,
        };
    },

    mounted() {
        // console.log(this.total, this.current, this.lastPage);

        this.numPages = this.lastPage
        this.currentPage = this.current
    },

    watch: {
        currentPage() {
            this.renderPage()
        },

        lastPage(val) {
            this.numPages = val

            this.renderPage()
        }
    },

    methods: {
        renderPage() {
            let pages = [];

            const currentPage = this.currentPage;
            const midRange = this.midRange;
            const numPages = this.numPages;

            // console.log('currentPage => ',currentPage, 'midRange => ', midRange, 'numPages =>', numPages);

            if (numPages <= 0) {
                this.pages = pages
                return false
            }

            pages.push({ label: "ก่อนหน้า", page: currentPage - 1, isActive: currentPage == 1 && currentPage > 1, disabled: currentPage <= 1 });

            if (currentPage > midRange + 1) {
                pages.push({ label: 1, page: 1 });

                if (currentPage > midRange + 2) {
                    pages.push({ label: "...", disabled: true });
                }
            }

            for (
                let i = currentPage - midRange;
                i < currentPage + midRange + 1;
                i++
            ) {
                if (i > 0 && i <= numPages) {
                    pages.push({
                        label: i,
                        page: i,
                        isActive: i == currentPage
                    });
                }
            }

            if (currentPage < numPages - midRange) {
                if (currentPage < numPages - midRange - 1) {
                    pages.push({ label: "...", disabled: true });
                }
            }

            pages.push({
                label: this.nextLabel,
                page: currentPage >= numPages ? null : currentPage + 1,
                disabled: currentPage >= numPages,
                // isActive: currentPage==this.lastPage && currentPage!
            });

            this.pages = pages
            // console.log(pages);
        },

        goToPage(page, disabled) {
            if (!page) return false;

            this.currentPage = page
            this.$emit('page-change', page)
        },

        resizeEventHandler() {
            this.maxW450 = window.innerWidth < this.w450;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
