<template>
    <div id="sra">

        <div class="searchform-container">
            <div class="container">
                <div class="searchform">
                    <button type="button" aria-label="ค้นหา" class="searchform-icon">
                        <box-icon name='search'></box-icon>
                    </button>
                    <input type="text" class="searchform-input form-control" v-model="question" @keyup.enter="searchHandler"
                        :placeholder="placeholder">

                    <button v-if="question != ''" @click="question = ''" class="clear-input" type="button">
                        <box-icon name='x'></box-icon>
                    </button>
                </div>
            </div>
        </div>

        <div id="workshop" class="module inner container" ref="container">
            <div class="module-content">
                <Skeleton v-if="splash" :limit="3" />

                <div v-else>
                    <div class="py-4" v-if="categories.length == 0">ไม่พบผลลัพธ์ที่คุณค้นหา ลองใช้คำค้นหาอื่น</div>
                    <div v-else class="mb-3 mb-md-5" v-for="(category, n) in categories" :key="n">
                        <div class="module-header">
                            <div class="d-flex justify-content-center align-items-center">
                                <h2 class="module-header-title text-uppercase">{{ category.title }}</h2>
                            </div>
                        </div>
                        <div class="workshop-grid">
                            <div class="workshop-item" v-for="(item, i) in category.workshops" :key="i">
                                <a :href="item.url" class="workshop-card workshop-link">
                                    <div class="workshop-media">
                                        <div class="workshop-pic">
                                            <img class="lazyload" :src="item.imageUrl" :alt="item.title"
                                                v-if="item.imageUrl" />
                                        </div>
                                    </div>
                                    <div class="workshop-body">
                                        <div class="workshop-content">
                                            <h3 class="headline" v-html="item.title"></h3>

                                            <div class="workshop-desc">{{ item.desc }}</div>
                                        </div>
                                        <div class="workshop-footer">
                                            <div class="timestamp">{{ item.dateStr }}</div>
                                            <div class="hashtags fs-13">
                                                <span v-for="tag in limitHashtag(item.hashtags)" :key="tag.id">#{{
                                                tag.name
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center py-4">

                <vue-pagination v-if="!maxW450" :current="currentPage" :total="totalPages" :last-page="lastPage"
                    @page-change="pageChangeHandler"></vue-pagination>

                <div class="d-flex justify-content-center" v-else-if="isMore">
                    <button type="button" :class="isLoadMore && `disabled`" :disabled="isLoadMore"
                        class="btn btn-sm btn-outline-primary rounded-pill min-w-120" @click.prevent="loadMoreHandler">
                        <span v-if="isLoadMore">กำลังโหลด...</span>
                        <span v-else>โหลดเพิ่ม...</span>
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import VuePagination from "../components/VuePagination/index.vue";
import Skeleton from "../components/Skeleton.vue";

export default {
    name: 'SearchPage',
    components: { VuePagination, Skeleton },
    props: ["ariaSearch", 'placeholder', 'eventId'],
    data() {
        return {
            // items: []
            storage: `https://educathai.com/storage/`,

            filters: {
                page: 1,
                limit: 8,
                q: ''
            },

            question: '',

            splash: true,

            currentPage: 1,
            totalPages: 0,
            lastPage: 0,

            isFilter: false,
            meta: {},
            posts: {},
            isPosition: false,

            isLoadMore: false,
            isMore: false,

            maxW450: false,
            w450: 450,


            categories: [],
            tm: null,

            // isAll: false
        };
    },

    watch: {
        question() {
            this.debounced()
        }
    },

    created() {
        if (this.limit) {
            this.filters.limit = this.limit;
        }

        this.question = this.ariaSearch;

        this.debounced = _.debounce(this.setQuestion, 500);
        window.addEventListener("resize", this.resizeEventHandler);
    },

    destroyed() {
        window.removeEventListener("resize", this.resizeEventHandler);
    },
    mounted() {
        this.resizeEventHandler()
        //this.searchHandler();
        this.splash = false
        this.isLoad = false
        this.searchHandler()
    },

    methods: {
        async searchHandler(){
            var url = new URL(window.location.href);
            var search_params = url.searchParams;
                search_params.set('src', this.question);
                url.search = search_params.toString();
            var new_url = url.toString();
            window.history.pushState({path:new_url},'',new_url);

            this.isLoad = true;
            this.filters.page = 1;
            this.filters.q = this.question;
            this.filters['action'] = '';
            if (this.eventId) {
                this.filters.eid = this.eventId;
            }
            this.splash = true

            try {
                this.filters['action'] = 'enter'
                const { data: response } = await axios.get(`/api/search`, { params: this.filters })
                this.categories = response.data
                this.splash = false
                this.isLoad = false;

            } catch (error) {

            }

            this.isLoad = false;
        },
        setQuestion() {
            //this.getPost();
        },

        async getPost() {
            this.isLoad = true;
            this.filters.page = 1;
            this.filters.q = this.question;
            this.filters['action'] = '';
            if (this.eventId) {
                this.filters.eid = this.eventId;
            }
            this.splash = true

            if (this.tm) {
                clearTimeout(this.tm)
            }

            this.tm = setTimeout(() => {
                this.loadPost()
            }, 100);
        },

        async loadPost() {
            try {
                const { data: response } = await axios.get(`/api/search`, { params: this.filters })

                this.categories = response.data
                this.splash = false

            } catch (error) {

            }

            this.isLoad = false;
        },

        pageChangeHandler(selectedPage) {
            this.currentPage = selectedPage;

            this.isPosition = true;
            this.getPost();
        },

        loadMoreHandler() {
            this.isLoadMore = true;
            this.currentPage++
            this.getPost();
        },

        resizeEventHandler() {
            this.maxW450 = window.innerWidth < this.w450;
        },

        limitHashtag(data) {
            const tags = data.slice(0, 2).map((tag) => {
                return { id: tag.id, name: tag.name }
            });

            return tags
        }
    }
};
</script>

<style lang="scss" scoped>
.workshop-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    margin-right: -15px;
}


.workshop-item {
    margin-bottom: 15px;
    padding-right: 15px;

    position: relative;
    width: 100%;

    flex: 0 0 50%;
    max-width: 50%;
}

.workshop-card {
    background-color: #fff;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    height: 100%;
}

.workshop-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 6px;
}

.workshop-pic {
    position: relative;
    display: block;
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    background: #ffd5d6;
    border-radius: 8px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.headline {
    color: #214368;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;

    font-size: 14px;
    line-height: 1.16667;
    font-weight: 700;
    margin-bottom: 0;
    max-height: 32px;

    span+span {
        margin-left: 4px;
    }
}

.workshop-desc {
    display: none;
}

.workshop-link {
    text-decoration: none;
}

.timestamp {
    font-size: 13px;
    color: #6e6e73;
    white-space: nowrap;
}

.workshop-footer {
    margin-top: 3px;

    .link-more {
        display: none;
    }
}

.hashtags {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;

    text-overflow: ellipsis;

    span+span {
        margin-left: 3px;
    }
}

@media (min-width: 440px) {
    // .workshop-grid{
    //     margin-right: -15px;
    // }
    // .workshop-item {
    //     margin-bottom: 15px;
    //     padding-right: 15px;
    // }

    .headline {
        font-size: 16px;
        max-height: 46.4px;
    }
}

@media (min-width: 768px) {
    .workshop-card {
        // box-shadow: rgb(0 0 0 / 7%) 0px 2px 6px 0px;
        // box-shadow: 0 1px 2px rgba(0,0,0,.2);
        // transition: all 0.8s ease 0s;

        box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
        transition: all .3s cubic-bezier(0, 0, .5, 1);

        .workshop-pic img {
            transition: transform 400ms cubic-bezier(0.4, 0, 0.25, 1) 0ms,
                opacity 1s cubic-bezier(0.4, 0, 0.25, 1) 0ms;
        }

        .headline {
            transition: color 400ms cubic-bezier(0.4, 0, 0.25, 1) 0ms;
        }

        &:hover {
            // box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 20px 0px;
            box-shadow: 2px 4px 16px rgba(0, 0, 0, .2);

            .workshop-pic img {
                transform: scale(1.05);
                // transform: rotate(2deg) scale(1.1);
            }

            .headline {
                color: #1d1d1f;
            }

            .link-more {
                color: #214368;
            }

            .link-more svg {
                width: 18px;
            }
        }
    }

    .workshop-item {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .workshop-body {
        padding: 12px;
    }

    .workshop-footer {
        display: flex;
        justify-content: space-between;

        .link-more {
            font-size: 13px;
            position: relative;
            transform: translate3d(0px, 0px, 0px);
            color: #777;

            display: flex;
            align-items: center;

            svg {
                transition: width 0.3s ease 0s;
                width: 0;
            }
        }
    }

    .workshop-pic {
        border-radius: 8px 8px 0 0;
    }

    .workshop-content {
        flex: 1 1 auto;
    }

    .headline {
        font-size: 18px;
        line-height: 1.3;
    }

    .workshop-desc {
        display: block;
        color: #777;
        font-size: 13px;
        line-height: 1.3;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-top: 3px;
    }

    .workshop-footer {
        margin-top: 6px;
    }
}

@media (min-width: 1024px) {
    .workshop-item {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .workshop-card {
        flex-direction: row;
        align-items: flex-start;
    }

    .workshop-media {
        width: 45%;
    }

    .workshop-body {
        flex: 1;
    }

    .workshop-pic {
        border-radius: 8px 0 0 8px;
    }

    .hashtags {
        width: 200px;
        text-align: right;
    }
}

.searchform-container {
    // border-bottom: 1px solid #d6d6d6;
    // background-color: #fff;
    padding: 20px 0;
    background-color: #f5f5f7;

    .searchform {
        position: relative;
        max-width: 346px;

        .searchform-icon {
            width: 30px;
            height: 30px;
            align-items: center;
            display: flex;
            border-radius: 50%;
            border-width: 0;
            padding: 3px;
            left: 4px;
            position: absolute;
            top: 5px;
            background-color: transparent;
            z-index: 1;
        }

        .searchform-input {
            padding-left: 37px;
            padding-right: 37px;
        }

        .clear-input {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border-width: 0;
            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -12px;
            background: transparent;
            outline: none;
            padding: 0;

            &:hover {
                background: #f2f2f2;
            }

            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -12px;
        }
    }
}
</style>
