<template>
    <div class="d-lg-flex signin-layout">
        <div class="h-100 d-lg-flex flex-column justify-content-center h-100">
            <div class="form-signin">

                <div class="text-center px-2">
                    <h1 class="mb-3 fw-700">Security verification</h1>
                    <div>เพื่อความปลอดภัยของการเข้าสู่ระบบสมาชิก กรุณาดำเนินการตามขั้นตอนให้เสร็จสมบูรณ์</div>
                    <div>To secure your account, please complete the following verification.</div>
                </div>

                <div class="signin-section d-flex flex-row justify-content-center">

                    <form @submit.prevent="loginWithTel" class="signin-with-email" v-if="verifyPhoneNumber">
                        <div class="mb-3">
                            <label for="verificationCodeInput">
                                <span>{{ $t('labelVerifyPhoneNumber') }}</span>
                                <span>{{ hidePhoneNumber }}</span>
                            </label>

                            <div class="font-weight-bold" v-if="refno">{{ $t('Ref') }}: {{ refno }}</div>

                            <div class="input-wrap">
                                <input id="verificationCodeInput" type="tel" required="required"
                                    class="form-control form-control-lg" v-model="verificationCode" autocomplete="off"
                                    :class="[isLoad && `disabled`, loadSentCode && `disabled`]"
                                    :disabled="isLoad || loadSentCode" maxlength="8" />

                                <button type="button" class="input-wrap-suffix" @click="sentCode">
                                    <span v-if="isSentCode">Get Code</span>
                                    <span v-else>{{ progressTimer }}s</span>
                                </button>
                            </div>

                            <div class="fs-80p mt-1">
                                <span class="text-link font-weight-bold" @click="changePhoneNumber">{{
                                        $t('verifyChangePhoneNumber')
                                }}</span>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-lg btn-primary btn-block mt-4"
                            :class="[!submitReady && `disabled`, isError && `btn-error`]" :disabled="!submitReady">
                            <span class="text-white fw-600">{{
                                    isLoad ? `${$t("Loading")}...` : `Submit`
                            }}</span>
                        </button>

                    </form>

                    <form @submit.prevent="updatePhoneNumber" class="signin-with-email" v-else>
                        <div class="mb-3">
                            <label for="phoneNumberInput">
                                <div class="font-weight-bold">Phone number</div>
                                <div class="fs-90p text-danger">กรุณากรอกหมายเลขโทรศัพท์มือถือเพื่อยืนยันตัวตน</div>
                                <div class="fs-90p text-danger">* หมายเลขโทรศัพท์ 1 หมายเลขต่อ 1 สมาชิกเท่านั้น</div>
                            </label>

                            <!-- <div class="mb-2">
                                <select class="custom-select custom-select-lg" v-model="countryCodeInput">
                                    <option value=""></option>
                                    <option :value="item.dial_code" v-for="(item, index) in callingcodes" :key="index">
                                        {{ item.name }} ({{ item.dial_code }})</option>
                                </select>
                            </div> -->
                            <input id="phoneNumberInput" type="tel" required="required"
                                class="form-control form-control-lg" v-model="phoneNumberInput" autocomplete="off"
                                :class="[isLoad && `disabled`, phoneNumberInputError && `is-invalid`]" maxlength="15" />

                            <div class="invalid-feedback" v-if="phoneNumberInputError">{{ phoneNumberInputError }}</div>
                        </div>

                        <button type="submit" class="btn btn-lg btn-primary btn-block mt-4"
                            :class="[!phoneNumberInput && `disabled`, phoneNumberInputError && `btn-error`]"
                            :disabled="!phoneNumberInput">
                            <span class="text-white fw-600">{{
                                    isLoad ? `${$t("Loading")}...` : $t("Next")
                            }}</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: ['event', 'token'],
    computed: {
        submitReady() {

            if (this.isLoad) {
                return false
            }
            else if (this.verificationCode == '') {
                return false
            }

            return true
        },
        hidePhoneNumber() {
            let phoneNumber = this.verifyPhoneNumber
            return phoneNumber.substr(0, 3) + '***' + phoneNumber.substring(phoneNumber.length - 4);
        }
    },
    data() {
        return {
            verificationCode: '',
            verifyCountryCode: '',
            verifyPhoneNumber: '',

            countryCodeInput: '+66',
            phoneNumberInput: '',
            phoneNumberInputError: null,

            isLoad: false,
            isError: false,

            memberId: `1`,

            isSentCode: true,
            timeleft: 60,

            downloadTimer: null,
            progressTimer: 60,

            refno: null,
            verifyToken: null,

            loadSentCode: false,

            callingcodes: [],
            cachePhoneNumber: '',
        }
    },

    async mounted() {
        await this.getCountryCode()

        // console.log( this.event, this.token );
        // this.verifyPhoneNumber = '0843635952'
        // if (this.token && this.verifyPhoneNumber) {
        //     // auto sendOTPToPhoneNumber
        //     this.sentCode()
        // }
    },
    methods: {
        async getCountryCode() {
            const { data: response } = await axios.get('/api/country/code')

            if (response.success) {
                this.callingcodes = response.data
            }
        },

        async loginWithTel() {
            this.isLoad = true

            const payload = {
                token: this.token,
                // ...this.form,
                verifyToken: this.verifyToken,
                verifyCode: this.verificationCode,

                phoneNumber: this.verifyPhoneNumber,
                countryCode: this.countryCodeInput,
                eventId: this.event.id
            }

            try {
                const { data: response } = await axios.post('/api/login/otp', payload)

                if (response.success) {
                    const redirectUrl = response.redirectUrl || '/'

                    this.$swal({
                        icon: 'success',
                        title: response.intro || this.$t('Login Welcome'),
                        text: response.message,
                    }).then(() => {
                        window.location.href = redirectUrl
                    });

                }
                else {
                    this.formFail()
                }
            } catch (error) {

                this.$swal({
                    icon: 'error',
                    title: this.$t('Oops'),
                    text: this.$t('OTP Invalid'),
                }).then(() => {

                    this.verificationCode = ''
                });
            }

            this.isLoad = false
        },

        sentCode(checked) {
            if (!this.isSentCode && !checked) return
            this.isSentCode = false

            clearInterval(this.downloadTimer)
            let timeleft = this.timeleft
            this.progressTimer = this.timeleft

            this.sendOTPToPhoneNumber()

            this.downloadTimer = setInterval(() => {
                if (timeleft <= 1) {
                    clearInterval(this.downloadTimer);
                    this.isSentCode = true
                }

                timeleft -= 1;
                this.progressTimer = timeleft;
            }, 1000);
        },

        async sendOTPToPhoneNumber() {
            this.loadSentCode = true
            await this.timeout(3);
            const payload = {
                // ...this.form,
                phoneNumber: this.phoneNumberInput,
                countryCode: this.countryCodeInput,
            }

            try {
                const { data: response } = await axios.post('/api/send/otp/verify/phoneNumber', payload)
                if (response.success) {
                    this.refno = response.data.refno
                    this.verifyToken = response.data.token
                }
                else {
                    this.formFail()
                }
            } catch (error) {
                this.formFail()

                clearInterval(this.downloadTimer)
                this.isSentCode = true
            }

            this.loadSentCode = false
        },

        formFail(title, message) {
            this.$swal({
                icon: 'error',
                title: title || this.$t('Oops'),
                text: message || this.$t('OopsMessage'),
            });
        },

        async updatePhoneNumber() {
            this.phoneNumberInputError = null

            await this.timeout(3);
            if (!this.validatePhoneNumber()) return;

            const payload = {
                phoneNumber: this.phoneNumberInput,
                countryCode: this.countryCodeInput,
            }

            try {
                const { data: response } = await axios.post('/api/verify/check/phoneNumber', payload)

                if (response.success) {
                    this.verifyPhoneNumber = this.phoneNumberInput
                    this.verificationCode = ''
                    // this.verifyCountryCode = response.data.countryCode

                    if (this.verifyPhoneNumber != this.cachePhoneNumber) {
                        await this.timeout(3);
                        this.sentCode(true)
                    }
                }

            } catch (error) {
                if (error.response.data) {
                    this.phoneNumberInputError = error.response.data.message
                }
            }
        },

        validatePhoneNumber() {
            const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

            let validated = re.test(this.phoneNumberInput)
            if (!validated) {
                this.phoneNumberInputError = 'เบอร์โทรศัพท์ไม่ถูกต้อง'
            }

            return validated;
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        changePhoneNumber() {
            this.cachePhoneNumber = this.verifyPhoneNumber
            this.verifyPhoneNumber = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.input-wrap {
    position: relative;

    .form-control {
        padding-right: 80px;
    }

    .input-wrap-suffix {
        border: none;
        background-color: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }

}

.text-link {
    cursor: pointer;
}

.fs-80p {
    font-size: 80%;
}
</style>