<template>
    <div class="step">

        <div class="step-item" v-for="(step, index) in steps" :key="index">
            <div class="step-link"
                :class="[step.id == stepId && `active`, step.id < stepId && `visited`, step.isDone && `doned`,currentStapId == step.id && `current`]"
                @click="activeStep(step)">
                <span class="step-dot"></span>
                <span class="step-no">{{ step.id }}.</span>
                <span class="step-text">{{ step.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['steps', 'stepId', 'currentStapId'],

    methods: {
        activeStep(step) {
            if (this.currentStapId == step.id || step.isDone || step.id < this.stepId) {
                this.$emit('next', step.id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.step {
    display: flex;
    height: 54px;

    justify-content: center;
    align-items: center;

    gap: 16px;

    .step-item {
        display: flex;
        align-items: center;
    }

    .step-link {
        cursor: default;
        position: relative;
        color: var(--step-link-color, #888);
        // display: flex;
        // gap: 5px;
        // align-items: center;

        &.active {
            font-weight: bold;
            color: var(--step-link-active-color, #000);
        }

        &.visited {
            font-weight: bold;
            cursor: pointer;
            color: var(--step-link-active-color, #000);
        }

        &.current{
            font-weight: bold;
            cursor: pointer;
            color: var(--step-link-active-color, #000);
        }

        &.doned {
            font-weight: bold;
            cursor: pointer;
            color: #28a745;

            .step-dot {
                left: -15px;
                opacity: 1;
            }
        }

        .step-dot {
            position: absolute;
            left: 0;
            top: 50%;
            transition-property: all;
            transition-timing-function: cubic-bezier(.19, 1, .22, 1);
            transition-duration: 680ms;
            display: inline-flex;
            opacity: 0;
            height: 14px;
            width: 11px;
            // margin-right: -8px;
            background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTEgOCI+CiAgPHBhdGggZmlsbD0iIzVEQjY1NSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC44NDY3MDU3MSw4LjMzOTI3OTE5IEM0Ljc2NzQyMDA1LDguNTQ3OTAwMDkgNC42MTYxMzU1OSw4LjczMjgxMDg1IDQuNDAyNzUxNzIsOC44NTYxNTQwOSBDMy45MDc0MDY3OCw5LjE0MjQ4MDU4IDMuMjY0NTkyNzQsOC45ODc5NTUxOCAyLjk2NjgxODU5LDguNTExNjUzMzIgQzIuOTYxMTQ2Nyw4LjUwMTY1NDYyIDIuOTU0NTI5NSw4LjQ5MTY1NTkyIDIuOTQ4ODU3NjEsOC40ODE2NTcyMiBMMC4xMzE4MTk2MDcsNS40MjIwNTQxMiBDLTAuMTQ4OTM4ODc5LDQuOTM1NzUzNTcgMC4wMzM1MDY4NzEzLDQuMzI0MDE0NzUgMC41MzkyNTAyNzEsNC4wNTQwNDk3NiBDMS4wMzI3MDQ1OCwzLjc5MTM1NjU3IDEuNjUzNzc2MzgsMy45NTEzMzU4MSAxLjk0Mzk4ODAxLDQuNDE1ODIxMDIgTDMuODMzNzAyOTIsNi40NjgyNTI1MyBMOS4xOTQ1NTIyNywxLjMxMzQ5NjE5IEM5LjU5MjUyOTc5LDAuOTEwODIxMTUyIDEwLjI1NTE5NTQsMC44OTM1NTA2NjQgMTAuNjczOTY5OSwxLjI3NjIyODMgQzExLjA5Mjc0NDMsMS42NTg5MDU5MyAxMS4xMTA3MDUzLDIuMjk2MDk1OTkgMTAuNzEyNzI3OCwyLjY5ODc3MTAzIEMxMC43MDA0Mzg3LDIuNzExNDk2NjUgMTAuNjg3MjA0MywyLjcyNDIyMjI4IDEwLjY3Mzk2OTksMi43MzYwMzg5MiBMNC44NDY3MDU3MSw4LjMzOTI3OTE5IFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTEpIi8+Cjwvc3ZnPgo=) no-repeat 50%;
            transform: translateY(-50%);
        }
    }

    .step-item:not(:last-child)::after {
        content: '';
        height: 10px;
        width: 6px;
        margin: 0 24px;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+ICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjYgLTE5KSI+ICAgIDxwYXRoIHN0cm9rZT0iIzdBOTJBNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTAsMCBMMy44NTg1Nzg2NCwzLjg1ODU3ODY0IEMzLjkzNjY4MzUsMy45MzY2ODM1IDMuOTM2NjgzNSw0LjA2MzMxNjUgMy44NTg1Nzg2NCw0LjE0MTQyMTM2IEwwLDgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU2NyAyMCkiLz4gIDwvZz48L3N2Zz4=) no-repeat;
    }

}
</style>