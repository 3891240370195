<template>
    <div class="skeleton-grid">
        <div class="skeleton-item" v-for="(item, i) in items" :key="i">
            <div class="skeleton-card">
                <div class="skeleton-media">
                    <div class="skeleton-pic"></div>
                </div>
                <div class="skeleton-content">
                    <div class="skeleton-title"></div>
                    <div class="skeleton-desc"></div>
                    <div class="skeleton-desc w60"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["limit"],
    data() {
        return {
            items: []
        };
    },

    created() {
        const limit = this.limit || 8;

        for (let index = 0; index < limit; index++) {
            this.items.push({ id: index });
        }
    }
};
</script>