<template>
  <div class="d-lg-flex signin-layout">
    <div class="h-100 d-lg-flex flex-column justify-content-center h-100">
      <div class="form-signin">
        <div class="text-center">
          <h1 class="mb-3 fw-700">{{ $t('Reset password') }}</h1>
        </div>
        <div class="signin-section d-flex flex-row justify-content-center">

          <form @submit.prevent="resetPassword" class="signin-with-email">

            <!-- <div class="d-flex justify-content-center mb-3">
              <div class="signin-switch">
                <div class="signin-switch-item">
                  <button type="button" :class="withEmail && `active`" @click="withEmail = true">Email</button>
                </div>
                <div class="signin-switch-item">
                  <button type="button" :class="!withEmail && `active`" @click="withEmail = false">Phone
                    Number</button>
                </div>
              </div>
            </div> -->

            <div class="mb-3" v-if="withEmail">
              <label for="email">{{ $t("email") }}</label>
              <input id="email" type="text" required="required" autocomplete="email" autofocus="autofocus"
                class="form-control form-control-lg" v-model="email"
                :class="[isLoad && `disabled`, errors.email && `is-invalid`]" :disabled="isLoad" />
              <div class="invalid-feedback" v-if="errors.email">{{ errors.email }}</div>
            </div>
            <div class="mb-3" v-else>
              <label for="phoneNumber">{{ $t("Phone Number") }}</label>

              <div class="phoneNumberInput-wrap">
                <div class="mb-2">
                  <select class="custom-select custom-select-lg" v-model="countryCodeInput">
                    <option value=""></option>
                    <option :value="item.dial_code" v-for="(item, index) in callingcodes" :key="index">
                      {{ item.name }} ({{ item.dial_code }})</option>
                  </select>
                </div>

                <div>
                  <input id="phoneNumber" type="tel" required="required" class="form-control form-control-lg"
                    v-model="phoneNumber" :class="isLoad && `disabled`" :disabled="isLoad" maxlength="10" />
                </div>
              </div>

            </div>

            <button type="submit" class="btn btn-lg btn-primary btn-block mt-4"
              :class="[isLoad && `disabled`, isError && `btn-error`]" :disabled="isLoad">
              <span class="text-white fw-600">{{
              isLoad ? `${$t("Loading")}...` : $t(`Next`)
              }}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  props: ['event'],
  data() {
    return {
      isLoad: false,
      isError: false,
      errors: {},

      email: '',
      countryCodeInput: '+66',
      phoneNumber: '',

      withEmail: true,

      callingcodes: [],
    }
  },

  async mounted() {
    // await this.getCountryCode()
    // console.log(this.event);

  },


  methods: {
    async getCountryCode() {
      const { data: response } = await axios.get('/api/country/code')

      if (response.success) {
        this.callingcodes = response.data
      }
    },
    async resetPassword() {
      this.isLoad = true;
      this.isError = false

      await this.timeout(1);
      const validated = await this.validator()

      if (validated) {

        try {
          const payload = {
            eventId: this.event.id,
            email: this.email,
            withEmail: this.withEmail,
          }

          const { data: response } = await axios.post('/api/reset-password', payload)
          window.location.href = response.redirectUrl

        } catch (error) {

          this.$swal({
            icon: 'error',
            title: this.$t('Oops'),
            text: this.$t('OopsMessage'),
          });
        }
      }
      else {
        this.isError = true
      }

      this.isLoad = false
    },

    async validator() {
      this.errors = {}

      if (this.withEmail) {
        if (this.email == '') {
          this.errors.email = "กรุณาระบุอีเมล"
        }
        else if (!REGEX_EMAIL.test(this.email)) {
          this.errors.email = "อีเมลไม่ถูกต้อง"
        } else {

          const checkEmail = await this.validatorEmail()

          if (checkEmail) {
            this.errors.email = "e-mail นี้ไม่มีอยู่ในระบบ"
          }
        }
      }

      return Object.keys(this.errors).length == 0;
    },
    async validatorEmail() {
      let checkEmail = true

      try {
        const payload = {
          eventId: this.event.id,
          email: this.email,
        }

        const { data: response } = await axios.post('/api/reset-password/check-email', payload)
        if (response.success) {
          checkEmail = false
        }

      } catch (error) {
        checkEmail = true
      }

      return checkEmail;
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

  }
}
</script>