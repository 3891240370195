<template>
    <div>
        <!-- filters -->
       <div class="page-title d-flex align-items-center">
            <h1>My Course</h1>

            <div class="d-flex ml-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="year">Event: </label>
                    </div>
                    <select class="custom-select" id="year" v-model="filters.event_id" @change="changeYear">
                        <option   :value="y.event_id" v-for="y in events_lists" :key="y.event_id"    >{{ y.event_name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- result -->
        <div>
            <table class="table-course">
                <thead>
                    <tr>
                        <th class="td-index">ลำดับ</th>
                        <th class="td-name">รายการ</th>
                        <th class="td-action"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td class="td-index">{{index+1}}</td>
                        <td class="td-name">
                            <a :href="item.url" v-if="item.url" target="_blank"><strong v-html="item.title"></strong></a>
                            <strong v-else v-html="item.title"></strong>
                        </td>
                        <td class="td-action">
                            <button type="button" class="btn btn-success min-w-120 disabled" disabled v-if="item.downloadDisable || !item.enable_download_cert">Certificate</button>
                            <a class="btn btn-success min-w-120" :href="item.downloadUrl" target="_blank" v-else-if="item.downloadUrl">Certificate</a>
                            <a class="btn btn-primary min-w-120" :href="item.watchUrl" :disabled="!item.url" target="_blank" v-else-if="item.watchUrl">ดูวีดีโอต่อ</a>
                            <button type="button" class="btn btn-primary min-w-120 disabled" disabled v-else>ดูวีดีโอต่อ</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex flex-column align-items-center justify-content-center">
                <div class="my-4" v-if="isLoad">
                    <div class="text-muted">กำลังโหลด...</div>
                </div>

                <div class="my-4" v-else-if="isError">
                    <div>
                        <strong class="text-danger">เกิดข้อผิดพลาด</strong>, <span class="text-muted">กรุณาลองใหม่</span>
                    </div>
                </div>

                <div class="my-4" v-else-if="isEmpty">
                    <div class="text-muted">ไม่พบข้อมูล</div>
                </div>

                <div class="my-4" v-else-if="isMore">
                    <button type="button" @click="loadMore" class="btn btn-outline-secondary min-w-120">แสดงเพิ่มเติม</button>
                </div>

                <div class="my-4" v-else-if="isDone">
                    <div class="text-muted">แสดงทั้งหมดแล้ว</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            let years = [];

            const today = new Date();
            let startYear = today.getFullYear();
            let lastYear = 2016;

            do {
                years.push( startYear );
                startYear--;
            }
            while (startYear >= lastYear);
            /*
            let events_lists = [
                {'year' : 16 ,   'event_name' : 'Principal Conference 2022' ,'selected' : 'selected'},
                {'year' : 15 ,   'event_name' : 'Mahidol Kids x EDUCA คิดช่วยครู','selected' : ''},
                {'year' : 2021 , 'event_name' : '2021' ,'selected' : ''},
                {'year' : 2020 , 'event_name' : '2020','selected' : ''},
                {'year' : 2019 , 'event_name' : '2019','selected' : ''},
                {'year' : 2018 , 'event_name' : '2018','selected' : ''},
                {'year' : 2017 , 'event_name' : '2017','selected' : ''},
                {'year' : 2016 , 'event_name' : '2016','selected' : ''}
            ];*/
            

            return {
                items: [],
                years: years,

                filters: {
                    page: 1,
                    limit: 12,
                    year: 16,
                    event_id : null
                },

                isLoad: false,
                isError: false,
                isMore: false,
                isEmpty: false,
                isDone: false,
                splash: true,
                events_lists : []
            }
        },

        async created(){
            await this.getCertificates()
        },

        methods: {

            async getCertificates(){
                setTimeout(() => {
                  axios
                    .get(`/api/my/certificates`, {
                        params: this.filters
                    })
                    .then(response => {
                        this.isLoad = false;
                        this.events_lists = response.data.data;
                        this.getItems();
                        if(this.events_lists.length > 0)
                            this.filters.event_id = this.events_lists[0].event_id;
                       // console.log( response.data.data)

                        //res.forEach(data => {
                        //    this.items.push(data);
                        //});
                        // this.items.push(...this.items, ...res.data);

                        //this.isEmpty = meta.total==0 && this.filters.page;
                        //this.isMore = meta.total>0&&links.next;
                    })
                    .catch(error => {
                        this.isLoad = false;
                        this.isError = true;
                    });

                    }, 1);
            },

            getItems(){

                this.isLoad = true;
                if( this.filters.page==1 ){
                    this.splash = true;
                    this.items = [];
                }

                setTimeout(() => {

                    axios
                    .get(`/api/account/my/courses/list`, {
                        params: this.filters
                    })
                    .then(response => {
                        this.isLoad = false;
                        const res = response.data;
                        const meta = res.meta;
                        const links = res.links;
                        this.splash = false
                        // console.log( 'response' , res);


                        res.data.forEach(data => {
                            this.items.push(data);
                        });
                        // this.items.push(...this.items, ...res.data);

                        this.isEmpty = meta.total==0 && this.filters.page;
                        this.isMore = meta.total>0&&links.next;
                    })
                    .catch(error => {
                        this.isLoad = false;
                        this.isError = true;
                    });

                }, 1);


                // for (let index = 0; index < 10; index++) {
                //     this.items.push({
                //         id: index
                //     })

                // }
            },

            changeYear(){
                this.filters.page = 1;
                this.getItems()
            },

            loadMore(){
                this.filters.page++
                this.getItems()
            }
        }
    }
</script>

<style lang="scss" scoped>
.table-course{
    width: 100%;

    th, td{
        padding: 6px 12px;
        border-bottom: 1px solid #ededed;
    }

    .td-index{
        width: 10px;
        white-space: nowrap;
        text-align: center;
    }

    .td-action{
        text-align: right;
        width: 40px;
        white-space: nowrap;


    }
}
</style>
