require("./bootstrap");

import Vue from "vue";
import i18n from "./i18n";
import VueSweetalert2 from "vue-sweetalert2";
import VueRouter from "vue-router";
import TextareaAutosize from "vue-textarea-autosize";
import vueVimeoPlayer from "vue-vimeo-player";

Vue.use(VueRouter);
Vue.use(TextareaAutosize);
Vue.use(vueVimeoPlayer);
Vue.use(VueSweetalert2);
Vue.use(require("vue-cookies"));

// pages
import LoginPage from "./pages/Login";
Vue.component("LoginPage", LoginPage);

import RegisterPage from "./pages/Register";
Vue.component("RegisterPage", RegisterPage);

import ResetPasswordPage from "./pages/ResetPassword";
Vue.component("ResetPasswordPage", ResetPasswordPage);

import VerifyPage from "./pages/Verify";
Vue.component("VerifyPage", VerifyPage);

import SearchPage from "./pages/Search";
Vue.component("SearchPage", SearchPage);

// Personal Info Page
import PersonalInfoPage from "./pages/PersonalInfo";
Vue.component("PersonalInfoPage", PersonalInfoPage);

// ComingSoon Page
import ComingSoonPage from "./pages/ComingSoon";
Vue.component("ComingSoonPage", ComingSoonPage);

// AccountEdit Page
import AccountEditPage from "./pages/AccountEdit";
Vue.component("AccountEditPage", AccountEditPage);

// EmailResetPassword
import EmailResetPasswordPage from "./pages/ResetPasswordWithEmail";
Vue.component("EmailResetPasswordPage", EmailResetPasswordPage);

/**
 * component
 */
import EventHeader from "./components/EventHeader";
Vue.component("EventHeader", EventHeader);

// PdpaModal
import PdpaModal from "./components/PdpaModal";
Vue.component("PdpaModal", PdpaModal);

import PaymentMethod from "./components/payment/PaymentMethod";
Vue.component("PaymentMethod", PaymentMethod);

import OrderSummary from "./components/OrderSummary/index";
Vue.component("OrderSummary", OrderSummary);

import PersonalForm from "./components/OrderSummary/PersonalForm";
Vue.component("PersonalForm", PersonalForm);

import BillingAddressForm from "./components/OrderSummary/BillingAddressForm";
Vue.component("BillingAddressForm", BillingAddressForm);

import ReviewComment from "./components/ReviewComment";
Vue.component("ReviewComment", ReviewComment);

import WorkshopVimeo from "./components/WorkshopVimeo";
Vue.component("WorkshopVimeo", WorkshopVimeo);

import AccountCourse from "./components/Account/AccountCourse.vue";
Vue.component("account-course", AccountCourse);

import SettingName from "./components/SettingName/SettingName";
Vue.component("setting-name", SettingName);

import PostHashtag from "./components/PostHashtag";
Vue.component("posthash-tag", PostHashtag);

import CookieConsent from "./components/CookieConsent";
Vue.component("cookie-consent", CookieConsent);

import PackageSummary from "./components/Package/index";
Vue.component("PackageSummary", PackageSummary);

import ByteArk from "./components/ByteArk/index";
Vue.component("ByteArk", ByteArk);

import WorkshopByteArk from "./components/WorkshopByteArk";
Vue.component("BytearkPlayer", WorkshopByteArk);

/**
 * ------------------------
 */
import "boxicons";
Vue.config.productionTip = false;

new Vue({
    el: "#app",
    // router,
    // store,
    i18n,
    components: {}
});
