<template>
    <div class="info-section">
        <div class="card card-info mb-4">
            <div class="card-header">
                <h3 @click="autoComplete">Current Address / ที่อยู่ปัจจุบัน</h3>
            </div>
            <div class="card-body">

                <!-- <div class="mb-3">
                    <label class="font-weight-bold d-flex" for="">
                        <span class="mr-1">Name / ชื่อ</span>
                        <span class="text-danger">*</span>
                    </label>

                    <input type="text" class="form-control" v-model="form.shipping_name"
                        :class="[errors.shipping_name && `is-invalid`]" />

                    <div class="invalid-feedback" v-if="errors.shipping_name">{{
                            errors.shipping_name
                    }}</div>
                </div> -->

                <div class="mb-3">
                    <label class="font-weight-bold d-flex" for="">
                        <span class="mr-1">Address / ที่อยู่</span>
                        <span class="text-danger">*</span>
                    </label>

                    <input type="text" class="form-control" v-model="form.address_no"
                        :class="[errors.address_no && `is-invalid`]" />

                    <div class="invalid-feedback" v-if="errors.address_no">{{
                            errors.address_no
                    }}</div>
                </div>

                <div class="row">

                    <div class="col-md-4 mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Province / จังหวัด</span>
                            <span class="text-danger">*</span>
                        </label>

                        <select class="custom-select" v-model="form.address_province_id"
                            :class="[errors.address_province_id && `is-invalid`]">
                            <option value="" disabled>Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in provinces" :key="index">{{ `${item.name_en} / ${item.name_th}` }}</option>
                        </select>

                        <div class="invalid-feedback" v-if="errors.address_province_id">{{
                                errors.address_province_id
                        }}</div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">District / อำเภอหรือเขต</span>
                            <span class="text-danger">*</span>
                        </label>

                        <select class="custom-select" :disabled="amphureList.length == 0"
                            v-model="form.address_amphure_id" :class="[errors.address_amphure_id && `is-invalid`]">
                            <option value="" disabled>Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in amphureList" :key="index">{{
                                    `${item.name_en} / ${item.name_th}`
                            }}</option>
                        </select>

                        <div class="invalid-feedback" v-if="errors.address_amphure_id">{{
                                errors.address_amphure_id
                        }}</div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Sub District / ตำบลหรือแขวง</span>
                            <span class="text-danger">*</span>
                        </label>

                        <select class="custom-select" :disabled="districtList.length == 0"
                            v-model="form.address_district_id" :class="[errors.address_district_id && `is-invalid`]">
                            <option value="" disabled>Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in districtList" :key="index">{{
                                    `${item.name_en} / ${item.name_th}`
                            }}</option>
                        </select>

                        <div class="invalid-feedback" v-if="errors.address_district_id">{{
                                errors.address_district_id
                        }}</div>
                    </div>

                    <div class="col-md-4">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Postal Code / รหัสไปรษณีย์</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" class="form-control" v-model="form.address_zip"
                            :class="[errors.address_zip && `is-invalid`]" />

                        <div class="invalid-feedback" v-if="errors.address_zip">{{
                                errors.address_zip
                        }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['form', 'config', 'provinces', 'event', 'errors'],
    async mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        if (this.form.address_province_id) {
            await this.getAmphureByProvinceId()

            if (this.form.address_amphure_id) {
                await this.getDistrictByAmphureId()
            }
        }

        if (!this.form.billingAddressSameCurrent && this.form.billingAddress.province_id) {
            await this.getBillingAmphure()

            if (this.form.billingAddress.amphures_id) {
                await this.getBillingDistrictByAmphureId()
            }
        }
    },

    watch: {
        'form.address_province_id'(val) {
            this.form.address_zip = ''
            if (val) this.getAmphureByProvinceId()

        },
        'form.address_amphure_id'(val) {
            this.form.address_zip = ''

            if (val) this.getDistrictByAmphureId()
        },
        'form.address_district_id'(id) {
            this.form.address_zip = ''

            if (id) {
                const district = this.districtList.find(n => n.id == id)
                this.form.address_zip = district ? district.zip_code : ''
            }
        },
    },

    data() {
        return {
            amphureCaching: [],
            districtCaching: [],

            amphureList: [],
            districtList: [],
        }
    },
    methods: {
        // Address
        async getAmphureByProvinceId() {
            this.amphureList = []
            this.districtList = []

            const provinceId = this.form.address_province_id
            if (typeof this.amphureCaching[provinceId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/amphures', { params: { provinceId: provinceId } })

                    if (response.success) {
                        this.amphureCaching[provinceId] = response.data
                    }
                    else {
                        this.form.address_province_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.address_province_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.amphureList = this.amphureCaching[provinceId] || []
        },
        async getDistrictByAmphureId() {
            this.districtList = []

            const amphureId = this.form.address_amphure_id
            if (typeof this.districtCaching[amphureId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/districts', { params: { amphureId: amphureId } })

                    if (response.success) {
                        this.districtCaching[amphureId] = response.data
                    }
                    else {
                        this.form.address_amphure_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.address_amphure_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.districtList = this.districtCaching[amphureId] || []
        },

        async autoComplete(e) {
            if (!e.ctrlKey || !e.shiftKey) return false;

            // this.form.shipping_name = `ภุชงค์ สวนแจ้ง`
            this.form.address_no = `252 ถ.ลาดพร้าววังหิน`

            this.form.address_province_id = `1`
            this.form.address_amphure_id = `38`
            this.form.address_district_id = `103801`


            await this.timeout(10)
            this.form.address_zip = `10230`

            this.form.billingAddressSameCurrent = true
        },
    }
}
</script>