<template>
    <div class="info-section">

        <div class="card card-info mb-4">
            <div class="card-header">
                <h3>Billing Address / ที่อยู่ในการออกใบเสร็จรับเงิน</h3>
            </div>
            <div class="card-body">

                <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" id="billing-address-same-current-address" class="custom-control-input"
                        v-model="form.billingAddressSameCurrent">
                    <label for="billing-address-same-current-address" class="custom-control-label">My billing address is
                        the same current address / ที่อยู่ในการออกใบเสร็จรับเงิน
                        ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน</label>
                </div>

                <div v-if="!form.billingAddressSameCurrent">
                    <div class="mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Recipient Name / ชื่อผู้รับ</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" class="form-control" v-model="form.billingAddress.full_name"
                            :class="[errors.billing_full_name && `is-invalid`]" />
                        <div class="invalid-feedback" v-if="errors.billing_full_name">{{
                                errors.billing_full_name
                        }}</div>
                    </div>

                    <div class="mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Address / ที่อยู่</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" class="form-control" v-model="form.billingAddress.address"
                            :class="[errors.billing_address && `is-invalid`]" />
                        <div class="invalid-feedback" v-if="errors.billing_address">{{
                                errors.billing_address
                        }}</div>
                    </div>

                    <div class="row">

                        <div class="col-md-4 mb-3">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Province / จังหวัด</span>
                                <span class="text-danger">*</span>
                            </label>

                            <select class="custom-select" v-model="form.billingAddress.province_id"
                                :class="[errors.billing_province_id && `is-invalid`]">
                                <option value="" disabled>Please select / โปรดเลือก</option>
                                <option :value="item.id" v-for="(item, index) in provinces" :key="index">{{ `${item.name_en} / ${item.name_th}` }}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.billing_province_id">{{
                                    errors.billing_province_id
                            }}</div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">District / อำเภอหรือเขต</span>
                                <span class="text-danger">*</span>
                            </label>

                            <select class="custom-select" v-model="form.billingAddress.amphures_id"
                                :disabled="billingAmphureList.length == 0"
                                :class="[errors.billing_amphures_id && `is-invalid`]">
                                <option value="" disabled>Please select / โปรดเลือก</option>
                                <option :value="item.id" v-for="(item, index) in billingAmphureList" :key="index">{{
                                        `${item.name_en} / ${item.name_th}`
                                }}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.billing_amphures_id">{{
                                    errors.billing_amphures_id
                            }}</div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Sub District / ตำบลหรือแขวง</span>
                                <span class="text-danger">*</span>
                            </label>

                            <select class="custom-select" :disabled="billingDistrictList.length == 0"
                                v-model="form.billingAddress.district_id"
                                :class="[errors.billing_district_id && `is-invalid`]">
                                <option value="" disabled>Please select / โปรดเลือก</option>
                                <option :value="item.id" v-for="(item, index) in billingDistrictList" :key="index">{{
                                        `${item.name_en} / ${item.name_th}`
                                }}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.billing_district_id">{{
                                    errors.billing_district_id
                            }}</div>
                        </div>

                        <div class="col-md-4">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Postal Code / รหัสไปรษณีย์</span>
                                <span class="text-danger">*</span>
                            </label>

                            <input type="text" class="form-control" v-model="form.billingAddress.zipcode"
                                :class="[errors.billing_zipcode && `is-invalid`]" />
                            <div class="invalid-feedback" v-if="errors.billing_zipcode">{{ errors.billing_zipcode }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['form', 'config', 'provinces', 'event', 'errors'],
    async mounted() {

        if (!this.form.billingAddressSameCurrent && this.form.billingAddress.province_id) {
            await this.getBillingAmphure()

            if (this.form.billingAddress.amphures_id) {
                await this.getBillingDistrictByAmphureId()
            }
        }
    },

    watch: {
        'form.billingAddress.province_id'(val) {
            this.form.billingAddress.zipcode = ''
            if (val) this.getBillingAmphure()

        },
        'form.billingAddress.amphures_id'(val) {
            this.form.billingAddress.zipcode = ''
            if (val) this.getBillingDistrictByAmphureId()
        },
        'form.billingAddress.district_id'(id) {
            this.form.billingAddress.zipcode = ''

            if (id) {
                const district = this.billingDistrictList.find(n => n.id == id)
                this.form.billingAddress.zipcode = district ? district.zip_code : ''
            }
        }
    },

    data() {
        return {
            amphureCaching: [],
            districtCaching: [],

            billingAmphureList: [],
            billingDistrictList: [],
        }
    },
    methods: {
        // Billing
        async getBillingAmphure() {
            this.billingAmphureList = []
            this.billingDistrictList = []

            const provinceId = this.form.billingAddress.province_id
            if (typeof this.amphureCaching[provinceId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/amphures', { params: { provinceId: provinceId } })

                    if (response.success) {
                        this.amphureCaching[provinceId] = response.data
                    }
                    else {
                        this.form.billingAddress.province_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.billingAddress.province_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.billingAmphureList = this.amphureCaching[provinceId] || []
        },
        async getBillingDistrictByAmphureId() {
            this.billingDistrictList = []

            const amphureId = this.form.billingAddress.amphures_id
            if (typeof this.districtCaching[amphureId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/districts', { params: { amphureId: amphureId } })

                    if (response.success) {
                        this.districtCaching[amphureId] = response.data
                    }
                    else {
                        this.form.billingAddress.amphures_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.billingAddress.amphures_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.billingDistrictList = this.districtCaching[amphureId] || []
        },
    }
}
</script>