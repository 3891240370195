<template>
    <div class="container">
        <div v-if="packageDetail.package_option.onsite">
            <div v-for="location in packageDetail.package_option.onsite.locations" :key="location.id" class="card mb-4">
                <div class="card-header">
                    <h5>{{ location.location }}</h5>
                </div>
                <div class="card-body pd-0" style="padding: 0px;">
                    <div v-for="date in filteredDates(location.dates)" :key="date.id" class="mb-3">
                        <h5>{{ date.date_title }}</h5>
                        <div v-for="session in date.sessions" :key="session.id" class="p-2 mb-2" v-if="session.learnings && session.learnings.length > 0">
                            <h5><strong>{{ session.session_name }}</strong> ({{ session.start_time }} - {{ session.end_time }})</h5>
                            <div>กรุณาเลือกหัวข้ออบรม</div>
                            <div class="learnings-container">
                                <div v-for="learning in session.learnings" 
                                     :key="learning.learning_id" 
                                     class="learning-box" 
                                     :class="{ selected: isSelected(session.id, learning.learning_id), disabled: learning.seats <= 0 }"
                                     @click="learning.seats > 0 ? toggleSelect(session.id, learning.learning_id) : null" >
                                    <p style="font-size: 18px" ><strong>{{ learning.learning_title }}</strong></p>
                                    <div>
                                        <p style="font-size: 18px"><b>{{ learning.room }}</b></p>
                                        <p style="font-size: 18px"><b>( เหลือที่นั่ง  {{ learning.seats }} )</b></p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3 mb-3 d-flex justify-content-center">
            <button type="button" class="btn btn-primary" style="width: 200px; padding: 10px; font-weight: bold; font-size: 18px;" @click="submitData">ชำระเงิน</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ["event", "apiUrl" ,"saveUrl","redirectUrl"],
    data() {
        return {
            packageDetail: {},
            selectedLearning: {} // เก็บ session.id และ learning.id ของ learning ที่ถูกเลือก
        };
    },
    async mounted() {
        await this.getPackageDetail().then(() => {
            this.autoSelectSingleLearning(); 
        });
    },
    methods: {
        autoSelectSingleLearning() {
            this.packageDetail.package_option.onsite.locations.forEach(location => {
                location.dates.forEach(date => {
                    date.sessions.forEach(session => {
                        if (session.learnings.length === 1) {
                            const learningId = session.learnings[0].learning_id;
                            if( session.learnings[0].seats > 0 ){
                               this.toggleSelect(session.id, learningId);
                            }
                        }
                    });
                });
            });
        },
        async getPackageDetail() {
            try {
                const response = await fetch(this.apiUrl);
                const data = await response.json();
                this.packageDetail = data.data;
            } catch (error) {
                console.error('Error fetching package details:', error);
            }
        },
        filteredDates(dates) {
            return dates.filter(date => date.sessions.some(session => session.learnings && session.learnings.length > 0));
        },
        toggleSelect(sessionId, learningId) {
            const key = `${sessionId}-${learningId}`;
            if (this.selectedLearning[sessionId] === learningId) {
                this.$delete(this.selectedLearning, sessionId); // ยกเลิกการเลือก
            } else {
                this.$set(this.selectedLearning, sessionId, learningId); // เลือกรายการใหม่
            }
        },
        isSelected(sessionId, learningId) {
            return this.selectedLearning[sessionId] === learningId;
        },

        async submitData() {
            const _vm = this;
            const locations = this.packageDetail.package_option.onsite.locations;
            let allSessionsSelected = true; // ตัวแปรสำหรับตรวจสอบว่าเลือกครบทุก session หรือไม่
            let missingSessions = []; // เก็บ session ที่ยังไม่ได้เลือก
            let isError = false;
            for (let location of locations) {
                for (let date of location.dates) {
                    for (let session of date.sessions) {
                        let sessionSelected = 0;
                        for (let learning of session.learnings ) {
                            if (this.isSelected(session.id ,learning.learning_id )) { // ตรวจสอบว่ามี learning ที่ถูกเลือกใน session นี้หรือไม่
                                allSessionsSelected = false;
                                sessionSelected++;
                            }
                        }

                        if(sessionSelected <= 0 && session.learnings.length > 0){
                            isError = true;
                        }
                        
                    }
                }
            }

            if (!isError) {
                this.$swal({
                    title: "ยืนยันการบันทึกข้อมูล",
                    text: "คุณต้องการบันทึกข้อมูลใช่ หรือไม่",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirm"
                }).then((result) => {

                    if (result.isConfirmed) {
                        // เมื่อผู้ใช้กดปุ่ม "Confirm"
                        _vm.saveData();
                    }  
                     
                });
            } else {
                this.$swal({
                        icon: 'error',
                        title: 'ตรวจสอบ',
                        text: 'กรุณาเลือกหัวข้อให้ครบทุกช่วง',
                    }).then(() => {
                });
            }
        },

        async saveData(){
            const _vm = this;
            let payload = {
                "package" : this.selectedLearning
            };
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            try {
               const response = await fetch(this.saveUrl, {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json' ,
                    'X-CSRF-TOKEN' : csrfToken 
                },
                  body: JSON.stringify( payload )
                });

                if (!response.ok) {
                   //throw new Error(`HTTP error! status: ${response.status}`);

                }
                window.location = _vm.redirectUrl;
           } catch (error) {
                //console.error("Error submitting form:", error); 
           }
        }
    }
};
</script>

<style scoped>
.card-header {
    background-color: #f8f9fa;
}
.card-body {
    background-color: #ffffff;
}
.border {
    border: 1px solid #dee2e6;
}
.learnings-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
.learning-box {
    width: 300px;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: border 0.3s;
}
.learning-box.selected {
    /* border: 3px solid green; */
    background-color: #2b4bb3;
    color:#fff
}
.learning-box p {
    margin: 0;
}
.learning-box.disabled{
    cursor: no-drop;
}
@media (max-width: 768px) {
    .learning-box {
        width: 100%;
        height: 200px;
    }
}
</style>
