<template>
    <div class="personal-information-page">
        <h1>Personal Information</h1>

        <div class="d-none d-md-block">
            <Step @next="stepId = $event" :steps="steps" :stepId="stepId" :currentStapId="currentStapId" />
        </div>

        <div class="info-sections">
            <Info :config="config" :form="form" :member="member" @done="doneStep" @next="stepId = $event"
                v-if="stepId == 1" :prefixs="prefixs" />

            <Occupation :workplaces="workplaces" :occupations="occupations" :affiliations="affiliations" :levels="levels" :subjects="subjects"
                :form="form" @done="doneStep" @next="stepId = $event" v-else-if="stepId == 2" />

            <PersonalAddress :event="event" :config="config" :form="form" :provinces="provinces" @done="doneStep"
                @next="stepId = $event" v-else-if="stepId == 3" />

            <!-- <Confirmation :event="event" :config="config" :form="form" @next="stepId = $event" v-else-if="stepId == 4" /> -->
        </div>

    </div>
</template>

<script>
const PREFIX_OTHER = 'other'
import Step from '../components/PersonalInfo/Step.vue';
import Info from '../components/PersonalInfo/Info.vue';
import Occupation from '../components/PersonalInfo/Occupation.vue';
import PersonalAddress from '../components/PersonalInfo/Address.vue';
import Confirmation from '../components/PersonalInfo/Confirmation.vue';

export default {
    props: ["event", 'member', 'occupations', 'levels', 'subjects', 'workplaces', 'affiliations', 'provinces', 'billing', 'code'],
    components: { Step, Info, Occupation, PersonalAddress, Confirmation },

    data() {
        const steps = [];
        steps.push({ id: 1, name: 'ข้อมูลส่วนตัว', isActive: true })
        steps.push({ id: 2, name: 'อาชีพ/ตำแหน่ง' })
        steps.push({ id: 3, name: 'ที่อยู่' })
        // steps.push({ id: 4, name: 'ยืนยัน' })

        const prefixs = [];
        prefixs.push({ id: 'นาย', name: "Mr. (นาย)" });
        prefixs.push({ id: 'นาง', name: "Mrs. (นาง)" });
        prefixs.push({ id: 'นางสาว', name: "Ms. (นางสาว)" });
        prefixs.push({ id: PREFIX_OTHER, name: "Other (อื่น ๆ)" });

        return {
            steps,
            stepId: 1,
            currentStapId: 1,

            prefixs,

            config: {
                agree: false,

                // info
                day: '',
                month: '',
                year: '',
                prefixId: '',

                billingAddressSameCurrentAddress: true,
            },

            form: {

                // info
                prefix: '',
                first_name: '',
                last_name: '',

                prefix_en: '',
                first_name_en: '',
                last_name_en: '',

                gender: '',

                phone_number: '',
                birthday: '',

                // occupation
                occupation_id: '',
                occupation_custom: '',

                affiliation_id: '',
                affiliation_custom: '',

                levels: [], // ระดับชั้น
                other_teaching_group: '',


                subjects: [], // วิชาสอน
                other_teaching_level: '',

                university_faculty: '',
                university_name: '',
                university_year: '',

                // address
                shipping_name: '',
                address_no: '',
                address_province_id: '',
                address_amphure_id: '',
                address_district_id: '',
                address_zip: '',

                // billing 
                billingAddress: {
                    full_name: '',
                    address: '',
                    province_id: '',
                    amphures_id: '',
                    district_id: '',
                    zipcode: '',
                    tel: '',
                },
                billingAddressSameCurrent: true,

                // Confirmation
                subscription: 1,
                notifications: [1, 2],

                work_id: '',
                work_name: '',

                acceptPDPA: null,
            },
        };
    },
    created() {
        this.form = { ...this.form, ...this.member }

        if (this.member.birthday) {
            const birthday = this.member.birthday.split('-')

            this.config.year = birthday[0]
            this.config.month = birthday[1]
            this.config.day = birthday[2]
        }

        if (this.member.prefix) {
            const prefix = this.prefixs.find(n => n.id == this.member.prefix)
            this.config.prefixId = prefix ? prefix.id : PREFIX_OTHER
        }

        if (this.member.levels) {
            this.form.subjects = this.member.levels.map(n => n.id)
        }

        if (this.member.teachings) {
            this.form.levels = this.member.teachings.map(n => n.id)
        }

        if (this.billing) {
            this.form.billingAddressSameCurrent = false
            this.form.billingAddress = { ...this.form.billingAddress, ...this.billing }
        }
    },

    methods: {
        doneStep(stepIndex) {
            const step = this.steps.find(n => n.id == stepIndex)
            step.isDone = true

            // next step
            this.nextStep()
        },

        nextStep() {
            const lastStapId = this.steps.length
            const nextId = this.stepId + 1

            if (nextId <= lastStapId) {
                this.currentStapId = nextId
                this.stepId = nextId
            }
        }
    }

}
</script>