<template>
    <div class="d-lg-flex signin-layout">
        <div class="h-100 d-lg-flex flex-column justify-content-center h-100">
            <div class="form-signin">
                <div class="btn-back-wrap" v-if="verified">
                    <button type="button" @click="backToVerifyOTPCode" class="btn btn-back btn-action">
                        <box-icon name='left-arrow-alt'></box-icon>
                    </button>
                    <div class="btn-back-placeholder">กลับ</div>
                </div>
                <div class="btn-back-wrap" v-else>
                    <a :href="urlEvent(`/reset-password`)" class="btn btn-back btn-action">
                        <box-icon name='left-arrow-alt'></box-icon>
                    </a>
                    <div class="btn-back-placeholder">กลับ</div>
                </div>
                <div class="text-center">
                    <h1 class="mb-3 fw-700">
                        <span v-if="verified">{{ $t('Confirm password') }}</span>
                        <span v-else>{{ `Security verification` }}</span>
                    </h1>
                </div>
                <div class="signin-section d-flex flex-row justify-content-center">
                    <form @submit.prevent="resetPassword" class="signin-with-email" v-if="verified">

                        <div class="mb-3">
                            <label for="password">{{ $t("Password") }}</label>
                            <input id="password" :type="showPassword ? `text`: `password`" required="required"
                                class="form-control form-control-lg" v-model="formPassword.password"
                                :class="[isLoad && `disabled`, errors.password && `is-invalid`]" :disabled="isLoad"
                                autocomplete="new-password" @focus="passwordFocus = true" @keyup="bindInputEvent" />

                            <div class="invalid-feedback" v-if="errors.password">{{ errors.password }}</div>
                          
                        </div>

                        <ul class="password-strength" v-if="1==2">
                            <li v-for="info in passwordInfos" :key="info.key" :class="[
                              info.status == false && 'failed',
                              info.status == true && 'passed',
                            ]">
                                <svg v-if="info.status == true" width="16" height="16" viewBox="0 0 48 48"><path d="M41.3 9.834L38.33 7.52a1 1 0 0 0-1.4.175l-17.697 22.73-8.613-8.614a1 1 0 0 0-1.414 0l-2.695 2.7a1 1 0 0 0 0 1.414l12.432 12.442a1 1 0 0 0 1.5-.093l21.034-27.037a1 1 0 0 0-.177-1.403z"></path></svg>

                                <svg v-else-if="info.status == false" width="16" height="16" viewBox="0 0 48 48"><path d="M35.314 8.444L24 19.757 12.686 8.444a1 1 0 0 0-1.414 0l-2.828 2.828a1 1 0 0 0 0 1.414L19.757 24 8.444 35.314a1 1 0 0 0 0 1.414l2.828 2.828a1 1 0 0 0 1.414 0L24 28.243l11.314 11.313a1 1 0 0 0 1.414 0l2.828-2.828a1 1 0 0 0 0-1.414L28.243 24l11.313-11.314a1 1 0 0 0 0-1.414l-2.828-2.828a1 1 0 0 0-1.414 0z"></path></svg>
                                <svg v-else viewBox="0 0 48 48" width="6" height="6"><circle cx="24" cy="24" r="19.9"></circle></svg>
                                <span>{{ info.label }}</span>
                            </li>
                        </ul>

                        <div class="mb-3">
                            <label for="new-password">{{ $t("New Password") }}</label>
                            <input id="new-password" type="password" required="required"
                                class="form-control form-control-lg" v-model="formPassword.newPassword"
                                :class="[isLoad && `disabled`, errors.newPassword && `is-invalid`]" :disabled="isLoad"
                                autocomplete="new-password" />
                            <div class="invalid-feedback" v-if="errors.newPassword">{{ errors.newPassword }}</div>
                        </div>

                        <button type="submit" class="btn btn-lg btn-primary btn-block mt-4"
                            :class="[isLoad && `disabled`, isError && `btn-error`]" :disabled="isLoad">
                            <span class="text-white fw-600">{{
                            isLoad ? `${$t("Loading")}...` : $t(`Save`)
                            }}</span>
                        </button>
                    </form>

                    <form @submit.prevent="verifyCode" class="signin-with-email" v-else>

                        <div class="mb-3">
                            <label for="verificationCodeInput">
                                <span v-if="isLoadResetOTP">กำลังส่งรหัสไปที่ </span>
                                <span v-else>{{ $t('labelVerifyEmail') }}</span>
                                <span>{{ email }}</span>
                            </label>

                            <div class="input-wrap">
                                <input id="verificationCodeInput" type="tel" required
                                    class="form-control form-control-lg" v-model="verificationCode" autocomplete="off"
                                    :class="[isLoad && `disabled`, isLoadResetOTP && `disabled`, loadSentCode && `disabled`]"
                                    :disabled="isLoad || loadSentCode || isLoadResetOTP" maxlength="8" />

                                <button type="button" class="input-wrap-suffix" @click="resetOTP">
                                    <span v-if="isResetOTP">ขอรหัสใหม่</span>
                                    <span v-else-if="isLoadResetOTP"></span>
                                    <span v-else>ขอรหัสใหม่ได้อีก {{ progressTimer }} วิ</span>
                                </button>
                            </div>

                        </div>

                        <button type="submit" class="btn btn-lg btn-primary btn-block mt-4"
                            :class="[isLoad && `disabled`, isLoadResetOTP && `disabled`, isError && `btn-error`]"
                            :disabled="isLoad || isLoadResetOTP">
                            <span class="text-white fw-600">{{
                            isLoad ? `${$t("Loading")}...` : $t(`Next`)
                            }}</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
const REGEX_UPPER = new RegExp("[A-Z]");
const REGEX_LOWER = new RegExp("[a-z]");
const REGEX_NUMBER = new RegExp("[0-9]");
const MIN_PASSWORD = 6;
const MAX_PASSWORD = 20;

export default {
    props: ['event', 'email', 'code'],

    computed: {
        submitReady() {

            if (this.isLoad) {
                return false
            }
            else if (this.verificationCode == '') {
                return false
            }

            return true
        },
        hidePhoneNumber() {
            let phoneNumber = this.email
            return phoneNumber.substr(0, 3) + '***' + phoneNumber.substring(phoneNumber.length - 4);
        }
    },

    data() {
        const passwordInfos = [];
        passwordInfos.push({
            key: "length",
            label: this.$t('RegisterRasswordRemark1'),
            status: null,
        });
        passwordInfos.push({
            key: "capital",
            label: this.$t('RegisterRasswordRemark2'),
            status: null,
        });
        passwordInfos.push({
            key: "number",
            label: this.$t('RegisterRasswordRemark3'),
            status: null,
        });
    
        return {
            isLoad: false,
            isError: false,
            errors: {},

            countryCodeInput: '+66',
            phoneNumber: '',
            showPassword: false,

            withEmail: true,
            callingcodes: [],

            verified: false,
            isResetOTP: true,

            progressTimer: '',
            loadSentCode: false,
            verificationCode: '',

            verifyPhoneNumber: '',
            isLoadResetOTP: false,
            passwordFocus: false,

            form: {
                email: '',
                token: ''
            },

            formPassword: {
                password: '',
                newPassword: '',
            },

            timer: null,
            progressTimer: 60,
            timeleft: 60,

            passwordInfos,
        }
    },

    async mounted() {
        // await this.getCountryCode()

        if (this.code) {
            this.form.token = this.code
        }

        if (this.email) {
            this.form.email = this.email
        }
    },


    methods: {
        urlEvent(path) {
            return `/${this.event.slug}${path}`
        },
        async getCountryCode() {
            const { data: response } = await axios.get('/api/country/code')

            if (response.success) {
                this.callingcodes = response.data
            }
        },
        async resetPassword() {
            this.isLoad = true;
            this.isError = false

            const validated = this.validator()
            await this.timeout(1000);

            if (validated) {
                try {
                    const payload = { ...this.formPassword, token: this.form.token, email: this.form.email }
                    const { data: response } = await axios.post('/api/reset-password/new-password', payload)

                    if (response.success) {
                        this.$swal({
                            icon: 'success',
                            title: this.$t('Success'),
                            text: response.message || `ตั้งรหัสผ่านใหม่สำเร็จ`,
                        }).then(() => {
                            window.location.href = response.redirectUrl
                        });
                    }
                    else {
                        this.alertError()
                    }

                } catch (error) {
                    this.alertError()
                }
            }
            else {
                this.isError = true
            }

            this.isLoad = false
        },

        validator() {
            this.errors = {}
            const form = this.formPassword
            const regex = new RegExp("^[a-zA-Z0-9 ]+$");

            const password = form.password

            const capitalletters = password.match(REGEX_UPPER) ? 1 : 0;
            const loweletters = password.match(REGEX_LOWER) ? 1 : 0;

            if (!form.password) {
                this.errors.password = "กรุณาระบุรหัสผ่าน"
            } else if (form.password.length < 6) {
                this.errors.password = "รหัสผ่านต้อง 6 ตัวอักษรขึ้นไป"
            } else if (form.password != form.newPassword) {
                this.errors.newPassword = "รหัสผ่านไม่ตรงกัน"
            } else if( !regex.test(password) ) {
                this.errors.password = "รหัสผ่านต้องเป็นภาษาอังกฤษหรือตัวเลขเท่านั้น"
            } else if( !capitalletters && !loweletters ){
                this.errors.password = "รหัสผ่านต้องมีตัวอักษรอย่างน้อย 1 ตัว"
            } else if( !password.match(REGEX_NUMBER) ){
                this.errors.password = "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว"
            }


            return Object.keys(this.errors).length == 0;
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async resetOTP() {
            if (!this.isResetOTP) return
            this.isResetOTP = false
            clearInterval(this.timer)
            this.isLoadResetOTP = true;

            const response = await this.resetOTPToEmail()
            this.isLoadResetOTP = false
            // console.log('response', response);

            if (response.token) {
                this.form.token = response.token
                this.verificationCode = ''
            }

            let timeleft = this.timeleft
            this.progressTimer = this.timeleft

            this.timer = setInterval(() => {
                if (timeleft <= 1) {
                    clearInterval(this.timer);
                    this.isResetOTP = true
                }

                timeleft -= 1;
                this.progressTimer = timeleft;
            }, 1000);
        },

        async resetOTPToEmail() {

            try {
                const payload = {
                    eventId: this.event.id,
                    email: this.email,
                }

                const { data: response } = await axios.post('/api/reset-password/email/reset/otp', payload)

                return response;
            } catch (error) {
                clearInterval(this.timer)
                this.isResetOTP = true

                this.$swal({
                    icon: 'error',
                    title: this.$t('Oops'),
                    text: this.$t('OopsMessage'),
                });

                return false;
            }
        },


        async verifyCode() {
            this.isLoad = true
            this.isError = false
            await this.timeout(1000);

            try {
                const payload = { ...this.form, verifyCode: this.verificationCode }
                const { data: response } = await axios.post('/api/reset-password/email/verify/otp', payload)

                if (response.success) {
                    this.verified = true
                }
                else {
                    this.alertError()
                }

            } catch (error) {
                this.isError = true
                const { data } = error.response

                if (data.message) {
                    const res = await this.alertError(data.message)

                }
                else {
                    this.alertError()
                }

                this.verificationCode = ''
            }

            this.isLoad = false
        },

        alertError(message) {
            return this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: message || this.$t('OopsMessage'),
            });
        },

        backToVerifyOTPCode() {
            this.verificationCode = ''
            this.verified = false
        },

        bindInputEvent() {
            const password = this.formPassword.password.trim();

            // const regex = new RegExp("^[a-zA-Z0-9 ]+$");

            const characters =
                password.length >= MIN_PASSWORD && password.length <= MAX_PASSWORD ? 1 : 0;
            const capitalletters = password.match(REGEX_UPPER) ? 1 : 0;
            const loweletters = password.match(REGEX_LOWER) ? 1 : 0;
            const number = password.match(REGEX_NUMBER) ? 1 : 0;

            const total = characters + capitalletters + loweletters + number;
            this.updateIndicator(total);

            this.updateInfo("length", characters);
            this.updateInfo("capital", capitalletters || loweletters);
            this.updateInfo("number", number);

            this.isIndicator = total >= 3;

            //   this.updateBtnSubmit();
        },

        updateBtnSubmit() {

        },
        updateInfo(criterion, isValid) {
            let index = this.passwordInfos.findIndex((n) => n.key == criterion);
            this.passwordInfos[index].status = isValid ? true : false;
        },
        updateIndicator(total) {
            let txt = "";

            if (total === 0) {
            } else if (total === 1) {
                txt = "very weak";
            } else if (total === 2) {
                txt = "weak";
            } else if (total === 3) {
                txt = "medium";
            } else {
                txt = "strong";
            }

            return txt;
        },
    }
}
</script>

<style lang="scss" scoped>
.form-signin {
    position: relative;
}

.btn-back-wrap {
    position: absolute;
    top: 20px;
    left: 20px;

    display: flex;
    gap: 10px;
    align-items: center;
}

.btn-back-placeholder {
    font-size: 80%;
}

.btn-back {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--signin-btn-back-bg, rgba(255, 255, 255, .3));
}

.input-wrap {
    position: relative;

    .form-control {
        padding-right: 80px;
    }

    .input-wrap-suffix {
        border: none;
        background-color: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }

}
.password-strength{
    display: flex;
    flex-direction: column;

    margin-bottom: 15px;

    li{
        display: flex;
        gap: 5px;
        align-items: center;
    }
}
</style>