<template>
    <form @submit.prevent="saveConfirmation" class="info-section">
        <div class="card card-info mb-4">
            <div class="card-header">
                <h3 @click="autoComplete">Current Address / ที่อยู่ปัจจุบัน</h3>
            </div>
            <div class="card-body">

                <!-- <div class="mb-3">
                    <label class="font-weight-bold d-flex" for="">
                        <span class="mr-1">Name / ชื่อ</span>
                        <span class="text-danger">*</span>
                    </label>

                    <input type="text" class="form-control" v-model="form.shipping_name"
                        :class="[errors.shipping_name && `is-invalid`]" />

                    <div class="invalid-feedback" v-if="errors.shipping_name">{{
                         errors.shipping_name 
                        }}</div>
                </div> -->

                <div class="mb-3">
                    <label class="font-weight-bold d-flex" for="">
                        <span class="mr-1">Address / ที่อยู่</span>
                        <span class="text-danger">*</span>
                    </label>

                    <input type="text" class="form-control" v-model="form.address_no"
                        :class="[errors.address_no && `is-invalid`]" />

                    <div class="invalid-feedback" v-if="errors.address_no">{{
                            errors.address_no
                    }}</div>
                </div>

                <div class="row">

                    <div class="col-md-4 mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Province / จังหวัด</span>
                            <span class="text-danger">*</span>
                        </label>

                        <select class="custom-select" v-model="form.address_province_id"
                            :class="[errors.address_province_id && `is-invalid`]">
                            <option value="" disabled>Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in provinces" :key="index">{{ `${item.name_en} / ${item.name_th}` }}</option>
                        </select>

                        <div class="invalid-feedback" v-if="errors.address_province_id">{{
                                errors.address_province_id
                        }}</div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">District / อำเภอหรือเขต</span>
                            <span class="text-danger">*</span>
                        </label>

                        <select class="custom-select" :disabled="amphureList.length == 0"
                            v-model="form.address_amphure_id" :class="[errors.address_amphure_id && `is-invalid`]">
                            <option value="" disabled>Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in amphureList" :key="index">{{
                                    `${item.name_en} / ${item.name_th}`
                            }}</option>
                        </select>

                        <div class="invalid-feedback" v-if="errors.address_amphure_id">{{
                                errors.address_amphure_id
                        }}</div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Sub District / ตำบลหรือแขวง</span>
                            <span class="text-danger">*</span>
                        </label>

                        <select class="custom-select" :disabled="districtList.length == 0"
                            v-model="form.address_district_id" :class="[errors.address_district_id && `is-invalid`]">
                            <option value="" disabled>Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in districtList" :key="index">{{
                                    `${item.name_en} / ${item.name_th}`
                            }}</option>
                        </select>

                        <div class="invalid-feedback" v-if="errors.address_district_id">{{
                                errors.address_district_id
                        }}</div>
                    </div>

                    <div class="col-md-4">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Postal Code / รหัสไปรษณีย์</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" class="form-control" v-model="form.address_zip"
                            :class="[errors.address_zip && `is-invalid`]" />

                        <div class="invalid-feedback" v-if="errors.address_zip">{{
                                errors.address_zip
                        }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-info mb-4">
            <div class="card-header">
                <h3>Billing Address / ที่อยู่ในการออกใบเสร็จรับเงิน</h3>
            </div>
            <div class="card-body">

                <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" id="billing-address-same-current-address" class="custom-control-input"
                        v-model="form.billingAddressSameCurrent">
                    <label for="billing-address-same-current-address" class="custom-control-label">My billing address is
                        the same current address / ที่อยู่ในการออกใบเสร็จรับเงิน
                        ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน</label>
                </div>

                <div v-if="!form.billingAddressSameCurrent">
                    <div class="mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Recipient Name / ชื่อผู้รับ</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" class="form-control" v-model="form.billingAddress.full_name"
                            :class="[errors.billing_full_name && `is-invalid`]" />
                        <div class="invalid-feedback" v-if="errors.billing_full_name">{{
                                errors.billing_full_name
                        }}</div>
                    </div>

                    <div class="mb-3">
                        <label class="font-weight-bold d-flex" for="">
                            <span class="mr-1">Address / ที่อยู่</span>
                            <span class="text-danger">*</span>
                        </label>

                        <input type="text" class="form-control" v-model="form.billingAddress.address"
                            :class="[errors.billing_address && `is-invalid`]" />
                        <div class="invalid-feedback" v-if="errors.billing_address">{{
                                errors.billing_address
                        }}</div>
                    </div>

                    <div class="row">

                        <div class="col-md-4 mb-3">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Province / จังหวัด</span>
                                <span class="text-danger">*</span>
                            </label>

                            <select class="custom-select" v-model="form.billingAddress.province_id"
                                :class="[errors.billing_province_id && `is-invalid`]">
                                <option value="" disabled>Please select / โปรดเลือก</option>
                                <option :value="item.id" v-for="(item, index) in provinces" :key="index">{{
                                        `${item.name_en}
                                                                    / ${item.name_th}`
                                
                                
                                
                                
                                
                                
                                }}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.billing_province_id">{{
                                    errors.billing_province_id
                            }}</div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">District / อำเภอหรือเขต</span>
                                <span class="text-danger">*</span>
                            </label>

                            <select class="custom-select" v-model="form.billingAddress.amphures_id"
                                :disabled="billingAmphureList.length == 0"
                                :class="[errors.billing_amphures_id && `is-invalid`]">
                                <option value="" disabled>Please select / โปรดเลือก</option>
                                <option :value="item.id" v-for="(item, index) in billingAmphureList" :key="index">{{
                                        `${item.name_en} / ${item.name_th}`
                                }}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.billing_amphures_id">{{
                                    errors.billing_amphures_id
                            }}</div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Sub District / ตำบลหรือแขวง</span>
                                <span class="text-danger">*</span>
                            </label>

                            <select class="custom-select" :disabled="billingDistrictList.length == 0"
                                v-model="form.billingAddress.district_id"
                                :class="[errors.billing_district_id && `is-invalid`]">
                                <option value="" disabled>Please select / โปรดเลือก</option>
                                <option :value="item.id" v-for="(item, index) in billingDistrictList" :key="index">{{
                                        `${item.name_en} / ${item.name_th}`
                                }}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.billing_district_id">{{
                                    errors.billing_district_id
                            }}</div>
                        </div>

                        <div class="col-md-4">
                            <label class="font-weight-bold d-flex" for="">
                                <span class="mr-1">Postal Code / รหัสไปรษณีย์</span>
                                <span class="text-danger">*</span>
                            </label>

                            <input type="text" class="form-control" v-model="form.billingAddress.zipcode"
                                :class="[errors.billing_zipcode && `is-invalid`]" />
                            <div class="invalid-feedback" v-if="errors.billing_zipcode">{{ errors.billing_zipcode }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-info mb-4">
            <div class="card-header">
                <h3>Communication Channel</h3>
            </div>
            <div class="card-body">

                <div class="mb-3">
                    <label class="font-weight-bold d-flex">
                        <span class="mr-1">Newsletter Subscription / การรับข่าวสารจาก EDUCA</span>
                        <span class="text-danger">*</span>
                    </label>

                    <div :class="[errors.subscription && `is-invalid`]">
                        <div class="custom-control custom-radio mb-1" v-for="(item, index) in subscriptionList"
                            :key="index">
                            <input type="radio" :id="`subscription-${item.id}`" :value="item.id"
                                class="custom-control-input" v-model="form.subscription">
                            <label :for="`subscription-${item.id}`" class="custom-control-label">{{ item.name }}</label>
                        </div>
                    </div>
                    <div class="invalid-feedback" v-if="errors.subscription">{{ errors.subscription }}
                            </div>
                    
                </div>
                <div v-if="form.subscription">
                    <label class="font-weight-bold d-flex mb-0">
                        <span class="mr-1">Newsletter Channel / ช่องทางการรับข่าวสาร</span>
                        <span class="text-danger">*</span>
                    </label>
                    <div class="text-muted mb-2">(you can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)</div>

                    <div class="d-flex" :class="[errors.notifications && `is-invalid`]">
                        <div class="custom-control custom-checkbox mr-3" v-for="(item, index) in channelList"
                            :key="index">
                            <input type="checkbox" :id="`channel-${item.id}`" :value="item.id"
                                class="custom-control-input" v-model="form.notifications">
                            <label :for="`channel-${item.id}`" class="custom-control-label">{{ item.name }}</label>
                        </div>
                    </div>

                    <div class="invalid-feedback" v-if="errors.notifications">{{ errors.notifications }}
                            </div>
                </div>

            </div>
        </div>


        <div class="personal-information-footer">
            <div>{{ $t('acceptBody') }} <a class="font-weight-bold" :href="eventBastUrl('/download/policy')"
                    target="_blank">{{ $t('acceptBodyLink') }} </a></div>
            <div class="custom-control custom-checkbox font-weight-bold">
                <input type="checkbox" id="privacy-policy" value="1" class="custom-control-input"
                    v-model="config.agree" />
                <label for="privacy-policy" class="custom-control-label text-danger">{{ $t('InfoAcceptText') }}</label>
            </div>

            <div class="personal-information-footer-buttons">
                <button type="button" class="btn btn-lg btn-light min-w-120 font-weight-bold" @click="$emit('next', 2)"
                    :disabled="isLoad">{{ $t('Back') }}</button>


                <button type="submit" :disabled="!config.agree || isLoad"
                    class="btn btn-lg btn-primary min-w-120 font-weight-bold"
                    :class="[!config.agree && `disabled`, isLoad && `disabled`, isError && 'btn-error']">{{ $t('Save') }}</button>
            </div>
        </div>

        <PdpaModal @done="saveInformation" :event="event" v-if="verifyPDPA" />
    </form>
</template>

<script>
const CURRENT_STEP = 3

import PdpaModal from '../PdpaModal.vue';
export default {
    props: ['form', 'config', 'provinces', 'event'],
    components: { PdpaModal },
    async mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        if (this.form.address_province_id) {
            await this.getAmphureByProvinceId()

            if (this.form.address_amphure_id) {
                await this.getDistrictByAmphureId()
            }
        }

        if (!this.form.billingAddressSameCurrent && this.form.billingAddress.province_id) {
            await this.getBillingAmphure()

            if (this.form.billingAddress.amphures_id) {
                await this.getBillingDistrictByAmphureId()
            }
        }
    },

    watch: {
        'form.address_province_id'(val) {
            this.form.address_zip = ''
            if (val) this.getAmphureByProvinceId()

        },
        'form.address_amphure_id'(val) {
            this.form.address_zip = ''

            if (val) this.getDistrictByAmphureId()
        },
        'form.address_district_id'(id) {
            this.form.address_zip = ''

            if (id) {
                const district = this.districtList.find(n => n.id == id)
                this.form.address_zip = district ? district.zip_code : ''
            }
        },

        'form.billingAddress.province_id'(val) {
            this.form.billingAddress.zipcode = ''
            if (val) this.getBillingAmphure()

        },
        'form.billingAddress.amphures_id'(val) {
            this.form.billingAddress.zipcode = ''
            if (val) this.getBillingDistrictByAmphureId()
        },
        'form.billingAddress.district_id'(id) {
            this.form.billingAddress.zipcode = ''

            if (id) {
                const district = this.billingDistrictList.find(n => n.id == id)
                this.form.billingAddress.zipcode = district ? district.zip_code : ''
            }
        }
    },

    data() {
        const subscriptionList = [];
        subscriptionList.push({ id: 1, name: "Newsletter Subscription / ต้องการรับข่าวสาร" });
        subscriptionList.push({ id: 0, name: "I prefer not to receive newsletter / ไม่ต้องการรับข่าวสาร" });

        const channelList = [];
        channelList.push({ id: 1, name: "Email" });
        channelList.push({ id: 2, name: "SMS" });

        return {
            amphureList: [],
            amphureCaching: {},

            districtList: [],
            districtCaching: [],

            billingAmphureList: [],
            billingDistrictList: [],

            errors: {},
            isError: false,
            isLoad: false,

            // -- step 4
            subscriptionList,
            channelList,
            verifyPDPA: false,
            isLoadinForm: true,
        }
    },
    methods: {
        eventBastUrl(path) {
            return `/${this.event.slug}${path}`
        },
        async saveConfirmation() {
            this.isError = false
            this.isLoad = true

            await this.timeout(3);

            if (!this.validateAddress()) {
                this.isError = true
                this.isLoad = false

                await this.timeout(3)
                this.focusInvalid()
            }
            else if (!this.verifyPDPA) {
                this.verifyPDPA = true
            }
            else {
                this.saveInformation()
            }
        },
        async saveInformation(acceptPDPA) {
            this.isLoad = true;

            this.form.acceptPDPA = acceptPDPA
            const titleLoading = this.$t('Loading')
            const swal = this.$swal({
                // text: this.$t('Loading'),
                showConfirmButton: false,
                background: 'transparent',
                backdrop: `rgba(0,0,0,0.9)`,
                title: "<h3 style='color: white;'>" + titleLoading + "...</h3>",
                html: "<div style='color: white;'>เรากำลังบันทึกข้อมูล, กรุณาอย่าปิดหน้านี้</div>",
                showLoaderOnConfirm: false,
                allowOutsideClick: false,
            });

            const payload = { ...this.form, eventId: this.event.id }
            await this.timeout(800);

            swal.close()

            try {
                const { data: response } = await axios.post('/api/personal-info', payload)
                if (response.success) {
                    swal.close()

                    this.$swal({
                        icon: 'success',
                        title: response.intro || this.$t('Success'),
                        text: response.message,
                    }).then(() => {

                        window.location.href = response.redirectUrl || this.eventBastUrl('/')
                    })
                }
            } catch (error) {
                const { data } = error.response
                this.error = data

                this.$swal({
                    icon: 'error',
                    title: data.intro || this.$t('Oops'),
                    text: data.message,
                });
            }

            this.config.agree = false
            this.isLoad = false
        },

        // async updateAddress() {
        //     this.isLoad = true
        //     this.isError = false

        //     await this.timeout(3);
        //     if (this.validateAddress()) {
        //         this.$emit('done', CURRENT_STEP)
        //     }
        //     else {
        //         this.isError = true

        //         await this.timeout(3)
        //         this.focusInvalid()
        //     }

        //     this.isLoad = false
        // },

        validateAddress() {
            const form = this.form
            this.errors = {}

            const rules = {
                // shipping_name: 'กรุณาระบุชื่อ',
                address_no: 'กรุณาระบุที่อยู่',
                address_province_id: 'กรุณาระบุจังหวัด',
                address_amphure_id: 'กรุณาระบุอำเภอหรือเขต',
                address_district_id: 'กรุณาระบุตำบลหรือแขวง',
                address_zip: 'กรุณาระบุรหัสไปรษณีย์',
            }

            for (const key in rules) {
                if (Object.hasOwnProperty.call(rules, key)) {
                    const message = rules[key];

                    if (!form[key]) {
                        this.errors[key] = message
                    }
                }
            }

            if (!this.form.billingAddressSameCurrent) {

                const billingRules = {
                    full_name: 'กรุณาระบุชื่อ',
                    address: 'กรุณาระบุที่อยู่',
                    province_id: 'กรุณาระบุจังหวัด',
                    amphures_id: 'กรุณาระบุอำเภอหรือเขต',
                    district_id: 'กรุณาระบุตำบลหรือแขวง',
                    zipcode: 'กรุณาระบุรหัสไปรษณีย์',
                }

                for (const key in billingRules) {
                    if (Object.hasOwnProperty.call(billingRules, key)) {
                        const message = billingRules[key];

                        if (!form.billingAddress[key]) {
                            this.errors[`billing_${key}`] = message
                        }
                    }
                }
            }

            if (this.form.subscription === null) {
                this.errors.subscription = 'กรุณาเลือกการรับข่าวสารจาก EDUCA'
            }
            else if(this.form.subscription == 1){
                if( this.form.notifications.length == 0 ){
                    this.errors.notifications = 'กรุณาเลือกช่องทางการรับข่าวสาร'
                }
            }

            return Object.keys(this.errors).length == 0
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        // Address
        async getAmphureByProvinceId() {
            this.amphureList = []
            this.districtList = []

            const provinceId = this.form.address_province_id
            if (typeof this.amphureCaching[provinceId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/amphures', { params: { provinceId: provinceId } })

                    if (response.success) {
                        this.amphureCaching[provinceId] = response.data
                    }
                    else {
                        this.form.address_province_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.address_province_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.amphureList = this.amphureCaching[provinceId] || []
        },
        async getDistrictByAmphureId() {
            this.districtList = []

            const amphureId = this.form.address_amphure_id
            if (typeof this.districtCaching[amphureId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/districts', { params: { amphureId: amphureId } })

                    if (response.success) {
                        this.districtCaching[amphureId] = response.data
                    }
                    else {
                        this.form.address_amphure_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.address_amphure_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.districtList = this.districtCaching[amphureId] || []
        },

        // Billing
        async getBillingAmphure() {
            this.billingAmphureList = []
            this.billingDistrictList = []

            const provinceId = this.form.billingAddress.province_id
            if (typeof this.amphureCaching[provinceId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/amphures', { params: { provinceId: provinceId } })

                    if (response.success) {
                        this.amphureCaching[provinceId] = response.data
                    }
                    else {
                        this.form.billingAddress.province_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.billingAddress.province_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.billingAmphureList = this.amphureCaching[provinceId] || []
        },
        async getBillingDistrictByAmphureId() {
            this.billingDistrictList = []

            const amphureId = this.form.billingAddress.amphures_id
            if (typeof this.districtCaching[amphureId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/districts', { params: { amphureId: amphureId } })

                    if (response.success) {
                        this.districtCaching[amphureId] = response.data
                    }
                    else {
                        this.form.billingAddress.amphures_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.billingAddress.amphures_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.billingDistrictList = this.districtCaching[amphureId] || []
        },

        focusInvalid() {
            const $invalid = $('.is-invalid')

            if ($invalid.length == 0) return
            $invalid[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        },

        async autoComplete(e) {
            if (!e.ctrlKey || !e.shiftKey) return false;

            // this.form.shipping_name = `ภุชงค์ สวนแจ้ง`
            this.form.address_no = `252 ถ.ลาดพร้าววังหิน`

            this.form.address_province_id = `1`
            this.form.address_amphure_id = `38`
            this.form.address_district_id = `103801`


            await this.timeout(10)
            this.form.address_zip = `10230`

            this.form.billingAddressSameCurrent = true
        },
    }
}
</script>