<template>
    <form @submit.prevent="saveConfirmation" class="info-section">
        <div class="card card-info mb-4">
            <div class="card-header">
                <h3>Communication Channel</h3>
            </div>
            <div class="card-body">

                <div class="mb-3">
                    <label class="font-weight-bold d-flex">
                        <span class="mr-1">Newsletter Subscription / การรับข่าวสารจาก EDUCA</span>
                        <span class="text-danger">*</span>
                    </label>

                    <div>
                        <div class="custom-control custom-radio mb-1" v-for="(item, index) in subscriptionList"
                            :key="index">
                            <input type="radio" :id="`subscription-${item.id}`" :value="item.id"
                                class="custom-control-input" v-model="form.subscription">
                            <label :for="`subscription-${item.id}`" class="custom-control-label">{{ item.name }}</label>
                        </div>
                    </div>
                </div>
                <div v-if="form.subscription">
                    <label class="font-weight-bold d-flex mb-0">
                        <span class="mr-1">Newsletter Channel / ช่องทางการรับข่าวสาร</span>
                        <span class="text-danger">*</span>
                    </label>
                    <div class="text-muted mb-2">(you can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)</div>

                    <div class="d-flex">
                        <div class="custom-control custom-checkbox mr-3" v-for="(item, index) in channelList"
                            :key="index">
                            <input type="checkbox" :id="`channel-${item.id}`" :value="item.id"
                                class="custom-control-input" v-model="form.notifications">
                            <label :for="`channel-${item.id}`" class="custom-control-label">{{ item.name }}</label>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="personal-information-footer">

            <div class="custom-control custom-checkbox font-weight-bold">
                <input type="checkbox" id="privacy-policy" value="1" class="custom-control-input"
                    v-model="config.agree" />
                <label for="privacy-policy" class="custom-control-label">I agree
                    with</label>
                <a href="https://educathai.com/privacy-policy" target="_blank" class="text-primary"><u>Term and
                        Conditions</u></a>
                / ยอมรับ<a href="https://educathai.com/privacy-policy" target="_blank"
                    class="text-primary text-underline"><u>ข้อกำหนดและเงื่อนไข</u></a>
            </div>

            <div class="personal-information-footer-buttons">
                <button type="button" :disabled="isLoad" class="btn btn-lg btn-light min-w-120 font-weight-bold"
                    @click="$emit('next', 3)">{{ $t('Back') }}</button>

                <button type="submit" :disabled="!config.agree || isLoad"
                    class="btn btn-lg btn-primary min-w-120 font-weight-bold"
                    :class="[!config.agree && `disabled`, isLoad && `disabled`]">บันทึก</button>
            </div>
        </div>

        <PdpaModal @done="saveInformation" :event="event" v-if="verifyPDPA" />
    </form>
</template>

<script>
import PdpaModal from '../PdpaModal.vue';
export default {
    props: ["form", "config", 'event'],
    components: { PdpaModal },
    mounted() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },
    data() {
        const subscriptionList = [];
        subscriptionList.push({ id: 1, name: "Newsletter Subscription / ต้องการรับข่าวสาร" });
        subscriptionList.push({ id: 0, name: "I prefer not to receive newsletter / ไม่ต้องการรับข่าวสาร" });

        const channelList = [];
        channelList.push({ id: 1, name: "Email" });
        channelList.push({ id: 2, name: "SMS" });
        return {
            subscriptionList,
            channelList,

            verifyPDPA: false,

            isLoadinForm: true,

            isLoad: false,
        };
    },
    methods: {
        eventBastUrl(path) {
            return `/${this.event.slug}${path}`
        },
        saveConfirmation() {

            if (!this.verifyPDPA) {
                this.verifyPDPA = true
            }
            else {
                this.saveInformation()
            }

        },

        async saveInformation() {
            this.isLoad = true;

            const titleLoading = this.$t('Loading')
            const swal = this.$swal({
                // text: this.$t('Loading'),
                showConfirmButton: false,
                background: 'transparent',
                backdrop: `rgba(0,0,0,0.9)`,
                title: "<h3 style='color: white;'>" + titleLoading + "...</h3>",
                html: "<div style='color: white;'>เรากำลังบันทึกข้อมูล, กรุณาอย่าปิดหน้านี้</div>",
                showLoaderOnConfirm: false,
                allowOutsideClick: false,
            });

            const payload = { ...this.form, eventId: this.event.id }
            await this.timeout(800);

            swal.close()

            try {
                const { data: response } = await axios.post('/api/personal-info', payload)
                if (response.success) {
                    swal.close()

                    this.$swal({
                        icon: 'success',
                        title: response.intro || this.$t('Success'),
                        text: response.message,
                    }).then(() => {

                        window.location.href = response.redirectUrl || this.eventBastUrl('/')
                    })
                }
            } catch (error) {
                const { data } = error.response
                this.error = data

                this.$swal({
                    icon: 'error',
                    title: data.intro || this.$t('Oops'),
                    text: data.message,
                });
            }

            this.config.agree = false
            this.isLoad = false
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    },

}
</script>