<template>
    <div class="pdpa-modal" v-if="activeModal">
        <div class="pdpa-modal-content">
            <div class="pdpa-modal-header text-center">
                <h3>{{ $t('acceptPDPATitle') }}</h3>
            </div>
            <div class="pdpa-modal-body" ref="body">
                <div class="custom-control custom-checkbox"><input type="checkbox" id="accept-pdpa" value="1"
                            class="custom-control-input" v-model="onReady"> <label for="accept-pdpa"
                            class="custom-control-label">{{ $t('acceptPDPACheckbox') }}</label></div>
            </div>

            <div class="pdpa-modal-footer">
                <button type="button" @click="decline" class="btn btn-lg min-w-120 font-weight-bold">{{ $t('btnAcceptPDPADecline') }}</button>
                <button type="button" @click="accept" class="btn btn-lg btn-primary min-w-120 font-weight-bold"
                    :disabled="!onReady">{{ $t('btnAcceptPDPAAccept') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['event'],

    data() {
        return {
            onReady: false,
            activeModal: true,
        }
    },

    mounted() {
        const body = document.body;
        body.classList.add("overflow-hidden");
    },

    methods: {
        decline() {

            this.$emit('done', false)
            this.closeModal()

            // this.$swal({
            //     icon: 'info',
            //     title: `ปฏิเสธ นโยบาย PDPA`,
            //     showCancelButton: true,
            //     confirmButtonText: `ปฏิเสธและออกจากระบบ`,
            //     cancelButtonText: `ทำรายการต่อ`,
            //     text: `หากคุณปฏิเสธ นโยบาย PDPA คุณจะไม่สามารถดำเนิกการต่อได้, คุณแน่ใจใช่ไหมที่จะปฏิเสธ?`,
            // }).then((res) => {

            //     if (res.isConfirmed) {
            //         if (this.event) {
            //             window.location.href = `/${this.event.slug}/account/logout`
            //         }
            //     }
            // });
        },

        async accept() {
            try {
                // const { data: response } = await axios.post('/api/pdpa/accept')
                // console.log(response);
                this.$emit('done', true)
                this.closeModal()
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: this.$t('Oops'),
                    text: this.$t('OopsMessage'),
                });
            }

        },

        closeModal() {
            const body = document.body;
            body.classList.remove("overflow-hidden");
            this.activeModal = false
        }
    }
}
</script>

<style lang="scss" scoped>
.pdpa-modal {
    z-index: 400;
    background-color: rgba(0, 0, 0, 0.5);

    position: fixed;
    inset: 0px;
    flex-direction: column-reverse;
    display: flex;
    visibility: inherit;


    .pdpa-modal-content {

        box-shadow: rgb(20 21 26 / 16%) 0px 8px 16px, rgb(71 77 87 / 16%) 0px 16px 32px, rgb(20 21 26 / 10%) 0px 0px 1px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        background-color: #fff;
    }

    .pdpa-modal-header {
        padding: 8px 16px;
    }

    .pdpa-modal-body {
        padding: 8px 16px;
        max-height: calc(100vh - 240px);
        overflow-y: auto;
        overflow-x: hidden;

        p {
            white-space: normal;
        }
    }

    .pdpa-modal-footer {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 16px;
    }
}


// @media (min-width: 768px) {
.pdpa-modal {
    align-items: center;
    justify-content: center;
    flex-direction: unset;

    .pdpa-modal-header {
        padding: 32px 24px 8px;
    }

    .pdpa-modal-body {
        padding: 16px 24px;
    }

    .pdpa-modal-content {
        border-radius: 6px;
        animation: 0.3s ease-in-out 0s 1 normal none running animation-pdpa-modal;
        max-width: 460px;
    }

    .pdpa-modal-footer {
        padding: 16px 24px 24px;
    }
}

// }


@keyframes animation-pdpa-modal {
    0% {
        transform: scale(0.95);
    }

    66% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}
</style>>