<template>
    <div class="profile-page personal-information-page">
        <h1>Update Profile</h1>

        <form @submit.prevent="updateProfile" class="info-sections">
            <AccountInfo :config="config" :form="form" :member="member" :prefixs="prefixs" :errors="errors" />
            <AccountOccupation :workplaces="workplaces" :occupations="occupations" :affiliations="affiliations"
                :levels="levels" :subjects="subjects" :form="form" :errors="errors" :amphureCaching="amphureCaching"
                :districtCaching="districtCaching" />
            <AccountAddress :event="event" :config="config" :form="form" :provinces="provinces" :errors="errors"
                :amphureCaching="amphureCaching" :districtCaching="districtCaching" />
            <AccountBillingAddress :event="event" :config="config" :form="form" :provinces="provinces"
                :errors="errors" />
            <AccountCommunication :event="event" :config="config" :form="form" :errors="errors" />

            <div class="personal-information-footer">
                <div class="personal-information-footer-buttons">
                    <button type="submit" :disabled="isSaved" class="btn btn-lg btn-primary min-w-120 font-weight-bold"
                        :class="[isSaved && `disabled`]">บันทึก</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
const PREFIX_OTHER = 'other'
import AccountInfo from '../components/Account/Info.vue';
import AccountOccupation from '../components/Account/Occupation.vue';
import AccountAddress from '../components/Account/Address.vue';
import AccountBillingAddress from '../components/Account/BillingAddress.vue';
import AccountCommunication from '../components/Account/Communication.vue';

const OCCUPATION_STUDENT_ID = 9
const OCCUPATION_OUTER_ID = [10, 11]
const AFFILIATION_OUTER_ID = 8
const LEVEL_OUTER_ID = 7
const SUBJECT_OUTER_ID = 15

export default {
    props: ["event", 'member', 'occupations', 'levels', 'subjects', 'workplaces', 'affiliations', 'provinces', 'billing', 'code', 'subscribes'],

    components: { AccountInfo, AccountOccupation, AccountAddress, AccountBillingAddress, AccountCommunication },

    data() {
        const prefixs = [];
        prefixs.push({ id: 'นาย', name: "Mr. (นาย)" });
        prefixs.push({ id: 'นาง', name: "Mrs. (นาง)" });
        prefixs.push({ id: 'นางสาว', name: "Ms. (นางสาว)" });
        prefixs.push({ id: PREFIX_OTHER, name: "Other (อื่น ๆ)" });

        return {
            prefixs,
            config: {
                agree: false,

                // info
                day: '',
                month: '',
                year: '',
                prefixId: '',

                billingAddressSameCurrentAddress: true,
            },

            form: {
                // info
                prefix: '',
                first_name: '',
                last_name: '',

                prefix_en: '',
                first_name_en: '',
                last_name_en: '',

                gender: '',

                phone_number: '',
                birthday: '',

                // occupation
                occupation_id: '',
                occupation_custom: '',

                affiliation_id: '',
                affiliation_custom: '',

                levels: [], // ระดับชั้น
                other_teaching_group: '',


                subjects: [], // วิชาสอน
                other_teaching_level: '',

                university_faculty: '',
                university_name: '',
                university_year: '',

                // address
                shipping_name: '',
                address_no: '',
                address_province_id: '',
                address_amphure_id: '',
                address_district_id: '',
                address_zip: '',

                // billing 
                billingAddress: {
                    full_name: '',
                    address: '',
                    province_id: '',
                    amphures_id: '',
                    district_id: '',
                    zipcode: '',
                    tel: '',
                },
                billingAddressSameCurrent: true,

                // Confirmation
                subscription: 1,
                subscribes: [1, 2],

                work_id: '',
                work_name: '',
            },

            isSaved: false,
            isError: false,
            errors: {},

            amphureCaching: [],
            districtCaching: [],

            OCCUPATION_STUDENT_ID,
            OCCUPATION_OUTER_ID,
            AFFILIATION_OUTER_ID,
            LEVEL_OUTER_ID,
            SUBJECT_OUTER_ID,
        }
    },

    created() {
        this.updateFromByMember()
    },

    methods: {
        updateFromByMember() {
            this.form = { ...this.form, ...this.member }

            if (this.member.birthday) {
                const birthday = this.member.birthday.split('-')

                this.config.year = birthday[0]
                this.config.month = birthday[1]
                this.config.day = birthday[2]
            }

            if (this.member.prefix) {
                const prefix = this.prefixs.find(n => n.id == this.member.prefix)
                this.config.prefixId = prefix ? prefix.id : PREFIX_OTHER
            }

            if (this.member.levels) {
                this.form.subjects = this.member.levels.map(n => n.id)
            }

            if (this.member.teachings) {
                this.form.levels = this.member.teachings.map(n => n.id)
            }

            if (this.billing) {
                this.form.billingAddressSameCurrent = false
                this.form.billingAddress = { ...this.form.billingAddress, ...this.billing }
            }

            if (this.subscribes) {
                this.form.subscribes = this.subscribes.map(n => n.channel_id)
            }

            this.form.subscription = this.member.subscription ? 1 : 0;
        },

        async updateProfile() {
            this.isSaved = true;
            this.isError = false;

            const payload = { ...this.form, eventId: this.event.id }

            if (!payload.subscription) {
                payload.notifications = []
            }

            if (this.validate()) {
                await this.timeout(800);

                const titleLoading = this.$t('Loading')
                const swal = this.$swal({
                    // text: this.$t('Loading'),
                    showConfirmButton: false,
                    background: 'transparent',
                    backdrop: `rgba(0,0,0,0.9)`,
                    title: "<h3 style='color: white;'>" + titleLoading + "...</h3>",
                    html: "<div style='color: white;'>เรากำลังบันทึกข้อมูล, กรุณาอย่าปิดหน้านี้</div>",
                    showLoaderOnConfirm: false,
                    allowOutsideClick: false,
                });

                try {
                    const { data: response } = await axios.put('/api/profile', payload)
                    swal.close()

                    if (response.success) {
                        this.$swal({
                            icon: 'success',
                            title: response.intro || this.$t('Success'),
                            text: response.message,
                        }).then(() => {
                            window.location.href = response.redirectUrl || this.eventBastUrl('/')
                        })
                    }
                    else {
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }


                } catch (error) {
                    const { data } = error.response
                    this.isError = true

                    this.$swal({
                        icon: 'error',
                        title: data.intro || this.$t('Oops'),
                        text: data.message,
                    });
                }
            }
            else {
                this.isError = true

                await this.timeout(3)
                this.focusInvalid()
            }

            this.isSaved = false
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        focusInvalid() {
            const $invalid = $('.is-invalid')

            if ($invalid.length == 0) return
            $invalid[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        },

        validate() {
            const form = this.form
            this.errors = {}

            // Info
            const rules = {
                // prefix: 'กรุณาระบุคำนำหน้าชื่อ',
                first_name: 'กรุณาระบุชื่อ (ภาษาไทย)',
                last_name: 'กรุณาระบุนามสกุล (ภาษาไทย)',

                first_name_en: 'กรุณาระบุชื่อ (ภาษาอังกฤษ)',
                last_name_en: 'กรุณาระบุนามสกุล (ภาษาอังกฤษ)',

                gender: 'กรุณาระบุเพศ',
                birthday: 'กรุณาระบุวันเกิด',
            }

            for (const key in rules) {
                if (Object.hasOwnProperty.call(rules, key)) {
                    const message = rules[key];

                    if (!form[key]) {
                        this.errors[key] = message
                    }
                }
            }

            if (!this.config.prefixId) {
                this.errors.prefix = `กรุณาระบุคำนำหน้าชื่อ`
                this.errors.prefix_en = `กรุณาระบุคำนำหน้าชื่อ`
            }
            else if (this.config.prefixId == PREFIX_OTHER) {

                if (!form.prefix) {
                    this.errors.prefix = `กรุณาระบุคำนำหน้าชื่อ`
                }

                if (!form.prefix_en) {
                    this.errors.prefix_en = `กรุณาระบุคำนำหน้าชื่อ`
                }
            }


            // Occupation
            if (!form.occupation_id) {
                this.errors.occupation_id = `กรุณาระบุอาชีพหรือตำแหน่ง`
            }
            else if (OCCUPATION_OUTER_ID.indexOf(form.occupation_id) >= 0) {

                if (!form.occupation_custom) {
                    this.errors.occupation_custom = `กรุณาระบุอาชีพหรือตำแหน่ง`
                }

            }
            else if (OCCUPATION_STUDENT_ID == form.occupation_id) {
                if (!form.university_faculty) this.errors.university_faculty = `กรุณาระบุคณะ`
                if (!form.university_name) this.errors.university_name = `กรุณาระบุมหาวิทยาลัย`
                if (!form.university_year) this.errors.university_year = `กรุณาระบุชั้นปี`
            }
            else {

                if (!form.affiliation_id) {
                    this.errors.affiliation_id = `กรุณาระบุสังกัด`
                } else if (form.affiliation_id == AFFILIATION_OUTER_ID && !form.affiliation_custom) {
                    this.errors.affiliation_custom = `กรุณาระบุสังกัด`
                }

                if (form.levels.length == 0) {
                    this.errors.levels = `กรุณาระบุระดับชั้นที่สอนหรือเกี่ยวข้อง`
                }

                if (form.levels.indexOf(LEVEL_OUTER_ID) >= 0 && !form.other_teaching_group) {
                    this.errors.other_teaching_group = `กรุณาระบุระดับชั้นที่สอนหรือเกี่ยวข้อง`
                }


                if (form.subjects.length == 0) {
                    this.errors.subjects = `กรุณาระบุกลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง`
                }

                if (form.subjects.indexOf(SUBJECT_OUTER_ID) >= 0 && !form.other_teaching_level) {
                    this.errors.other_teaching_level = `กรุณาระบุกลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง`
                }


                if (!form.work_id) {
                    this.errors.work_id = `กรุณาระบุสถานที่ทำงาน`
                }

                if (!form.work_name) {
                    this.errors.work_name = `กรุณาระบุชื่อสถาบัน`
                }
            }

            // Address
            const ruleAddress = {
                // shipping_name: 'กรุณาระบุชื่อ',
                address_no: 'กรุณาระบุที่อยู่',
                address_province_id: 'กรุณาระบุจังหวัด',
                address_amphure_id: 'กรุณาระบุอำเภอหรือเขต',
                address_district_id: 'กรุณาระบุตำบลหรือแขวง',
                address_zip: 'กรุณาระบุรหัสไปรษณีย์',
            }

            for (const key in ruleAddress) {
                if (Object.hasOwnProperty.call(ruleAddress, key)) {
                    const message = ruleAddress[key];

                    if (!form[key]) {
                        this.errors[key] = message
                    }
                }
            }


            // BillingAddress
            if (!this.form.billingAddressSameCurrent) {
                const billingRules = {
                    full_name: 'กรุณาระบุชื่อ',
                    address: 'กรุณาระบุที่อยู่',
                    province_id: 'กรุณาระบุจังหวัด',
                    amphures_id: 'กรุณาระบุอำเภอหรือเขต',
                    district_id: 'กรุณาระบุตำบลหรือแขวง',
                    zipcode: 'กรุณาระบุรหัสไปรษณีย์',
                }

                for (const key in billingRules) {
                    if (Object.hasOwnProperty.call(billingRules, key)) {
                        const message = billingRules[key];

                        if (!form.billingAddress[key]) {
                            this.errors[`billing_${key}`] = message
                        }
                    }
                }
            }

            // Subscription
            if (this.form.subscription === null) {
                this.errors.subscription = 'กรุณาเลือกการรับข่าวสารจาก EDUCA'
            }
            else if (this.form.subscription == 1) {
                if (this.form.subscribes.length == 0) {
                    this.errors.subscribes = 'กรุณาเลือกช่องทางการรับข่าวสาร'
                }
            }

            return Object.keys(this.errors).length == 0
        }
    }
}
</script>
