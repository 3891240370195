<template>
        <div class="order-summary-main">
            <div class="content-form-center" style="position: relative">
                <h4 class="header-4">PERSONAL INFORMATION</h4>
                <div class="form-row" v-if="!isFormShow">
                    <div class="form-group col-md-6">
                        <div class="form-group row">
                            <label for="staticEmail" class="col-sm-4 col-form-label bold-size">Email / อีเมลสมาชิก</label>
                            <div class="col-sm-8">
                                <label  class="col-form-label">{{form.email}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label bold-size">MOBILE NO. / เบอร์ติดต่อ</label>
                            <div class="col-sm-8">
                                <label  class="col-form-label">{{form.phone_number}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button  @click="toggleForm" type="button" class="btn bluebtn editprofile" style="width: auto;position: absolute;top:10px;right: 10px;">{{text_edit_profile}}</button>
            
                <div class="form-row" style="justify-content: center;margin-top: 25px"  v-if="isFormShow"  >
                    <form @submit.prevent="updateInfo" class="info-section">
                        <div class="card card-info mb-4" style="border:none">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4 mb-3">
                                        <label class="font-weight-bold" for="prefixInput">Title / คำนำหน้าชื่อ (ภาษาไทย)</label>

                                        <div :class="[config.prefixId == PREFIX_OTHER && `input-group`, errors.prefix && `is-invalid`]">
                                            <select id="prefixInput" class="custom-select" v-model="config.prefixId"
                                                :class="[isLoad && `disabled`]">
                                                <option value="">Please select / โปรดเลือก</option>
                                                <option :value="item.id" v-for="(item, index) in prefixs" :key="index">{{ item.name }}
                                                </option>
                                            </select>

                                            <input v-if="config.prefixId == PREFIX_OTHER" type="text" class="form-control"
                                                v-model="form.prefix" placeholder="ระบุ" autocomplete="off" />
                                        </div>

                                        <div class="invalid-feedback" v-if="errors.prefix">{{ errors.prefix }}</div>
                                    </div>
                                    <div class="col-md">

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label class="font-weight-bold d-flex" for="firstNameInput">
                                                    <span class="mr-1">Name / ชื่อ (ภาษาไทย)</span>
                                                    <span class="text-danger">*</span>
                                                </label>

                                                <input type="text" class="form-control" id="firstNameInput"
                                                    :class="[isLoad && `disabled`, errors.first_name && `is-invalid`]"
                                                    v-model="form.first_name" />

                                                <div class="invalid-feedback" v-if="errors.first_name">{{ errors.first_name }}</div>
                                            </div>

                                            <div class="col-md-6 mb-3">
                                                <label class="font-weight-bold d-flex" for="lastNameInput">
                                                    <span class="mr-1">Surname / นามสกุล (ภาษาไทย)</span>
                                                    <span class="text-danger">*</span>
                                                </label>

                                                <input type="text" class="form-control" id="lastNameInput"
                                                    :class="[isLoad && `disabled`, errors.last_name && `is-invalid`]"
                                                    v-model="form.last_name" />

                                                <div class="invalid-feedback" v-if="errors.last_name">{{ errors.last_name }}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-4 mb-3 d-none d-md-block">
                                        <label class="font-weight-bold" for="prefixEnInput">Title / คำนำหน้าชื่อ (ภาษาอังกฤษ)</label>

                                        <div
                                            :class="[config.prefixId == PREFIX_OTHER && `input-group`, errors.prefix_en && `is-invalid`]">
                                            <select id="prefixInput" class="custom-select" v-model="config.prefixId"
                                                :class="[isLoad && `disabled`]">
                                                <option value="">Please select / โปรดเลือก</option>
                                                <option :value="item.id" v-for="(item, index) in prefixs" :key="index">{{ item.name }}
                                                </option>
                                            </select>

                                            <input v-if="config.prefixId == PREFIX_OTHER" type="text" class="form-control"
                                                v-model="form.prefix_en" placeholder="ระบุ" autocomplete="off" />
                                        </div>

                                        <div class="invalid-feedback" v-if="errors.prefix_en">{{ errors.prefix_en }}</div>
                                    </div>

                                    <div class="col-md">
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label class="font-weight-bold d-flex" for="firstNameEnInput">
                                                    <span class="mr-1">Name / ชื่อ (ภาษาอังกฤษ)</span>
                                                    <span class="text-danger">*</span>
                                                </label>

                                                <input type="text" class="form-control" id="firstNameEnInput"
                                                    :class="[isLoad && `disabled`, errors.first_name_en && `is-invalid`]"
                                                    v-model="form.first_name_en" />

                                                <div class="invalid-feedback" v-if="errors.first_name_en">{{ errors.first_name_en }}
                                                </div>
                                            </div>

                                            <div class="col-md-6 mb-3">
                                                <label class="font-weight-bold d-flex" for="lastNameEnInput">
                                                    <span class="mr-1">Surname / นามสกุล (ภาษาอังกฤษ)</span>
                                                    <span class="text-danger">*</span>
                                                </label>

                                                <input type="text" class="form-control" id="lastNameEnInput"
                                                    :class="[isLoad && `disabled`, errors.last_name_en && `is-invalid`]"
                                                    v-model="form.last_name_en" />

                                                <div class="invalid-feedback" v-if="errors.last_name_en">{{ errors.last_name_en }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label class="font-weight-bold d-flex" for="">
                                        <span class="mr-1">Gender / เพศ</span>
                                        <span class="text-danger">*</span>
                                    </label>

                                    <div class="d-md-flex radio-wrap" :class="[errors.gender && `is-invalid`]">
                                        <div v-for="(gender, index) in genders" :key="index" class="custom-control custom-radio mr-3">
                                            <input type="radio" name="gender" :id="`gender-${gender.id}`" :value="gender.id"
                                                class="custom-control-input" v-model="form.gender" />
                                            <label :for="`gender-${gender.id}`" class="custom-control-label">{{ gender.name }}</label>
                                        </div>
                                    </div>
                                    <div class="invalid-feedback" v-if="errors.gender">{{ errors.gender }}</div>
                                </div>

                                <div class="mb-3">
                                    <label class="font-weight-bold d-flex" for="">
                                        <span class="mr-1">Date of Birth / วันเกิด</span>
                                        <span class="text-danger">*</span>
                                    </label>

                                    <div class="row" :class="[errors.birthday && `is-invalid`]">
                                        <div class="col-md-3 mb-1 mb-md-0">
                                            <select class="custom-select" v-model="config.day">
                                                <option value="" disabled>Day / วันเกิด</option>
                                                <option :value="item.id" v-for="(item, index) in days" :key="index">{{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-3 mb-1 mb-md-0">
                                            <select class="custom-select" v-model="config.month">
                                                <option value="" disabled>Month / เดือนเกิด</option>
                                                <option :value="item.id" v-for="(item, index) in months" :key="index">{{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-3 mb-1 mb-md-0">
                                            <select class="custom-select" v-model="config.year">
                                                <option value="" disabled>Year / ปีเกิด (ค.ศ.)</option>
                                                <option :value="item.id" v-for="(item, index) in years" :key="index">{{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md">
                                            <div v-if="age">
                                                <span class="fs-80p">Age / อายุ: </span>
                                                <strong>{{ age }} Years / ปี</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="invalid-feedback" v-if="errors.birthday">{{ errors.birthday }}</div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="font-weight-bold d-flex" for="phoneNumberInput">
                                            <span class="mr-1">Mobile No. / เบอร์ติดต่อ</span>
                                            <span class="text-danger">*</span>
                                        </label>

                                        <input type="text" class="form-control" id="phoneNumberInput" readonly
                                            v-model="form.phone_number" />
                                    </div>
                                </div>




                                <div>
                                                <label class="font-weight-bold d-flex" for="">
                                                    <span class="mr-1">Occupation or Poition / อาชีพหรือตำแหน่ง</span>
                                                    <span class="text-danger">*</span>
                                                </label>

                                                <select class="custom-select" v-model="form.occupation_id"
                                                    :class="[errors.occupation_id && `is-invalid`]">
                                                    <option value="" disabled>Please select / โปรดเลือก</option>
                                                    <option :value="item.id" v-for="(item, index) in occupations" :key="index">{{  item.name  }}
                                                    </option>
                                                </select>

                                                <div class="invalid-feedback" v-if="errors.occupation_id">{{  errors.occupation_id  }}</div>
                                            </div>

                                            <div class="mt-3" v-if="OCCUPATION_OUTER_ID.indexOf(form.occupation_id) >= 0">
                                                <label class="font-weight-bold d-flex" for="">
                                                    <span class="mr-1">Please specify / โปรดระบุอาชีพ</span>
                                                    <span class="text-danger">*</span>
                                                </label>

                                                <input type="text" class="form-control" v-model="form.occupation_custom"
                                                    :class="[errors.occupation_custom && `is-invalid`]" />

                                                <div class="invalid-feedback" v-if="errors.occupation_custom">{{  errors.occupation_custom  }}</div>
                                            </div>

                                            <div class="mt-3 row align-items-start" v-else-if="OCCUPATION_STUDENT_ID == form.occupation_id">
                                                <div class="col-md-5 mb-3">
                                                    <label for="universityFaculty" class="font-weight-bold mb-0 text-nowrap">
                                                        <span>Faculty / คณะ</span>
                                                        <span class="text-danger">*</span>
                                                    </label>

                                                    <input type="text" id="universityFaculty" class="form-control" v-model="form.university_faculty"
                                                        :class="[errors.university_faculty && `is-invalid`]" />
                                                    <div class="invalid-feedback" v-if="errors.university_faculty">{{  errors.university_faculty  }}
                                                    </div>
                                                </div>

                                                <div class="col-md-5 mb-3">
                                                    <label for="universityName" class="font-weight-bold mb-0 text-nowrap">
                                                        <span class="mr-1">University / มหาวิทยาลัย</span>
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <input type="text" id="universityName" class="form-control" v-model="form.university_name"
                                                        :class="[errors.university_name && `is-invalid`]" />
                                                    <div class="invalid-feedback" v-if="errors.university_name">{{  errors.university_name  }}</div>
                                                </div>

                                                <div class="col-md-2 mb-3">
                                                    <label for="universityYear" class="font-weight-bold mb-0 text-nowrap">
                                                        <span class="mr-1">Year / ชั้นปี</span>
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <input type="tel" maxlength="2" id="universityYear" v-model="form.university_year"
                                                        class="form-control" :class="[errors.university_year && `is-invalid`]" />
                                                    <div class="invalid-feedback" v-if="errors.university_year">{{  errors.university_year  }}</div>
                                                </div>

                                            </div>

                                            <div v-else-if="form.occupation_id && form.occupation_id <= 8">
                                                <div class="mt-3">
                                                    <label class="font-weight-bold" for="">Affiliation / สังกัด</label>
                                                    <select class="custom-select" v-model="form.affiliation_id"
                                                        :class="[errors.affiliation_id && `is-invalid`]">
                                                        <option value="" disabled>Please select / โปรดเลือก</option>
                                                        <option :value="item.id" v-for="(item, index) in affiliations" :key="index">{{  item.name  }}
                                                        </option>
                                                    </select>

                                                    <div class="invalid-feedback" v-if="errors.affiliation_id">{{  errors.affiliation_id  }}</div>
                                                </div>

                                                <div class="mt-2" v-if="form.affiliation_id == AFFILIATION_OUTER_ID">
                                                    <label class="font-weight-bold d-flex" for="">
                                                        <span class="mr-1">Please specify / โปรดระบุสังกัด</span>
                                                        <span class="text-danger">*</span>
                                                    </label>

                                                    <input type="text" class="form-control" v-model="form.affiliation_custom"
                                                        :class="[errors.affiliation_custom && `is-invalid`]" />

                                                    <div class="invalid-feedback" v-if="errors.affiliation_custom">{{  errors.affiliation_custom  }}
                                                    </div>
                                                </div>

                                                <div class="mt-3">
                                                    <label class="font-weight-bold mb-0 d-flex" for="">
                                                        <span class="mr-1">Teaching Level / ระดับชั้นที่สอนหรือเกี่ยวข้อง</span>
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <div class="text-muted mb-2">(you can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)
                                                    </div>

                                                    <div class="row" :class="[errors.levels && `is-invalid`]">
                                                        <div class="mb-2 col-md-5" v-for="(item, index) in levels" :key="index">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" :id="`teaching-${item.id}`" :value="item.id"
                                                                    class="custom-control-input" v-model="form.levels" />
                                                                <label :for="`teaching-${item.id}`" class="custom-control-label">{{  item.name 
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="invalid-feedback" v-if="errors.levels">{{  errors.levels  }}</div>

                                                    <div class="mt-1" v-if="form.levels.indexOf(LEVEL_OUTER_ID) >= 0">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">Please specify / โปรดระบุระดับชั้นที่สอนหรือเกี่ยวข้อง</span>
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input type="text" class="form-control" v-model="form.other_teaching_group"
                                                            :class="[errors.other_teaching_group && `is-invalid`]" />
                                                        <div class="invalid-feedback" v-if="errors.other_teaching_group">{{
                                                            errors.other_teaching_group 
                                                            }}</div>
                                                    </div>

                                                </div>

                                                <div class="mt-3">
                                                    <label class="font-weight-bold mb-0 d-flex" for="">
                                                        <span class="mr-1">Subject or Related Field / กลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง</span>
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <div class="text-muted mb-2">(you can select more than 1 choice / สามารถเลือกได้มากกว่า 1 ข้อ)
                                                    </div>

                                                    <div class="row" :class="[errors.subjects && `is-invalid`]">
                                                        <div class="mb-2 col-md-5" v-for="(item, index) in subjects" :key="index">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" :id="`subject-${item.id}`" :value="item.id"
                                                                    class="custom-control-input" v-model="form.subjects" />
                                                                <label :for="`subject-${item.id}`" class="custom-control-label">{{  item.name 
                                                                    }}</label>

                                                                <span class="custom-checkbox-bg"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="invalid-feedback" v-if="errors.subjects">{{  errors.subjects  }}</div>

                                                    <div class="mt-1" v-if="form.subjects.indexOf(SUBJECT_OUTER_ID) >= 0">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">Please specify / กลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง</span>
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input type="text" class="form-control" v-model="form.other_teaching_level"
                                                            :class="[errors.other_teaching_level && `is-invalid`]" />
                                                        <div class="invalid-feedback" v-if="errors.other_teaching_level">{{
                                                            errors.other_teaching_level 
                                                            }}</div>
                                                    </div>
                                                </div>

                                                <div class="mt-3 row">
                                                    <div class="col-md-4 mb-3">
                                                        <label for="work-id" class="font-weight-bold">Office Address
                                                            / สถานที่ทำงาน <span class="text-danger">*</span></label>

                                                        <select id="work-id" class="custom-select" v-model="form.work_id" :class="[errors.work_id && `is-invalid`]">
                                                            <option value="">Please select / โปรดเลือก</option>
                                                            <option :value="item.id" v-for="(item, index) in workplaces" :key="index">{{item.name}}</option>
                                                        </select>

                                                        <div class="invalid-feedback" v-if="errors.work_id">{{
                                                            errors.work_id 
                                                            }}</div>
                                                    </div>
                                                    <div class="col-md-8 mb-3">
                                                        <label for="workName" class="font-weight-bold">School or Institution
                                                            / ชื่อสถาบัน <span class="text-danger">*</span></label> 
                                                        <input type="text" id="workName" class="form-control" v-model="form.work_name" :class="[errors.work_name && `is-invalid`]" />

                                                        <div class="invalid-feedback" v-if="errors.work_name">{{
                                                            errors.work_name 
                                                            }}</div>
                                                    </div>
                                                </div>
                                            </div>





                            </div>
                        </div>

                        <div class="personal-information-footer-buttons">
                            
                        </div>
                    </form>
                </div>
            
            
            </div>



 





        </div>
</template>

<script>
    const CURRENT_STEP = 1
    const PREFIX_OTHER = 'other'
 
    const OCCUPATION_STUDENT_ID = 9
    const OCCUPATION_OUTER_ID = [10, 11]
    const AFFILIATION_OUTER_ID = 8
    const LEVEL_OUTER_ID = 7
    const SUBJECT_OUTER_ID = 15
    export default {
        props: ['form', 'config', 'member', 'prefixs', 'occupations', 'affiliations', 'levels', 'subjects', 'workplaces'],
    
        data() {
            
            const genders = [];
            genders.push({ id: '1', name: "Male (ชาย)" });
            genders.push({ id: '2', name: "Female (หญิง)" });
            genders.push({ id: '3', name: "Not specific (ไม่ระบุ)" });
    
    
            const days = []
            for (let index = 1; index <= 31; index++) {
                days.push({ id: index < 10 ? `0${index}` : index, name: index })
            }
    
            const monthList = ['January / มกราคม', 'February / กุมภาพันธ์', 'March / มีนาคม', 'April / เมษายน', 'May / พฤษภาคม', 'June / มิถุนายน', 'July / กรกฎาคม', 'August / สิงหาคม', 'September / กันยายน', 'October / ตุลาคม', 'November / พฤศจิกายน', 'December / ธันวาคม']
            const months = []
            for (let index = 1; index <= 12; index++) {
                months.push({ id: index < 10 ? `0${index}` : index, name: monthList[index - 1] })
            }
    
            const theDate = new Date()
            const years = []
            for (let index = theDate.getFullYear() - 18; index >= theDate.getFullYear() - 81; index--) {
                years.push({ id: index, name: index })
            }
    
            return {
                OCCUPATION_STUDENT_ID,
                OCCUPATION_OUTER_ID,
                AFFILIATION_OUTER_ID,
                LEVEL_OUTER_ID,
                SUBJECT_OUTER_ID,

                isFormShow : false,
                text_edit_profile : 'Edit Profile',
                genders,
                days,
                months,
                years,
    
                errors: {},
    
                isError: false,
                isLoad: false,
    
                PREFIX_OTHER,
            }
        },
    
        computed: {
            birthday() {
    
                let birthday = '';
                if (this.config.year && this.config.month && this.config.day) {
                    birthday = `${this.config.year}-${this.config.month}-${this.config.day}`
    
                    this.form.birthday = birthday
                }
    
                return birthday
            },
    
            age() {
    
                if (!this.birthday) return '';
    
                var today = new Date();
                var birthDate = new Date(this.birthday);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
    
                return age
            }
        },
    
        watch: {
            'config.prefixId'(val) {
                if (val != PREFIX_OTHER) {
                    this.form.prefix = val
                }
                else {
                    this.form.prefix = ''
                }
            }
        },
    
        created() {
        //this.form = { ...this.form, ...this.member }
        /*
        this.form.first_name = this.member.first_name
        this.form.last_name = this.member.last_name
        this.form.first_name_en = this.member.first_name_en
        this.form.last_name_en = this.member.last_name_en
        this.form.phone_number = this.member.phone_number
        this.form.email = this.member.email
      //  console.log(this.member)
        if (this.member.birthday) {
            const birthday = this.member.birthday.split('-')

            this.config.year = birthday[0]
            this.config.month = birthday[1]
            this.config.day = birthday[2]
        }

        if (this.member.prefix) {
            const prefix = this.prefixs.find(n => n.id == this.member.prefix)
            this.config.prefixId = prefix ? prefix.id : PREFIX_OTHER
        }

        if (this.member.levels) {
            this.form.subjects = this.member.levels.map(n => n.id)
        }

        if (this.member.teachings) {
            this.form.levels = this.member.teachings.map(n => n.id)
        }

        if (this.billing) {
            this.form.billingAddressSameCurrent = false
            this.form.billingAddress = { ...this.form.billingAddress, ...this.billing }
        }*/
    },
    
        mounted() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
           // console.log(this.member)
           //console.log( this.form.first_name  )
           //console.log('mounted')
        },
    
        methods: {
            showForm(){
                this.isFormShow = true
            },
            toggleForm(){
                this.isFormShow = !this.isFormShow;
                if(this.isFormShow){this.text_edit_profile = 'Hide Profile'}else{this.text_edit_profile = 'Edit Profile'}
            },
            async updateInfo() {
                this.isLoad = true
                this.isError = false
    
                await this.timeout(3);
                if (this.validateInfo()) {
                    this.$emit('done', CURRENT_STEP)
                }
                else {
                    this.isError = true
    
                    await this.timeout(3)
                    this.focusInvalid()
                }
    
                this.isLoad = false
            },
    
            timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
    
            validateInfo() {
                const form = this.form
                this.errors = {}
    
                const rules = {
                    // prefix: 'กรุณาระบุคำนำหน้าชื่อ',
                    first_name: 'กรุณาระบุชื่อ (ภาษาไทย)',
                    last_name: 'กรุณาระบุนามสกุล (ภาษาไทย)',
    
                    first_name_en: 'กรุณาระบุชื่อ (ภาษาอังกฤษ)',
                    last_name_en: 'กรุณาระบุนามสกุล (ภาษาอังกฤษ)',
    
                    gender: 'กรุณาระบุเพศ',
                    birthday: 'กรุณาระบุวันเกิด',
                }
    
                for (const key in rules) {
                    if (Object.hasOwnProperty.call(rules, key)) {
                        const message = rules[key];
    
                        if (!form[key]) {
                            this.errors[key] = message
                        }
                    }
                }
    
    
                if (!this.config.prefixId) {
                    this.errors.prefix = `กรุณาระบุคำนำหน้าชื่อ`
                    this.errors.prefix_en = `กรุณาระบุคำนำหน้าชื่อ`
                }
                else if (this.config.prefixId == PREFIX_OTHER) {
    
                    if (!form.prefix) {
                        this.errors.prefix = `กรุณาระบุคำนำหน้าชื่อ`
                    }
    
                    if (!form.prefix_en) {
                        this.errors.prefix_en = `กรุณาระบุคำนำหน้าชื่อ`
                    }
                }
    
                return Object.keys(this.errors).length == 0;
            },
            validateOccupation() {
            const form = this.form
            this.errors = {}

            if (!form.occupation_id) {
                this.errors.occupation_id = `กรุณาระบุอาชีพหรือตำแหน่ง`
            }
            else if (OCCUPATION_OUTER_ID.indexOf(form.occupation_id) >= 0 ) {
                if(!form.occupation_custom){
                  this.errors.occupation_custom = `กรุณาระบุอาชีพหรือตำแหน่ง`
                  console.log('occupation_custom')
                }
            }
            else if (OCCUPATION_STUDENT_ID == form.occupation_id) {
                if (!form.university_faculty) this.errors.university_faculty = `กรุณาระบุคณะ`
                if (!form.university_name) this.errors.university_name = `กรุณาระบุมหาวิทยาลัย`
                if (!form.university_year) this.errors.university_year = `กรุณาระบุชั้นปี`
                console.log('occupation_custom2')
            }
            else {
//1 && 0
               // if(form.occupation_id != '11' && form.occupation_id != '10'){
                    if (!form.affiliation_id) {
                        this.errors.affiliation_id = `กรุณาระบุสังกัด`
                    } else if (form.affiliation_id == AFFILIATION_OUTER_ID && !form.affiliation_custom) {
                        this.errors.affiliation_custom = `กรุณาระบุสังกัด`
                    }

                    if (form.levels.length == 0) {
                        this.errors.levels = `กรุณาระบุระดับชั้นที่สอนหรือเกี่ยวข้อง`
                    }

                    if (form.levels.indexOf(LEVEL_OUTER_ID) >= 0 && !form.other_teaching_group) {
                        this.errors.other_teaching_group = `กรุณาระบุระดับชั้นที่สอนหรือเกี่ยวข้อง`
                    }


                    if (form.subjects.length == 0) {
                        this.errors.subjects = `กรุณาระบุกลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง`
                    }

                    if (form.subjects.indexOf(SUBJECT_OUTER_ID) >= 0 && !form.other_teaching_level) {
                        this.errors.other_teaching_level = `กรุณาระบุกลุ่มสาระที่สอนหรือทำงานเกี่ยวข้อง`
                    }


                    if (!form.work_id) {
                        this.errors.work_id = `กรุณาระบุสถานที่ทำงาน`
                    }

                    if (!form.work_name) {
                        this.errors.work_name = `กรุณาระบุชื่อสถาบัน`
                    }
                    console.log('occupation_custom3')
                //}

            }

            return Object.keys(this.errors).length == 0
        },
            focusInvalid() {
                const $invalid = $('.is-invalid')
    
                if ($invalid.length == 0) return
                $invalid[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            },
    
    
            autoComplete(e) {
                if (!e.ctrlKey || !e.shiftKey) return false;
    
                this.form.first_name = `ภุชงค์`
                this.form.last_name = `สวนแจ้ง`
    
                this.form.first_name_en = `Puchong`
                this.form.last_name_en = `Suanjang`
    
                this.form.gender = 1
                this.form.phone_number = `0843635952`
    
                this.config.prefixId = `นาย`
                this.config.day = `18`
                this.config.month = `06`
                this.config.year = `1990`
            },
        }
    }
    </script>