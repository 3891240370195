<template>
    <div class="mb-3 row justify-content-center">

        <div class="col-lg-9">

            <div class="box-card">
                <div class="alert alert-warning">
                    <div class="font-weight-bold text-danger">
                        <p>กรุณาตรวจสอบความถูกต้องของชื่อ-นามสกุลทั้งภาษาไทยและภาษาต่างประเทศของสมาชิก</p>
                        <p>*ท่านสามารถแก้ไขได้เพียงครั้งเดียวเท่านั้น เมื่อกด “บันทึก” แล้ว ชื่อ-สกุลดังกล่าวจะปรากฏบนเกียรติบัตรของสมาชิก</p>
                        <p>***ทั้งนี้ หากเกิดข้อผิดพลาดและหรือสมาชิกต้องการแก้ไขความถูกต้องของชื่อสกุลอีกครั้ง ต้องชำระค่าธรรมเนียมจำนวน 100 บาท/ครั้ง ก่อนการดาวน์โหลด *** <a href="https://lin.ee/t626Dj" target="_blank">https://lin.ee/t626Dj</a></p>
                        <p>The information on the certificate could be EDITED ONLY ONCE. Before clicking "save" button, please carefully recheck your full name spelling.</p>
                        <p>***If there are any mistakes or misspellings on your full name, you must be charged 100 THB per time of operational fee for re-editing your information. *** Please contact us at EDUCA Line Official Account <a href="https://lin.ee/t626Dj" target="_blank">https://lin.ee/t626Dj</a></p>
                    </div>
                </div>

                <div id="name-container" class="my-4">

                    <!-- ชื่อภาษาไทย -->
                    <div class="row">
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="">Title / คำนำหน้า (ภาษาไทย)</label>

                            <div :class="isPrefixCustom && 'input-group'">
                                <select class="custom-select" v-model="member.prefix" :class="errors.prefix && 'is-invalid'">
                                    <option :value="item.name" v-for="(item, n) in prefixs" :key="n">{{ item.name }}</option>
                                </select>

                                <input v-if="isPrefixCustom" type="text" class="form-control" v-model="member.prefix_custom" :class="errors.prefix_custom && 'is-invalid'" style="min-width: 150px;" />
                            </div>

                            <div class="invalid-feedback d-block" v-if="errors.prefix">{{ errors.prefix }}</div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="">Name / ชื่อ (ภาษาไทย)
                                <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="member.first_name" :class="errors.first_name && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.first_name">{{ errors.first_name }}</div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="">Surname / นามสกุล (ภาษาไทย) <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="member.last_name" :class="errors.last_name && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.last_name">{{ errors.last_name }}</div>
                        </div>
                    </div>
                    <!-- end: ชื่อภาษาไทย -->

                    <!-- ชื่อภาษาอังกฤษ -->
                    <div class="row">
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="prefix_en">Title / คำนำหน้า (ภาษาอังกฤษ)</label>

                            <div :class="isPrefixCustomEn && 'input-group'">
                                <select class="custom-select" id="prefix_en" v-model="member.prefix_en" :class="errors.prefix_en && 'is-invalid'">
                                    <option :value="item.name_en" v-for="(item, n) in prefixs" :key="n">{{ item.name_en }}</option>
                                </select>

                                <input v-if="isPrefixCustomEn" type="text" class="form-control" v-model="member.prefix_en_custom" :class="errors.prefix_en && 'is-invalid'" style="min-width: 150px;" />
                            </div>

                            <div class="invalid-feedback d-block" v-if="errors.prefix_en">{{ errors.prefix_en }}</div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="first_name_en">Name / ชื่อ (ภาษาอังกฤษ)<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="first_name_en" v-model="member.first_name_en" :class="errors.first_name_en && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.first_name_en">{{ errors.first_name_en }}</div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="font-weight-bold" for="last_name_en">Surname / นามสกุล (ภาษาอังกฤษ)<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="last_name_en" v-model="member.last_name_en" :class="errors.last_name_en && 'is-invalid'" />
                            <div class="invalid-feedback" v-if="errors.last_name_en">{{ errors.last_name_en }}</div>
                        </div>
                    </div>
                    <!-- end: ชื่อภาษาอังกฤษ -->
                </div>
            </div>

            <div v-if="showform" class="mt-4">
                <h1><strong>แบบสอบถามการจัดงาน EDUCA MahidolKids</strong></h1>

                <div class="mt-3 fs-14 mb-4">
                    <div>คำชี้แจง (Survey Instruction)</div>
                    <h3 class="mb-3">แบบสอบถามชุดนี้จัดทำขึ้นเพื่อสำรวจความพึงพอใจผู้เข้าร่วมงาน และสำรวจความต้องการ ความสนใจ การพัฒนาองค์ความรู้ด้านวิทยาศาสตร์ของครู และการรับรู้ต่อแหล่งเรียนรู้วิทยาศาสตร์ เพื่อนำไปพัฒนาเนื้อหา และส่งเสริมให้เหมาะสมและตรงกับความต้องการมากยิ่งขึ้น ทั้งนี้  ขอความร่วมมือจากท่านในการตอบแบบสอบถามทุกข้อ จะขอบพระคุณยิ่ง และ กด SAVE เพื่อดาวน์โหลดเกียรติบัตรในลำดับต่อไป</h3>
                    <h3 class="mb-3">This questionnaire aims to survey the EDUCA Overall satisfaction of the participants. and exploring the teachers' needs, interests, understanding of scientific knowledge, and perception of science learning resources to develop content and communicate it to be more appropriate and meet the needs of our teachers.</h3>
                    <h3 class="mb-3">It will be very grateful if you could complete all items, then press SAVE to download your certificate in PDF file.</h3>
                </div>


                <!-- Part 1 -->
                <div class="card mb-4">

                    <div class="card-header">
                        <div><strong>Part 1</strong> - แบบสอบถามความพึงพอใจของผู้เข้าร่วมงานต่อการจัดงาน EDUCA Online Festival 2021</div>
                        <div>The Survey on Participants' opinion towards overall event</div>
                    </div>

                    <div class="card-body">
                        <div id="answer-0-0" class="mb-4">
                            <div class="mb-2">
                                <strong>1. ความคิดเห็นที่มีต่อเวิร์กช็อป / What are your thoughts about workshop? <span class="text-danger">*</span></strong>
                                <div>โปรดเลือกคำตอบที่ตรงกับความคิดเห็นของท่านมากที่สุด (Please choose the answers that meet with your opinion.)</div>
                            </div>

                            <div>
                                <table class="card-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th class="td-check">4 (มากที่สุด/Most)</th>
                                            <th class="td-check">3</th>
                                            <th class="td-check">2</th>
                                            <th class="td-check">1 (น้อยที่สุด/Least)</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(question,i) in part1Question1" :key="i">
                                            <td class="td-name">{{question.name}}</td>

                                            <td class="td-check" v-for="(val, index) in question.options" :key="index">
                                                <div class="custom-control custom-radio">

                                                    <input type="radio" :id="`survey-1-1-${i}-${index}`" :value="val" class="custom-control-input" :name="`survey-1-1-${i}`" v-model="answers[0].answers[0].answer[i]">

                                                    <label :for="`survey-1-1-${i}-${index}`" class="custom-control-label">
                                                    </label>
                                                </div>
                                            </td>
                                            
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>

                        <div id="answer-0-1" class="mb-4">
                            <div class="mb-2">
                                <strong>2. การประเมินการดำเนินกิจกรรมในภาพรวมงาน EDUCA Online Festival 2021 18-22 ต.ค.64 (Overall Process) <span class="text-danger">*</span></strong>
                            </div>

                            <div>
                                <table class="card-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th class="td-check">1</th>
                                            <th class="td-check">2</th>
                                            <th class="td-check">3</th>
                                            <th class="td-check">4</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>น้อยที่สุด (Least)</td>
                                            <td class="td-check">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="survey-1-2-1" name="survey-like-0" class="custom-control-input" value="1" v-model="answers[0].answers[1].answer">
                                                    <label for="survey-1-2-1" class="custom-control-label"></label>
                                                </div>
                                            </td>
                                            <td class="td-check">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="survey-1-2-2" name="survey-like-0" class="custom-control-input" value="2" v-model="answers[0].answers[1].answer">
                                                    <label for="survey-1-2-2" class="custom-control-label"></label>
                                                </div>
                                            </td>
                                            <td class="td-check">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="survey-1-2-3" name="survey-like-0" class="custom-control-input" value="3" v-model="answers[0].answers[1].answer">
                                                    <label for="survey-1-2-3" class="custom-control-label"></label>
                                                </div>
                                            </td>
                                            <td class="td-check">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="survey-1-2-4" name="survey-like-0" class="custom-control-input" value="4" v-model="answers[0].answers[1].answer">
                                                    <label for="survey-1-2-4" class="custom-control-label"></label>
                                                </div>
                                            </td>
                                            <td>มากที่สุด (Most)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>

                        </div>

                        <div id="answer-0-2" class="mb-4">
                            <div class="mb-2">
                                <strong>3. ระยะเวลาในการจัดงาน EDUCA 2021 (5 วัน) มีความเหมาะสมหรือไม่ (Appropriateness of the amount of time used in the Event---5 days) <span class="text-danger">*</span></strong>
                                <div class="fs-13 text-">หากไม่เหมาะสม กรุณาตอบจำนวนวันที่เหมาะสมในช่อง "อื่นๆ" (If not, please specify the amount of time in 'Other'.)</div>
                            </div>

                            <div>
                                <ul class="survey-lists-radio">
                                    <li>
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="survey-1-3-1" class="custom-control-input" value="1" v-model="answers[0].answers[2].answer">
                                            <label for="survey-1-3-1" class="custom-control-label">เหมาะสม</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="survey-1-3-2" class="custom-control-input" value="2" v-model="answers[0].answers[2].answer">
                                            <label for="survey-1-3-2" class="custom-control-label">ไม่เหมาะสม</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex align-items-center">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="survey-like-1-3" class="custom-control-input" value="3" v-model="answers[0].answers[2].answer">
                                                <label for="survey-like-1-3" class="custom-control-label">อื่นๆ:</label>
                                            </div>

                                            <div class="flex-fill ml-2" v-if="answers[0].answers[2].answer==3">
                                                <input type="text" :disabled="answers[0].answers[2].answer!=3" class="form-control input-note" v-model="answers[0].answers[2].note">
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>

                        <div id="answer-0-3" class="mb-4">
                            <div class="mb-2">
                                <strong>4. ข้อคิดเห็น และ/หรือ ข้อเสนอแนะเพิ่มเติม เกี่ยวกับการจัดงาน EDUCA Online Festival 2021 (Additional comments on the EDUCA Online Festival 2021) <span class="text-danger">*</span></strong>
                            </div>

                            <textarea class="form-control" rows="2" v-model="answers[0].answers[3].answer"></textarea>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>
                    </div>
                </div>

                <!-- Part 2 -->
                <div class="card mb-4">
                    <div class="card-header">
                        <div><strong>Part 2</strong> - แบบสำรวจความรู้และความต้องการการพัฒนาด้านกระบวนการวิทยาศาสตร์และแหล่งเรียนรู้นอกห้องเรียน</div>
                        <div>The Survey on Participants' needs and understanding towards sciencific knowledge and learning resources</div>
                    </div>

                    <div class="card-body">
                        <div id="answer-1-0" class="mb-4">
                            <div class="mb-2">
                                <strong>1. ท่านคิดว่าความรู้และทักษะกระบวนการคิดวิทยาศาสตร์มีความจำเป็นต่อการเรียนการสอนหรือไม่ / Do you think that knowledge and skills of scientific thinking are necessary for teaching and learning? <span class="text-danger">*</span></strong>
                            </div>

                            <ul class="survey-lists-radio">
                                <li>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="survey-2-1-0" name="survey-2-1-0" class="custom-control-input" value="0" v-model="answers[1].answers[0].answer">
                                        <label for="survey-2-1-0" class="custom-control-label">ไม่จำเป็น(no)</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="survey-2-1-1" name="survey-2-1-0" class="custom-control-input" value="1" v-model="answers[1].answers[0].answer">
                                        <label for="survey-2-1-1" class="custom-control-label">จำเป็น(yes)</label>
                                    </div>
                                </li>
                            </ul>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>


                        <div id="answer-1-1" class="mb-4">
                            <div class="mb-2">
                                <strong>2. หน่วยงานของท่านมีการส่งเสริมทักษะด้านวิทยาศาสตร์ใดบ้างดังต่อไปนี้ (เลือกได้มากกว่า 1 รายการ) / Which of the following science skills is your school/organization supporting? (You can choose more than one answer) <span class="text-danger">*</span></strong>
                            </div>

                            <ul class="survey-lists-radio">
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-2-1" class="custom-control-input" value="1" v-model="answers[1].answers[1].answer">
                                        <label for="survey-2-2-1" class="custom-control-label">เครื่องมือหรือสื่อเพื่อการเรียนการสอนวิทยาศาสตร์ / Tools or media for teaching science</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-2-2" class="custom-control-input" value="2" v-model="answers[1].answers[1].answer">
                                        <label for="survey-2-2-2" class="custom-control-label">ห้องทดลองวิทยาศาสตร์ / Science lab</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-2-3" class="custom-control-input" value="3" v-model="answers[1].answers[1].answer">
                                        <label for="survey-2-2-3" class="custom-control-label">ฝึกอบรมครูคิดเชิงวิทย์แบบออนไลน์ / Online Training for Science Thinking Teachers</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-2-4" class="custom-control-input" value="4" v-model="answers[1].answers[1].answer">
                                        <label for="survey-2-2-4" class="custom-control-label">จัดทัศนศึกษาแหล่งเรียนรู้วิทยาศาสตร์ / Musuem tour, excursion</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-2-5" class="custom-control-input" value="5" v-model="answers[1].answers[1].answer">
                                        <label for="survey-2-2-5" class="custom-control-label">เชิญวิทยากรมาบรรยายที่โรงเรียนหรือ เฉพาะกลุ่ม /closed group training support</label>
                                    </div>
                                </li>

                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-2-6" class="custom-control-input" value="6" v-model="answers[1].answers[1].answer">
                                        <label for="survey-2-2-6" class="custom-control-label">จัดกิจกรรมงานวันวิทยาศาสตร์ /งานบรูณาการแข่งขันเกี่ยวกับวิทย์ / science day event or competition </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-2-7" name="survey-like-0" class="custom-control-input" value="7" v-model="answers[1].answers[1].answer">
                                        <label for="survey-2-2-7" class="custom-control-label">จัดรายการเกี่ยวกับส่งเสริมวิทยาศาสตร์ / Organizing & communicating Sciences programs </label>
                                    </div>
                                </li>
                                <li>

                                    <div class="d-flex align-items-center">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="survey-2-2-8" name="survey-like-0" class="custom-control-input" value="8" v-model="answers[1].answers[1].answer">
                                            <label for="survey-2-2-8" class="custom-control-label">อื่นๆ:</label>
                                        </div>

                                        <div class="flex-fill ml-2" >
                                            <input type="text" class="form-control input-note" v-model="answers[1].answers[1].note">
                                        </div>

                                    </div>

                                    
                                </li>
                            </ul>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>

                        <div id="answer-1-2" class="mb-4">
                            <div class="mb-2">
                                <strong>3. ท่านคิดว่าแหล่งเรียนรู้ทางวิทยาศาสตร์ เช่น พิพิธภัณฑ์ ในยุค New Normal มีความจำเป็นหรือไม่ / Do you think scientific learning resources such as museums or outdoor learning resrouces in the New Normal era are necessary or not? <span class="text-danger">*</span></strong>
                            </div>

                            <ul class="survey-lists-radio">
                                <li>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="survey-2-3-1" name="survey-2-3-1" class="custom-control-input" value="1" v-model="answers[1].answers[2].answer">
                                        <label for="survey-2-3-1" class="custom-control-label">ไม่จำเป็น (No)</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="survey-2-3-2" name="survey-2-3-2" class="custom-control-input" value="2" v-model="answers[1].answers[2].answer">
                                        <label for="survey-2-3-2" class="custom-control-label">จำเป็น (Yes)</label>
                                    </div>
                                </li>
                            </ul>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>


                        <div id="answer-1-3" class="mb-4">
                            <div class="mb-2">
                                <strong>4. เมื่อพูดถึง "พิพิธภัณฑ์" ท่านนึกถึงอะไรเป็นอันดับแรก / What comes to your mind when talking about "museums" first? <span class="text-danger">*</span></strong>
                            </div>

                            <textarea class="form-control" rows="2" v-model="answers[1].answers[3].answer"></textarea>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>


                        <div id="answer-1-4" class="mb-4">
                            <div class="mb-2">
                                <strong>5. อะไรที่ทำให้ท่านอยากไปพิพิธภัณฑ์ (เลือกได้มากกว่า 1 รายการ) / What made you want to go to the museum? (You can choose more than one answer) <span class="text-danger">*</span></strong>
                            </div>

                            <ul class="survey-lists-radio">
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-4-1" class="custom-control-input" value="1" v-model="answers[1].answers[4].answer">
                                        <label for="survey-2-4-1" class="custom-control-label">กิจกรรมที่หลากหลายในพิพิธภัณฑ์ หรือเทศกาลอีเว้นท์ต่างๆ / Various activities in the museum or festival events</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-4-2" class="custom-control-input" value="2" v-model="answers[1].answers[4].answer">
                                        <label for="survey-2-4-2" class="custom-control-label">ประสบการณ์ใหม่ๆ กับเนื้อหาที่อัปเดท ทันสมัย / Get new experiences & up-to-date knowledge</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-4-3" class="custom-control-input" value="3" v-model="answers[1].answers[4].answer">
                                        <label for="survey-2-4-3" class="custom-control-label">มีกิจกรรมอบรมพัฒนาความรู้ให้ครู นักเรียนฟรี / Get free training activities to develop knowledge for teachers and students.</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-4-4" class="custom-control-input" value="4" v-model="answers[1].answers[4].answer">
                                        <label for="survey-2-4-4" class="custom-control-label">ได้ทำสิ่งประดิษฐ์ มีชิ้นงานกลับบ้าน / hands-on invention & get something back home. </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-4-5" class="custom-control-input" value="5" v-model="answers[1].answers[4].answer">
                                        <label for="survey-2-4-5" class="custom-control-label">ได้พบปะผู้คน เจอเพื่อนใหม่ ที่มีความสนใจเรื่องราวเดียวกัน  / meet people, make new friends who are interested in the same story </label>
                                    </div>
                                </li>

                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-4-6" class="custom-control-input" value="6" v-model="answers[1].answers[4].answer">
                                        <label for="survey-2-4-6" class="custom-control-label">ราคาค่าเข้าชมเหมาะสม / The entrance fee is reasonable. </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-4-7" class="custom-control-input" value="7" v-model="answers[1].answers[4].answer">
                                        <label for="survey-2-4-7" class="custom-control-label">การเดินทางสะดวก เช่น มีบริการรับ-ส่ง ส่วนลด / Convenient transportation, such as a transfer service, a discount </label>
                                    </div>
                                </li>
                                <li>
                                    

                                    <div class="d-flex align-items-center">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="survey-2-4-8" name="survey-like-0" class="custom-control-input" value="8" v-model="answers[1].answers[4].answer">
                                            <label for="survey-2-4-8" class="custom-control-label">อื่นๆ:</label>
                                        </div>

                                        <div class="flex-fill ml-2">
                                            <input type="text" class="form-control input-note" v-model="answers[1].answers[4].note">
                                        </div>

                                    </div>

                                </li>
                            </ul>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>

                        <div id="answer-1-5" class="mb-4">
                            <div class="mb-2">
                                <strong>6. ท่านคิดว่า ในยุค New Normal พิพิธภัณฑ์วิทยาศาสตร์ ควรปรับตัวอย่างไร / Do you think that in the New Normal era, how should the science museums adjust/adapt themselves during or after this era? <span class="text-danger">*</span> </strong>
                            </div>

                            <textarea class="form-control" rows="2" v-model="answers[1].answers[5].answer"></textarea>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>

                        <div id="answer-1-6" class="mb-4">
                            <div class="mb-2">
                                <strong>7. ท่านต้องการพัฒนาองค์ความรู้ด้านวิทยาศาสาตร์ด้วยวิธีการใด? (เลือกได้มากกว่า 1 รายการ) / How would you like to develop scientific knowledge for yourself? (You can choose more than one answer)<span class="text-danger">*</span></strong>
                            </div>

                            <ul class="survey-lists-radio">
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-1" class="custom-control-input" value="1" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-1" class="custom-control-label">การประชุมเชิงปฏิบัติการ / Workshops</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-2" class="custom-control-input" value="2" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-2" class="custom-control-label">การฟังบรรยาย / Lecture</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-3" class="custom-control-input" value="3" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-3" class="custom-control-label">การสัมมนาแลกเปลี่ยนเรียนรู้  / Sharing knowledge through seminars </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-4" class="custom-control-input" value="4" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-4" class="custom-control-label">การศึกษาด้วยตนเอง/รับชมรายการต่างๆ ผ่านระบบออนไลน์ / Self-study with online resources learning </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-5" class="custom-control-input" value="5" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-5" class="custom-control-label">การศึกษาต่อในระดับที่สูงขึ้น / studying in higher level of education</label>
                                    </div>
                                </li>

                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-6" class="custom-control-input" value="6" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-6" class="custom-control-label">การเรียนรู้ร่วมกันผ่านระบบออนไลน์ /online meeting with colleques or friends </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-7" class="custom-control-input" value="7" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-7" class="custom-control-label">การประกวดผลงาน / academic competition or award </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-2-6-8" class="custom-control-input" value="8" v-model="answers[1].answers[6].answer">
                                        <label for="survey-2-6-8" class="custom-control-label">ทัศนศึกษา เที่ยวพิพิธภัณฑ์ หรือแหล่งเรียนรู้นอกสถานที่ / outdoor excursion, study trip</label>
                                    </div>
                                </li>
                                <li>
                                     <div class="d-flex align-items-center">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="survey-2-6-9" class="custom-control-input" value="9" v-model="answers[1].answers[4].answer">
                                            <label for="survey-2-6-9" class="custom-control-label">อื่นๆ:</label>
                                        </div>

                                        <div class="flex-fill ml-2">
                                            <input type="text" class="form-control input-note" v-model="answers[1].answers[6].note">
                                        </div>

                                    </div>

                                </li>
                            </ul>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>
                    </div>
                </div>


                <!-- Part 3 -->
                <div class="card mb-4">
                    <div class="card-header">
                        <div><strong>Part 3</strong> - ความตระหนักรู้และการรับรู้ที่มีต่อแหล่งเรียนรู้วิทยาศาสตร์ องค์การพิพิธภัณฑ์วิทยาศาสตร์แห่งชาติ (อพวช.)</div>
                        <div>Awareness and Perception of National Science Museum (NSM)</div>
                    </div>

                    <div class="card-body">
                        <div id="answer-2-0" class="mb-4">
                            <div class="mb-2">
                                <strong>1. ท่านรู้จักองค์การพิพิธภัณฑ์วิทยาศาสตร์แห่งชาติหรือไม่ / Do you know the National Science Museum (NSM) in Thailand? <span class="text-danger">*</span></strong>
                            </div>

                            <ul class="survey-lists-radio">
                                <li>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="survey-3-1-1" class="custom-control-input" value="1" v-model="answers[2].answers[0].answer">
                                        <label for="survey-3-1-1" class="custom-control-label">รู้จัก (Yes)</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="survey-3-1-0" class="custom-control-input" value="0" v-model="answers[2].answers[0].answer">
                                        <label for="survey-3-1-0" class="custom-control-label">ไม่รู้จัก (กรุณาข้ามไปตอบ ข้อ 5) / If no, skip to item 5.</label>
                                    </div>
                                </li>
                            </ul>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>


                        <div id="answer-2-1" class="mb-4">
                            <div class="mb-2">
                                <strong>2. เมื่อพูดถึง "อพวช. (องค์การพิพิธภัณฑ์วิทยาศาสตร์แห่งชาติ)" ท่านนึกถึงอะไรเป็นอันดับแรก / What come up with your mind first when talking about NSM : National Science Museum? <span class="text-danger" v-if="answers[2].answers[0].answer==1">*</span></strong>
                            </div>

                            <textarea class="form-control" rows="2" v-model="answers[2].answers[1].answer"></textarea>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>

                        <div id="answer-2-2" class="mb-4">
                            <div class="mb-2">
                                <strong>3. ท่านรู้จักองค์การพิพิธภัณฑ์วิทยาศาสตร์แห่งชาติ (อพวช.) ได้อย่างไร / What kinds of channels that you know the NSM : National Science Museum?<span class="text-danger" v-if="answers[2].answers[0].answer==1">*</span></strong>
                            </div>

                            <ul class="survey-lists-radio">
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-3-3-1" class="custom-control-input" value="1" v-model="answers[2].answers[2].answer">
                                        <label for="survey-3-3-1" class="custom-control-label">เพื่อน / Friends</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-3-3-2" class="custom-control-input" value="2" v-model="answers[2].answers[2].answer">
                                        <label for="survey-3-3-2" class="custom-control-label">เฟสบุคของหน่วยงาน / NSM Facebook Page</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-3-3-3" class="custom-control-input" value="3" v-model="answers[2].answers[2].answer">
                                        <label for="survey-3-3-3" class="custom-control-label">เว็บไซด์ของ อพวช. องค์การพิพิธภัณฑ์วิทยาศาสตร์แห่งชาติ / NSM Website</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-3-3-4" class="custom-control-input" value="4" v-model="answers[2].answers[2].answer">
                                        <label for="survey-3-3-4" class="custom-control-label">การประชาสัมพันธ์ของหน่วยงาน เช่น ทีวี/ PR of NSM, TV</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="survey-3-3-5" class="custom-control-input" value="5" v-model="answers[2].answers[2].answer">
                                        <label for="survey-3-3-5" class="custom-control-label">งาน EDUCA / EDUCA</label>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="survey-3-3-6" class="custom-control-input" value="6" v-model="answers[2].answers[2].answer">
                                            <label for="survey-3-3-6" class="custom-control-label">อื่นๆ:</label>
                                        </div>

                                        <div class="flex-fill ml-2">
                                            <input type="text" class="form-control input-note" v-model="answers[2].answers[2].note">
                                        </div>

                                    </div>
                                </li>
                            </ul>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>


                        <div id="answer-2-3" class="mb-4">
                            <div class="mb-2">
                                <strong>4. ท่านพึงพอใจต่อองค์การพิพิธภัณฑ์วิทยาศาสตร์แห่งชาติ (อพวช.) ในเรื่องดังต่อไปนี้ในระดับใด / To what level are you satisfied with the National Science Museum (NSM) on the following matters? <span class="text-danger" v-if="answers[2].answers[0].answer==1">*</span></strong>
                            </div>

                            <table class="card-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="td-check">น้อยที่สุด (Least)</th>
                                        <th class="td-check">น้อย</th>
                                        <th class="td-check">ปานกลาง</th>
                                        <th class="td-check">มาก</th>
                                        <th class="td-check">มากที่สุด (Most)</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(question,i) in part3Question5" :key="i">
                                        <td class="td-name">{{question.name}}</td>

                                        <td class="td-check" v-for="(item, index) in question.options" :key="index">
                                            <div class="custom-control custom-radio">

                                                <input type="radio" :id="`survey-3-5-${i}-${index}`" :value="index" class="custom-control-input" :name="`survey-3-5-${i}`" v-model="answers[2].answers[3].answer[i]">

                                                <label :for="`survey-3-5-${i}-${index}`" class="custom-control-label">
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                        </div>

                        <div id="answer-2-4" class="mb-4">
                            <div class="mb-2">
                                <strong>5. ท่านต้องการให้ องค์การพิพิธภัณฑ์วิทยาศาสตร์แห่งชาติ (อพวช.) ในฐานะหน่วยงานส่งเสริมองค์ความรู้วิทยาศาสตร์ผ่านแหล่งเรียนรู้ต่างๆ เพิ่มเติมในเรื่องใด / As a teacher or educator in Thailand, please give any recommendations toward the National Science Museum (NSM) which is an public agency that raises public awareness of science for Thai society & promotes scientific knowledge through various learning resources <span class="text-danger">*</span></strong>
                            </div>
                            <textarea class="form-control" rows="2" v-model="answers[2].answers[4].answer"></textarea>

                            <div class="invalid-feedback">จำเป็นต้องตอบคำถามนี้</div>
                            
                        </div>
                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-center py-5">
                <!-- <a href="/educa2021/account/course" class="btn btn-light btn-lg min-w-120 font-weight-bold mr-5 text-white">Cancel</a> -->
                <button type="submit" class="btn btn-primary btn-submit btn-lg min-w-120 font-weight-bold" :class="[loading && 'loading', hasError && 'btn-error']" @click.prevent="save">
                    <span>Save</span>
                    <div class="loader" v-if="loading"><div></div><div></div><div></div></div>
                </button>
            </div>

            <div class="btn-submit form-overlay" v-if="loading">
                <div class="loader"><div></div><div></div><div></div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['user', 'isChangeName', 'next' ,'event'],
    data(){

        return {

            prefixs: [
                {name: 'นาย', name_en: 'Mr.'},
                {name: 'นาง', name_en: 'Mrs.'},
                {name: 'นางสาว', name_en: 'Ms.'},
                {name: 'อื่นๆ', name_en: 'Other'},
            ],
            showform : false ,
            member: {
                prefix: '',
                prefix_custom: '',
                last_name: '',
                first_name: '',

                prefix_en: '',
                prefix_en_custom: '',
                first_name_en: '',
                last_name_en: '',
                event_id : ''
            },

            errors: {},

            loading: false,
            hasError: false,

            survey: {
                like: null,

                habits: [],

                note: '',
            },

            habits: [
                {id: 1, name: 'สายบิวตี้ (Beautiful)'},
                {id: 2, name: 'สายกิน เช็กอินทุกร้านเด็ด (Eating)'},
                {id: 3, name: 'สายสุขภาพ อาหารคลีน วิ่งมาราธอน ต้องมีเรา (Eating)'},
                {id: 4, name: 'สายเที่ยว ไปทุกทริปที่เพื่อนชวน (Traveling)'},
                {id: 5, name: 'สายช้อปปิ้ง ลดราคาที่ไหน เจอครูที่นั่น (Big Sales Shopping)'},
                {id: 6, name: 'สายบุญ บุญนำพา ทุกต้นเดือน (Meditation)'},
                {id: 7, name: 'สายเฮฮา ปาร์ตี้  (Party lover)'},
                {id: 8, name: 'สายเม้าท์มอย รู้ทุกเรื่องต้องทางนี้ (Gossip)'},
                {id: 9, name: 'สายชอบอ่าน (Bookworm)'},
                {id: 10, name: 'สายติ่งซีรีส์ ดูจนตาแฉะ (Korean film or Series lover)'},
                {id: 11, name: 'สายมิวสิก ทั้งร้องและฟังตัวเอง (Music)'},
                {id: 12, name: 'สายแดนซ์ K-Pop/หมอลำ (Dancing)'},
                {id: 13, name: 'สายคุกกิ้ง Master Chief  (Cooking)'},
                {id: 14, name: 'สายสะสม (เช่น เหรียญ แสตมป์ ของโบราณ) (Collectors; stamp, coin)'},
                {id: 15, name: 'สายรักษ์โลก  (Environmental-eco concerned)'},
                {id: 16, name: 'สายมู วันนี้ใส่เสื้อสีอะไรดี  (Horoscope, supernatural lover)'},
                {id: 17, name: 'สายไฮเทค โหลดทุกแอป แต่ใช้ประจำแอปเดียว (Hi-tech savvy)'},
                {id: 18, name: 'สาย Adventure ผจญภัยได้ทุกที่ ดำน้ำ ดูปะการัง ปีนเขา ยิงปืน (Adventure lover)'},
            ],

            part1Question1: [
                {
                    name: 'การประชาสัมพันธ์ข้อมูลข่าวสาร (Content and Event PR)',
                    options: [4,3,2,1]
                },
                {
                    name: 'ระบบการลงทะเบียนทางเว็บไซต์ (Registration)',
                    options: [4,3,2,1]
                },
                {
                    name: 'การให้บริการและตอบคำถามของผู้จัด (Q&A Service)',
                    options: [4,3,2,1]
                },
                {
                    name: 'ความเหมาะสมของแพลตฟอร์ม (EDUCA Platform)',
                    options: [4,3,2,1]
                },
                {
                    name: 'ความเหมาะสมของเนื้อหาเวิร์กช็อป (Workshop Content)',
                    options: [4,3,2,1]
                }
            ],

            part3Question5: [
                {
                    name: 'ความสะดวกในการเข้าพิพิธภัณฑ์ /ใช้งานเว็บไซด entry to the museum /use website/social media during covid',
                    options: [{},{},{},{},{}]
                },
                {
                    name: 'ความหลากหลายของกิจกรรมแต่ละพิพิธภัณฑ์ย่อย / a variety of activities for each sub-museum',
                    options: [{},{},{},{},{}]
                },
                {
                    name: 'ความทันสมัยของการออกแบบและประสบการณ์ใหม่ๆ / Modern design and new experiences',
                    options: [{},{},{},{},{}]
                },
                {
                    name: 'ความครบถ้วน และความเหมาะสมของเนื้อหา และกิจกรรมที่ใช้สำหรับเรียนการสอน / completeness and suitability of content and activities used for teaching',
                    options: [{},{},{},{},{}]
                },
                {
                    name: 'ความเหมาะสมของภาษาที่ใช้ (ชัดเจน กระชับ และเข้าใจง่าย) / appropriateness of the language used (clear, concise and easy to understand)',
                    options: [{},{},{},{},{}]
                },
                {
                    name: 'ความเชี่ยวชาญของวิทยากรเจ้าหน้าที่ / Expertise of NSM staffs & speakers',
                    options: [{},{},{},{},{}]
                },
                {
                    name: 'ความสะอาดของสถานที่ / Cleanliness',
                    options: [{},{},{},{},{}]
                
                },
                {
                    name: 'ความเหมาะสมของค่าเข้าชม / Appropriate ticket fee',
                    options: [{},{},{},{},{}]
                },
                {
                    name: 'ความพึงพอใจในภาพรวมต่อ อพวช. ในระดับใด / At what level is the overall satisfaction towards NSM?',
                    options: [{},{},{},{},{}]
                },
            ],

            answers: [
                { 
                    part: 1,
                    answers: [
                        {answer: []},
                        {answer: null},
                        {answer: null, note: null},
                        {answer: null},
                    ]
                },
                { 
                    part: 2,
                    answers: [
                        {answer: null},
                        {answer: [], note: null},
                        {answer: null},
                        {answer: null},
                        {answer: [], note: null},
                        {answer: null},
                        {answer: [], note: null},
                    ]
                },
                { 
                    part: 3,
                    answers: [
                        {answer: null},
                        {answer: null},
                        {answer: [], note: null},
                        {answer: []},
                        {answer: null},
                    ]
                }
            ],

            errorAnswers: {}
        }
    },

    created(){
        this.member = this.user

        const prefix = this.prefixs.find(n=>n.name==this.user.prefix)
        if( !prefix ){
            this.member.prefix_custom = this.user.prefix
            this.member.prefix = 'อื่นๆ'
        }

        const prefix_en = this.prefixs.find(n=>n.name_en==this.user.prefix_en)
        if( !prefix_en ){
            this.member.prefix_en_custom = this.user.prefix_en
            this.member.prefix_en = 'Other'
        }

        this.redirect = !this.next ? '/account': this.next;

        // this.isChangeName = this.isChangeName ? true: false;

        // console.log( this.isChangeName, this.next )
    },

    computed: {
        isPrefixCustom(){

            if( this.member.prefix!=='' ){
                const prefix = this.prefixs.find(n=>n.name==this.member.prefix)

                if( !prefix ){
                    return true;
                }

                if( this.member.prefix=='อื่นๆ' ){
                    return true;
                }
            }

            return false;
        },

        isPrefixCustomEn(){

            if( this.member.prefix_en!=='' ){
                const prefix = this.prefixs.find(n=>n.name_en==this.member.prefix_en)

                if( !prefix ){
                    return true;
                }

                if( this.member.prefix_en=='Other' ){
                    return true;
                }
            }

            return false;
        }
    },

    mounted(){

        // console.log( this.answers );

        this.answers.forEach((part,p) => {
            part.answers.forEach((ans,q) => {
                this.errorAnswers[`answer${p}_${q}`] = 0;
            });
        });


        // console.log( this.errorAnswers );
    },

    methods: {
        getMember(){
            // axios.get("/apis/members/");
        },

        validate(){
            let validated = true;
            $(`.is-invalid`).removeClass('is-invalid');

            var part3_1 = "";
            var part3_4 = "";

            this.answers.forEach((part,p) => {
                
                part.answers.forEach((ans,q) => {
                    
                    if(p==0 && q==0){
                        if( ans.answer.length!=5 ) {
                            $(`#answer-${p}-${q}`).addClass('is-invalid')
                            validated = false;
                        }
                    }

                    if(p==0 && q==1 && !ans.answer){
                        $(`#answer-${p}-${q}`).addClass('is-invalid')
                        validated = false;
                    }

                    if(p==0 && q==2 ){
                        if( !ans.answer || (ans.answer==3 && !ans.note) ){
                            $(`#answer-${p}-${q}`).addClass('is-invalid')
                            validated = false;
                        }
                    }

                    if(p==0 && q==3 && !ans.answer ){
                        $(`#answer-${p}-${q}`).addClass('is-invalid')
                        validated = false;
                    }



                    if(p==1 && q==0 && !ans.answer){
                        $(`#answer-${p}-${q}`).addClass('is-invalid')
                        validated = false;
                    }

                    if(p==1 && q==1 ){
                        if( ans.answer.length == 0 ){
                            $(`#answer-${p}-${q}`).addClass('is-invalid')
                            validated = false;
                        }
                    }

                    if(p==1 && q==2 && !ans.answer){
                        $(`#answer-${p}-${q}`).addClass('is-invalid')
                        validated = false;
                    }

                    if(p==1 && q==3 && !ans.answer){
                        $(`#answer-${p}-${q}`).addClass('is-invalid')
                        validated = false;
                    }

                    if(p==1 && q==4 ){
                        if( ans.answer.length == 0 ){
                            $(`#answer-${p}-${q}`).addClass('is-invalid')
                            validated = false;
                        }
                    }

                    if(p==1 && q==5 && !ans.answer){
                        $(`#answer-${p}-${q}`).addClass('is-invalid')
                        validated = false;
                    }

                    if(p==1 && q==6 ){

                        if( ans.answer.length == 0 ){
                            $(`#answer-${p}-${q}`).addClass('is-invalid')
                            validated = false;
                        }
                    }

                    
                    if( p==2 ){

                        if( q==0 ){
                            part3_1 = ans.answer
                        }

                        if( q==3 ){
                            part3_4 = ans.answer
                        }

                        if(q==0 && !ans.answer){
                            $(`#answer-${p}-${q}`).addClass('is-invalid')
                            validated = false;
                        }
                        

                        if(part3_1==1 && q==1 && !ans.answer){
                            $(`#answer-${p}-${q}`).addClass('is-invalid')
                            validated = false;
                        }

                        if(part3_1==1 && q==2 ){
                            if( ans.answer.length==0 ){
                                $(`#answer-${p}-${q}`).addClass('is-invalid')
                                validated = false;
                            }
                        }

                        // if(part3_1==1 && q==3 && !ans.answer){
                        //     $(`#answer-${p}-${q}`).addClass('is-invalid')
                        //     validated = false;
                        // }


                        if(part3_1==1 && q==3 ){
                            if( ans.answer.length!=9 ){
                                $(`#answer-${p}-${q}`).addClass('is-invalid')
                                validated = false;
                            }
                        }
                    }


                    if(p==2 && q==4 && !ans.answer){
                        $(`#answer-${p}-${q}`).addClass('is-invalid')
                        validated = false;
                    }
                });
            });


            if( !validated ){
                // console.log( $('.invalid-feedback')[0] );
                $(`.is-invalid`)[0].scrollIntoView({behavior: "smooth"});
                
            }

            return validated;
        },
        save(){
            // console.log( this.answers );

            setTimeout(() => {
            
                //if( this.validate() ){
                    const vm = this
                    vm.loading = true;
                    vm.hasError = false;

                    if( !this.isChangeName ){

                        vm.loading = false;

                        vm.$swal(
                            'เกิดข้อผิดพลาด!',
                            'ไม่สามาเปลี่ยนชื่อได้, เนื่องจากปีนี้คุณได้ทำการเปลี่ยนชื่อแล้ว!',
                            'info'
                        );

                        return false;
                    }
                    
                    // console.log({member: vm.member, survey: vm.survey});
                    // vm.member.survey_like = vm.survey.like
                    // vm.member.survey_habits = vm.survey.habits
                    // vm.member.survey_note = vm.survey.note

                    vm.member.answers = this.answers
                    vm.member.event_id = this.event.id

                    vm.$swal({
                        title: 'ยืนยันการแก้ไขชื่อ?',
                        text: 'ท่านสามารถแก้ไขได้เพียงครั้งเดียวเท่านั้น !',
                        icon: 'info',
                        // showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: `ยืนยัน`,
                        cancelButtonText: `ยกเลิก`,

                       
                    }).then((result) => {

                        if (result.isConfirmed) {

                            axios.put("/api/event/settings/name", vm.member)
                            .then(response => {
                                const data = response.data

                                // console.log( response.status );
                                if( response.status == 200 ){
                                    vm.$swal( data ).then(() => {

                                        window.location.href = vm.redirect;
                                    });
                                }
                                else{
                                    vm.$swal(
                                        'เกิดข้อผิดพลาด!',
                                        'กรุณาลองใหม่อีกครั้ง!',
                                        'error'
                                    );

                                    vm.loading = false;
                                }

                            })
                            .catch(error => {
                                vm.loading = false;

                                if( error.response ){
                                    const data = error.response.data

                                    if( data.errors ){

                                        vm.setError( data.errors ).then(res=>{

                                        });
                                    }
                                }

                                vm.hasError = true;
                                // console.log( 'error', error.response );
                            })

                        }
                        else{
                            vm.loading = false;
                        }

                    });
                //}
            }, 1);
           
            
        },

        setError( errors ){
            const vm = this;
            return new Promise((resolve, reject) => {
                $.each(errors, function (name, message) {

                    vm.errors[ name ] = message[0];
                    // console.log( name, message );
                });

                resolve();
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .box-card{
        background-color: #ecefff;
        padding: 20px;
        border-radius: 15px
    }

    .card-table{
        width: 100%;

        th,td{
            border: 1px solid #ccc;
            padding: 2px 4px;
        }

        .td-check{
            width: 100px;
            text-align: center;
        }
    }

    .survey-lists-radio{
        list-style-type: none;
        margin-left: 0;
        padding: 0;
        
        li{
            margin-bottom: 8px;
        }
    }


    .custom-checkbox .custom-control-label::before{
        border-radius: 3px
    }
    .input-note{
        border-radius: 0;
        padding: 0;
        border-width: 0 0 1px;
        height: auto;
        box-shadow: none;
    }

    .is-invalid .invalid-feedback{
        display: block;
    }
</style>