<template>
    <div class="video-player-wrap">
        <div class="post-image aspect-ratio aspect-ratio-1 video-thumbnail rounded-0">
            <img class="lazyload image" :src="thumbnail" :data-src="imageUrl" alt="">

            <div class="video-player" :class="playerReady&&`ready`" v-if="startPlayer">
                <ByteArk ref="video" :video-src="videoSrc" :video-id="vimeoId" :options="playerOps" @ready="onReady" @play="onPlay"
                    @pause="onPause" @ended="onEnded" @progress="onProgress" @loaded="onLoaded" @error="onError"
                    @cuechange="onCuechange" @cuepoint="onCuepoint" @duration="onDuration" @timeupdate="onTimeupdate"
                    @durationchange="onDurationchange"></ByteArk>
            </div>

            <div class="player-controls" v-if="!playerReady">

                <div class="player-loading" v-if="loadPlayer">
                    <span>กำลังโหลดวีดีโอ...</span>
                </div>

                <div v-else class="video-player--center-button">
                    <button v-if="!startPlayer" type="button" aria-label="เล่นวิดีโอ" @click="getVideo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24">
                            <path d="M7 6v12l10-6z"></path>
                        </svg>
                    </button>
                </div>
            </div>

            <div class="video-player-error" v-if="isError">
                <div class="code">Error {{ errors.status }}</div>
                <div class="message">{{ errors.message }}</div>

                <div class="mt-2">
                    <button type="button" @click="btnLogin" v-if="errors.status==401"
                        class="btn btn-primary min-w-120 mr-1">เข้าสู่ระบบ</button>
                    <button type="button" @click="tryAgain" :disabled="isLoad"
                        class="btn btn-primary min-w-120">ลองใหม่</button>
                </div>
            </div>

        </div>


        <div ref="control" class="player-controls-bar row no-gutters" v-if="!isError">
            <div class="col-auto mr-3" v-if="1==2">
                <button type="button" class="btn btn-play w-min-65" @click="setPlayer">

                    <div class="play-icon" v-if="!playerRunning">
                        <svg viewBox="0 0 20 20" preserveAspectRatio="xMidYMid" focusable="false"
                            aria-labelledby="play-icon-title" role="img">
                            <title id="play-icon-title">Play</title>
                            <polygon class="fill" points="1,0 20,10 1,20"></polygon>
                        </svg>
                    </div>

                    <div v-else class="pause-icon">
                        <svg viewBox="0 0 20 20" focusable="false" aria-labelledby="pause-icon-title" role="img">
                            <title id="pause-icon-title">Pause</title>
                            <rect class="fill" width="6" height="20" x="0" y="0"></rect>
                            <rect class="fill" width="6" height="20" x="12" y="0"></rect>
                        </svg>
                    </div>

                    <!-- <div class="replay-icon"><svg viewBox="-387 605 16 16" aria-labelledby="replay-icon-title" role="img"><title id="replay-icon-title">Play</title><path class="fill" d="M-387 606v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1s-.4-1-1-1h-1.5c1.1-1.2 2.7-2 4.5-2 3.3 0 6 2.7 6 6s-2.7 6-6 6c-2.3 0-4.4-1.3-5.4-3.4-.2-.5-.8-.7-1.3-.5-.5.2-.7.8-.5 1.3 1.3 2.8 4.2 4.6 7.2 4.6 4.4 0 8-3.6 8-8s-3.6-8-8-8c-2.3 0-4.5 1-6 2.7V606c0-.6-.4-1-1-1s-1 .4-1 1z"></path></svg></div> -->

                </button>
            </div>

            <div class="col">
                <div class="position-relative fs-13">
                    <div class="d-md-flex mb-1">

                        <div>
                            <span class="mr-1">รับชมแล้ว</span>
                            <span>{{watch_time_text}}</span>
                            <span>({{watch_time_percent}}%)</span>
                        </div>

                        <span class="mx-1 d-none d-md-block">/</span>

                        <div class="mr-2 d-flex">

                            <span>
                                เกณฑ์ผ่าน
                                <span>{{ criteria_watch_text }}</span>
                                <span>({{criteriaWatchPercent}}%)</span>
                            </span>

                            <span class="ml-2 d-flex align-items-center bg-success video-status"
                                :class="isVerified&&`active`">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                    <path
                                        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z">
                                    </path>
                                    <path
                                        d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z">
                                    </path>
                                </svg>
                                <span class="ml-1">ผ่านแล้ว</span>
                            </span>
                        </div>
                    </div>

                    <div class="position-relative">
                        <div class="progress video-progress">
                            <div class="progress-bar" role="progressbar" :style="`width:${progressbar}%`"
                                :aria-valuenow="progressbar" aria-valuemin="0" aria-valuemax="100"></div>

                            <div class="video-progress-success" v-if="watch_success_percent>0"
                                :style="`width:${watch_success_percent}%`"></div>
                        </div>

                        <!--  @click="progressBarClick" -->
                        <div class="video-progress-bar" v-if="playerReady" @mousemove="progressBarMove">
                            <div class="video-progress-bar--pointer" :style="pointerBarStyle">{{ pointer_bar }}</div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-auto ml-md-3" v-if="1==2">
                <button type="button" class="btn btn-play" @click="toggleFullscreen">

                    <div class="unfullscreen-icon" v-if="isFullscreen"><svg viewBox="0 0 12 12"
                            preserveAspectRatio="xMidYMid" focusable="false" aria-labelledby="unfullscreen-icon-title"
                            role="img">
                            <title id="unfullscreen-icon-title">Exit full screen</title>
                            <polyline class="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1"
                                transform="translate(6,6) "></polyline>
                            <polyline class="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1"
                                transform="translate(6,6) rotate(90)"></polyline>
                            <polyline class="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1"
                                transform="translate(6,6) rotate(180)"></polyline>
                            <polyline class="fill" points="-1,-1 -1.1,-5 -2.1,-4 -4.1,-6 -6,-4.1 -4,-2.1 -5,-1.1"
                                transform="translate(6,6) rotate(270)"></polyline>
                        </svg></div>

                    <div class="fullscreen-icon" v-else><svg viewBox="0 0 12 12" preserveAspectRatio="xMidYMid"
                            focusable="false" aria-labelledby="fullscreen-icon-title" role="img">
                            <title id="fullscreen-icon-title">Enter full screen</title>
                            <polyline class="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9"
                                transform="translate(6,6)"></polyline>
                            <polyline class="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9"
                                transform="translate(6,6) rotate(90)"></polyline>
                            <polyline class="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9"
                                transform="translate(6,6) rotate(180)"></polyline>
                            <polyline class="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9"
                                transform="translate(6,6) rotate(270)"></polyline>
                        </svg></div>

                </button>
            </div>

            <div class="col-md-auto video-footer-rigth ml-md-2 mt-2 mt-md-0">
                <button type="button" class="btn btn-play" :disabled="!isVerified" @click="donwloadCertificate"
                    v-if="downloadCertificate">Download Certificate</button>

                <button type="button" class="btn btn-info" :disabled="!isVerified" @click="donwloadSurvey"
                    v-if="survey"><span class="text-white">แบบประเมิน</span></button>
                <button type="button" class="btn btn-info" :disabled="!isVerified" @click="activeSurveyGoogleForm"
                    v-else-if="checkSurveyGoogleForm"><span class="text-white">แบบประเมิน</span></button>
            </div>

        </div>

        <div v-if="debug" class="p-3 alert-danger">
            Debug:
            <div>loading <span v-if="isLoad">...</span></div>
            <div>
                Error<span v-if="isError">...</span> : {{ errors.status }}
            </div>
            <div>playerReady <span v-if="playerReady">...</span></div>
            <div>loaded <span v-if="playerLoaded">...</span></div>
            <div>debugLoadVideo {{ debugLoadVideo ? 1 : 0 }}</div>
            <div>isUpdate {{ isUpdate ? 1 : 0 }}</div>
            <div>debugUpdateVideo {{ debugUpdateVideo }}</div>
            <div>debugError {{ debugError }}</div>
        </div>
    </div>
</template>

<script>

import app from "../../firebase"
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
//import ByteArk from "./ByteArk/index.vue";

// Initialize Cloud Firestore and get a reference to the service
const firebaseDatabase = getFirestore(app);

export default {
    props: [
        "video",
        "videoId",
        "learnId",
        "thumbnail",
        "imageUrl",
        "vimeoId",
        "ariaWatch",
        "downloadCertificate",
        "survey",
        "eventName",
        'event',
        "surveyGoogleForm",
        "videoSrc"  
    ],

    computed: {
        criteriaWatchPercent() {
            return this.criteria_watch_percent || 0
        },

        checkSurveyGoogleForm() {
            return this.surveyGoogleForm?.link || null
        }
    },

    data() {
        return {
            debug: false,
             
            isLoad: false,
            isError: false,
            isUpdate: false,
            startPlayer: false,
            errors: {
                status: "",
                message: '',
            },

            loadPlayer: false,

            playerReady: false,
            playerLoaded: false,

            debugLoadVideo: false,
            debugUpdateVideo: false,
            debugError: false,

            fullscreen: false,
            $iframe: null,
            iframe_width: null,
            iframe_height: null,

            windowWidth: window.innerWidth,

            playerOps: {
                autoplay: true,

                buttons: {
                    like: false,
                    watchlater: false,
                    share: false,
                    embed: false,
                    hd: false,
                    fullscreen: true,
                    scaling: false
                },

                controls: true,
            },

            watchTime: 0,
            watch_time_percent: 0,
            watch_time_text: "00:00",

            timeupdate: 0,
            isTimeupdate: false,
            loaded: false,

            dataWatch: {
                duration: 0,
                seconds: 0,
                percent: 0,
                total_seconds: 0,
                total_second_percent: 0
            },

            progressbar: 0,
            seconds: "00",
            minutes: "00",

            width: 640,
            height: 320,

            // player
            playerRunning: false,
            playerError: false,

            isFullscreen: false,

            criteria_watch_percent: 0,
            criteria_watch_text: '00:00',
            styleCriteriaWatchPercent: {},
            criteria_watch_text_style: {},
            watch_success_percent: 0,

            pointerSeconds: "00:00",
            pointer_bar: "00:00",
            pointerBarStyle: {},

            _play: null,
            dueSecondUpdateData: 5, //10 วิ  
            countSecondUpdateData: 0,

            isVerified: false,
            isCloseWindow: false,

            interval: null,
            watch: null,
        };
    },

    created() {
        window.addEventListener("beforeunload", this.beforePageDestroyed);
        window.addEventListener("resize", this.resizeHandler);

        if (this.video) {
            this.criteria_watch_text = this.video.criteria_watch_time
            this.criteria_watch_percent = this.video.criteria_watch_percent || 0

            this.dataWatch.duration = this.video.duration
        }

        if (this.ariaWatch) {
            this.beforePlayer(this.ariaWatch)
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);

        if (this.interval) {
            clearInterval(this.interval);
        }

    },

    watch: {
        watch_time_text() {

            if (this.criteria_watch_percent > 0 && this.dataWatch.total_second_percent >= this.criteria_watch_percent && !this.isVerified) {
                this.updateVerified()
            }

        }
    },

    async mounted() {
        this.resizeHandler();

        if (this.watch) {
            const response = await this.getWatchFormFrebase()
            if (response) {
                this.beforePlayer({ ...this.watch, ...response })
            }
            // console.log('getWatchFormFrebase', response);
        }


        console.log('surveyGoogleForm', this.surveyGoogleFormLink);

    },

    methods: {
        beforePlayer(payload) {
            this.watch = payload
            this.dataWatch.seconds = payload.seconds
            this.dataWatch.total_seconds = payload.total_seconds
            this.dataWatch.duration = payload.duration || this.video.duration

            // this.dataWatch.total_seconds = this.criteria_watch_percent
            // this.dataWatch.total_second_percent = this.criteria_watch_percent

            // console.log( this.dataWatch.seconds, this.dataWatch.duration );
            if (this.dataWatch.seconds >= this.dataWatch.duration) {
                this.dataWatch.seconds = 0;
            }

            if (payload.verified_at) {
                this.isVerified = true
            }

            this.setProgressbar({
                seconds: payload.seconds,
                percent: payload.percent,
            })
        },

        updateVerified() {

            this.isVerified = true;
            this.dataWatch.verified_at = new Date();
            this.updatePlayer('verified');
        },
        toggleTimer() {

            if (this.interval) {
                clearInterval(this.interval);
            }

            this.interval = setInterval(this.incrementTime, 1000);
        },
        incrementTime() {
            if (!this.playerRunning) return false;

            if (this.dataWatch.total_second_percent >= 100) {
                clearInterval(this.interval);
                return false;
            }

            this.dataWatch.total_seconds = parseInt(this.dataWatch.total_seconds) + 1;

            if (this.dueSecondUpdateData == this.countSecondUpdateData) {
                this.countSecondUpdateData = 0;
                this.updatePlayer();
            }
            else {
                this.countSecondUpdateData++;
            }
        },
        resizeHandler() {
            const thumbnail = this.$el.querySelector(".video-thumbnail");
            this.windowWidth = window.innerWidth;

            this.width = thumbnail.offsetWidth;
            this.height = thumbnail.offsetHeight;

            if (this.$iframe) {
                this.$iframe.width(this.width);
                this.$iframe.height(this.height);
            }
        },

        beforePageDestroyed(evt) {
            var evt = evt || window.event;
            // alert( '123 close' );
            // evt.preventDefault();
            // console.log( 'beforePageDestroyed', this.playerRunning, this.playerReady );

            // evt.returnValue = '';
            if (this.playerRunning && this.playerReady) {
                this.updatePlayer('close-window');

                if (evt) {
                    evt.returnValue = 'Are you sure?';
                }

                return 'Are you sure?';
            }
        },

        // Video
        async getVideo() {
            const vm = this;

            this.isError = false;
            this.isLoad = true;
            this.loadPlayer = true;

            await this.timeout(1300);
            try {
                const { data: response } = await axios.get(`/apis/video/${this.videoId}`)

                if (response.watch) {
                    // this.isVerified = response.watch.verified_at ? true : false
                }

                vm.debugLoadVideo = true
                this.isLoad = false;
                this.loadPlayer = false;

                if (this.startPlayer) {
                    this.play()
                }
                else {
                    this.callPlayer();
                }

            } catch (error) {
                vm.debugLoadVideo = false;
                this.isLoad = false;
                vm.loadPlayer = false;
                vm.isError = true;


                if (this.startPlayer) {
                    this.pause();
                }

                if (error.response) {
                    const res = error.response.data
                    vm.errors = res

                    if (!res.status) {
                        vm.errors.status = 500;
                    }

                    if (!res.message) {
                        vm.errors.message = "เกิดข้อผิดพลาด";
                    }
                }
                else {
                    vm.errors.status = 500;
                    vm.errors.message = "เกิดข้อผิดพลาด";
                }
            }
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        // player
        callPlayer() {
            this.startPlayer = true;
            this.loadPlayer = true;

            this.getVideo();
        },

        onReady() {
            const vm = this;
            this.loadPlayer = false;
            this.$iframe = $(this.$refs.video.$el).find(">iframe");
            this.resizeHandler();
        },
        onPlay() {
            if (this.isError) {
                this.onPause();
            }

            console.log( 'onPlay...' );
            this.playerRunning = true;

            this._playPlayer();
            this.toggleTimer();
        },
        onPause() {
            // console.log( 'onPause...' );

            if (this.interval) {
                clearInterval(this.interval);
            }

            if (this.playerRunning && !this.isError) {
                // console.log( 'onPause...', this.playerRunning );

                this.playerRunning = false;
                clearTimeout(this._play);

                if (this.watch) {
                    this.updatePlayer('onPause');
                }
            }

        },
        onEnded() {
            // console.log( 'onEnded...' );
            if (this.interval) {
                clearInterval(this.interval);
            }

            this.updatePlayer('onEnded');
        },
        onError(e) {
            // console.log( 'onError...', e );
            if (this.interval) {
                clearInterval(this.interval);
            }

            this.playerError = true;
        },
        onProgress(duration) {
            // console.log( 'onProgress...', duration );

        },
        onLoaded(evt) {
            // console.log( 'onLoaded...' );

            this.initPlayer();
        },
        onDuration(duration) {
            // console.log('onDuration', duration);
            this.dataWatch.duration = duration;
        },
        onCuechange() {
            // console.log( 'onCuechange...' );
        },
        onCuepoint() {
            // console.log( 'onCuepoint..' );
        },
        onTimeupdate(data) {
            // console.log( 'onTimeupdate..');

            this.dataWatch.seconds = data.seconds
            this.dataWatch.percent = data.percent

            this.setProgressbar(data)
        },

        onDurationchange(duration) {
            this.dataWatch.duration = duration.duration;
        },

        initPlayer() {
            const vm = this;
            if (!this.watch) {
                this.getWatch()
            }
            else {
                vm.playerReady = true;
                const lastSeconds = this.dataWatch.seconds || 0
                // console.log('lastSeconds ->', this.dataWatch.seconds, lastSeconds );

                this.$refs.video.player.setCurrentTime(lastSeconds).then(() => {
                    vm.play();
                })
            }
        },
        onWatch() { },

        async updatePlayer(eventName) {
            var vm = this;

            if (vm.isUpdate) return false;
            vm.isUpdate = true;

            if (vm.dataWatch.total_seconds >= vm.dataWatch.duration) {
                vm.dataWatch.total_seconds = vm.dataWatch.duration
            }

            let response = null

            try {
                const payloadWatch = { ...this.watch, ...this.dataWatch }

                // 
                if (['close-window', 'onPause', 'onEnded', 'verified'].indexOf(eventName) >= 0) {
                    await this.updatePlayerToDatabase(this.dataWatch)
                }

                if (payloadWatch.id) {
                    response = await this.updatePlayerToFirebase(payloadWatch)
                }
                else if (!eventName) {
                    response = await this.updatePlayerToDatabase(this.dataWatch)

                    if (response) {
                        this.beforePlayer(response)
                    }
                }

                this.setProgressbar({
                    seconds: response.seconds,
                    percent: response.percent,
                    duration: response.duration,
                })

            } catch (error) {
                this.isError = true;
                this.playerRunning = false
                this.pause()
            }

            /** */

            this.countSecondUpdateData = 0;
            vm.isUpdate = false;
            return response
        },

        async updatePlayerToDatabase(payload) {
            // console.log('updatePlayerToDatabase...', payload);

            try {
                const { data: response } = await axios.put(`/apis/video/${this.videoId}/watch`, payload)
                return response.watch;
            } catch (error) {
                console.error("Error adding document: ", error);

                if (error.response) {
                    this.errors = error.response.data
                }

                return false

            }
        },

        async updatePlayerToFirebase(payload) {
            // const path = `watch-${payload.id}`
            // console.log('updatePlayerToFirebase...', payload);

            try {
                await setDoc(doc(firebaseDatabase, `watch/${payload.id}`), payload)
                return payload
            } catch (error) {
                return false
            }
        },

        async getWatch() {
            this.isUpdate = true;

            try {
                const { data: response } = await axios.put(`/apis/video/${this.videoId}/watch`, this.dataWatch)

                // console.log(response.watch);
                const watch = response.watch
                // console.log('getWatch response', response);

                if (response) {
                    this.beforePlayer(watch)
                    this.playerReady = true;
                }

            } catch (error) {
                // console.log('getWatch: error', error);
            }

            this.isUpdate = false;
        },

        async getWatchFormDatabase() {

            try {

            } catch (error) {
                return false
            }
        },

        async getWatchFormFrebase() {

            try {
                const docRef = doc(firebaseDatabase, `watch/${this.watch.id}`);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    return docSnap.data()
                } else {
                    return false
                }

            } catch (error) {
                // console.log('getWatchFormDatabase: error =>', error);
                return false
            }
        },

        // control player
        play() {
            this.$refs.video.play()
            // iframe .autoplay
            // console.log( this.$refs.video.player.element );
            // this.$refs.video.player.play()
        },
        pause() {
            this.$refs.video.pause()
        },

        // funtion
        pad(val) {
            var valString = parseInt(val) + "";

            if (valString.length < 2) {
                return "0" + valString;
            } else {
                return valString;
            }
        },

        setPlayer() {
            if (this.playerReady) {
                if (this.playerRunning) {
                    this.pause();
                }
                else {
                    this.play();
                }
            }
        },
        toggleFullscreen() {
            // this.$refs.video.player.setFullscreen()
        },

        setProgressbar(data) {
            let seconds = data.seconds;
            const duration = parseInt(data.duration || this.dataWatch.duration);

            // 
            const percent = (data.percent * 100);
            // this.progressbar = percent.toFixed(2)
            // let watch_time_percent = ((seconds * 100) / this.data.duration).toFixed(2);

            // เวลาในการรับชม
            let totalSeconds = this.dataWatch.total_seconds
            if (totalSeconds >= duration) {
                totalSeconds = duration
            }

            this.setWatchTime(duration, totalSeconds)
        },

        progressBarMove(evt) {
            if (!this.startPlayer) return false;

            const vm = this;
            const $el = $(evt.target);

            let left = evt.pageX - $el.offset().left;
            let width = $el.outerWidth();

            const duration = vm.dataWatch.duration

            vm.pointerSeconds = (duration * left) / width

            const seconds = vm.pad(vm.pointerSeconds % 60);
            const minutes = vm.pad(parseInt(vm.pointerSeconds / 60));

            vm.pointer_bar = minutes + ':' + seconds
            vm.pointerBarStyle.left = left + 'px';
        },

        progressBarClick(evt) {
            const vm = this
            if (!vm.playerReady) return false;

            if (vm.pointerSeconds > vm.dataWatch.total_seconds) return false;

            this.$refs.video.player.setCurrentTime(vm.pointerSeconds).then(() => {
                vm.updateVideo();
            })
        },

        async _playPlayer() {

            return false;
        },

        async setWatchTime(duration, seconds) {


            // duration = เวลาทั้งหมด = 100 %
            // seconds = เวลาปจัจจุบัน = ?seconds
            // const currentPercent = (seconds * duration) / 100 //(duration / 100) * seconds

            // let percent = (seconds * 100)
            // const watchTimePercent = percent > 0 ? (percent / duration).toFixed(0) : 0;
            const watchTimePercent = Math.floor((seconds * 100) / duration)

            const watch_time_seconds = this.pad(seconds % 60);
            const watch_time_minutes = this.pad(parseInt(seconds / 60));

            // update ui
            this.watch_time_text = `${watch_time_minutes}:${watch_time_seconds}`
            this.watch_time_percent = watchTimePercent

            // update data
            this.dataWatch.total_seconds = seconds
            this.dataWatch.total_second_percent = watchTimePercent;

            this.progressbar = watchTimePercent;
        },

        tryAgain() {
            this.playerReady = false
            this.getVideo();
        },

        btnLogin() {
            window.location.href = `/${this.event.slug}/login?next=/${this.event.slug}/online-workshop/${this.learnId}`
        },
        donwloadCertificate() {
            var url = `/${this.event.slug}/online-workshop/${this.learnId}/certificate`
            window.open(url, '_blank');
        },
        donwloadSurvey() {
            var url = `/${this.event.slug}/online-workshop/${this.learnId}/survey`
            window.open(url, '_blank');
        },

        activeSurveyGoogleForm() {
            // const url = `/${this.event.slug}/online-workshop/${this.learnId}/survey/googleform`
            const url = this.surveyGoogleForm?.link
            if (url) {
                window.open(url, '_blank');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.player-controls {
    position: absolute;
    inset: 0;

    z-index: 1;

    width: 100%;
    // background-color: rgba(0,0,0,.5);
    background-image: -webkit-gradient(linear,
            left top,
            left bottom,
            from(rgba(20, 21, 22, 0.3)),
            to(#141516));
    background-image: linear-gradient(to bottom,
            rgba(20, 21, 22, 0.3),
            #141516);

    .player-loading {
        position: absolute;

        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        transition: all 0.2s ease;
        color: #fff;
    }
}

.video-player--center-button {
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    transition: all 0.2s ease;

    button {
        border-width: 0;

        width: 96px;
        height: 96px;

        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.3);

        display: flex;
        justify-content: center;
        align-items: center;
        color: #f7f9fa;

        svg {
            fill: currentColor;
        }
    }
}

.video-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    bottom: 0;

    opacity: 0;
    z-index: 1;
    transition: opacity 250ms;

    &.ready {
        opacity: 1;
    }
}

.video-status {
    padding: 0;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    opacity: 0;
    overflow: hidden;

    transition: all .1s cubic-bezier(0.25, 1, 0.5, 1);

    &.active {
        opacity: 1;
        padding: 0px 6px;
    }
}

.video-player-error {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;

    .code {
        font-size: 24px;
        font-weight: bold;
        color: #cddc39;
    }

    .message {
        font-size: 16px;
    }
}
</style>
