<template>
    <div class="d-lg-flex signin-layout">
        <div class="h-100 d-lg-flex flex-column justify-content-center h-100">
            <verify-form ref="verifyForm" :event="event" :callingcodes="callingcodes" :countryCode="countryCode"
                :phoneNumber="verifyPhoneNumber" :redirectPath="url(redirectPath)" :token="token"
                @goToLoginForm="goToLoginForm" v-if="isVerifyOTP" />
            <div v-else class="form-signin">
                <div class="text-center">
                    <h1 class="fw-700">{{ $t("Login") }}</h1>
                </div>
                <div class="signin-section d-flex flex-row justify-content-center">

                    <form @submit.prevent="loginUser" class="signin-with-email">

                        <div class="alert mb-3 alert-warning font-weight-bold fs-90p">
                            *สำหรับสมาชิกที่มีอีเมลและรหัสผ่านเดิมอยู่แล้ว สามารถใช้อีเมลและรหัสผ่าน
                            เข้าสู่ระบบลงทะเบียนได้เลย
                        </div>

                        <div class="d-flex justify-content-center mb-3">
                            <div class="signin-switch">
                                <div class="signin-switch-item">
                                    <button type="button" :class="withEmail && `active`"
                                        @click="withEmail = true">Email</button>
                                </div>
                                <div class="signin-switch-item">
                                    <button type="button" :class="!withEmail && `active`"
                                        @click="withEmail = false">Phone
                                        Number</button>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="alert alert-danger mb-3" v-if="error">
                            <span>{{ error.message }}</span>
                        </div> -->
                        <div class="mb-3" v-if="withEmail">
                            <label for="email">{{ $t("email") }}</label>
                            <input id="email" type="text" required="required" autocomplete="email" autofocus="autofocus"
                                class="form-control form-control-lg" v-model="email" :class="isLoad && `disabled`"
                                :disabled="isLoad" />
                        </div>
                        <div class="mb-3" v-else>
                            <label for="phoneNumber">{{ $t("Phone Number") }}</label>

                            <!-- <div class="phoneNumberInput-wrap row no-gutters"> -->
                            <!-- <div class="mb-2">
                                <select class="custom-select custom-select-lg" v-model="countryCode">
                                    <option value=""></option>
                                    <option :value="item.dial_code" v-for="(item, index) in callingcodes" :key="index">
                                        {{ item.name }} ({{ item.dial_code }})</option>
                                </select>
                            </div> -->
                            <div class="">
                                <input id="phoneNumber" type="tel" required="required"
                                    class="form-control form-control-lg" v-model="phoneNumber"
                                    :class="isLoad && `disabled`" :disabled="isLoad" maxlength="10" />
                            </div>
                            <!-- </div> -->

                        </div>

                        <div class="mb-3">
                            <label for="password">{{ $t("password") }}</label>
                            <input id="password" type="password" required="required" autocomplete="current-password"
                                class="form-control form-control-lg" v-model="password" :class="isLoad && `disabled`"
                                :disabled="isLoad" />
                            <div class="mt-1" v-if="isForgotPassword">
                                <a class="fs-13 font-weight-bold text-danger" :href="url('/reset-password')">{{
                                $t("Forgot Password")
                                }}</a>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-lg btn-primary btn-block mt-4"
                            :class="[isLoad && `disabled`, error && `btn-error`]" :disabled="isLoad">
                            <span class="text-white fw-600">{{
                            isLoad ? `${$t("Loading")}...` : $t("Login")
                            }}</span>
                        </button>

                        <div class="signin-section-ro my-4 text-center fs-12 fw-700" v-if="loginWithSocial">
                            <span class="line"></span>
                            <span class="text">{{ $t("orLogin") }}</span>
                            <span class="line"></span>
                        </div>

                        <login-social-form :event="event" v-if="loginWithSocial" :isLoad="isLoad" :is-login="true" />

                        <div class="text-muted mt-3 fs-12 text-center">
                            <p class="mb-0 fs-90p">
                                การดำเนินการต่อหมายความว่าคุณยอมรับ<a href="https://educathai.com/privacy-policy"
                                    target="_blank">เงื่อนไขการให้บริการ</a>ของ Educa และรับทราบว่าคุณได้อ่าน<a
                                    :href="url('/privacy-policy')" target="_blank">นโยบายส่วนบุคคล</a>แล้ว
                            </p>
                        </div>

                        <div class="mt-5 text-center">
                            <!-- <div>{{ $t("New User") }} ?</div> -->
                            <a class="btn btn-block btn-primary" :href="url('/register')">{{ $t("Create New Account") }}
                                ({{ $t("New User") }})</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import VerifyForm from '../components/VerifyForm'
import LoginSocialForm from '../components/AuthWithSocial'

export default {
    name: 'LoginPage',
    props: ['event', 'baseUrl', 'loginWithSocial', 'isForgotPassword','redirectUrl'],

    components: { VerifyForm, LoginSocialForm },

    data() {
        return {
            email: "",
            phoneNumber: '',
            password: "",
            countryCode: '+66',

            checkbox_remember_me: false,
            mode: "Development",

            withEmail: true,

            error: null,
            isLoad: false,
            redirectPath: '/',

            callingcodes: [],

            isVerifyOTP: false,
            verifyPhoneNumber: '',

            token: null,

            //The password you entered is incorrect, you have 4 more chances.
        };
    },
    created() {
        // console.log(process.env);
        // this.mode = process.env.VUE_APP_EVENT_ID
        if (process.env.APP_ENV != "development") {
            this.mode = "Production";
        }
    },

    async mounted() {

        // console.log(this.event);

        // await get
        // const {data: response}
        await this.getCountryCode()
    },

    methods: {
        url(path) {
            return `/${this.event.slug}${path}`
        },
        async getCountryCode() {
            const { data: response } = await axios.get('/api/country/code')

            if (response.success) {
                this.callingcodes = response.data
            }
        },
        async loginUser() {
            this.isLoad = true;
            this.error = null

            let payload = {
                email: this.withEmail ? this.email : null,
                password: this.password,
                phoneNumber: this.withEmail ? null : this.phoneNumber,
                withEmail: this.withEmail ? 1 : 0,
                countryCode: this.countryCode,
                eventId: this.event.id,
                redirectUrl : this.redirectUrl ,
            };

            await this.timeout(3);

            try {
                const { data: response } = await axios.post(this.url('/login'), payload)
                if (response.success) {

                    const redirectUrl = response.redirectUrl || this.url(this.redirectPath)

                    if (response.verified) {

                        this.$swal({
                            icon: 'success',
                            title: response.intro || this.$t('Login Welcome'),
                            text: response.message,
                        }).then(() => {
                            window.location.href = redirectUrl
                        });
                    }
                    else {

                        // set token to verify form
                        this.verifyPhoneNumber = response.data.phoneNumber
                        this.token = response.token
                        // console.log( this.$refs.verifyForm );
                        // if (this.$refs.verifyForm) {
                        //     this.$refs.verifyForm.setToken(response.token)
                        //     this.$refs.verifyForm.setPhoneNumber(data.phoneNumber)
                        // }

                        this.isVerifyOTP = true
                    }
                }
                else {
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });
                }
            } catch (error) {
                const { data } = error.response
                this.error = data

                let swalOps = {
                    icon: 'error',
                    title: data.intro || this.$t('Oops'),
                    text: data.message,
                }

                if (data.errorEmail) {
                    swalOps.html = `ไม่มี e-Mail นี้อยู่ในระบบ คลิกที่นี่เพื่อสมัคร <a class="font-weight-bold" href="${this.url('/register')}">สมาชิกใหม่</a>`
                }
                else if (data.errorPassword) {
                    swalOps.html = ` รหัสผ่านผิด กรุณาลองใหม่อีกครั้ง <a class="font-weight-bold" href="${this.url('/reset-password')}">ลืมรหัสผ่าน</a>`
                }

                this.$swal(swalOps);
            }

            this.isLoad = false
        },

        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        goToLoginForm() {
            this.email = ""
            this.phoneNumber = ""
            this.password = ""

            this.isVerifyOTP = false
        }
    }
};
</script>