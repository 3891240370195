<template>
    <div class="byteark-player-container">
      <video controls
        ref="videoElement"
        :controls="playerOps.controls"
        :autoplay="playerOps.autoplay"
        :width="width"
        :height="height"

        @play="onPlay"
        @pause="onPause"
        @ended="onEnded"
        @timeupdate="onTimeupdate"
        @error="onError"

      >
        <source :src="videoSrc" type="video/mp4" >
    </video>
    </div>
  </template>
  
  <script>
  export default {
    name: "ByteArk",
    props: {
      videoSrc: {
        type: String,
        required: true
      },
      playerOps: {
        type: Object,
        default: () => ({
          autoplay: false,
          controls: true,
        })
      },
      width: {
        type: Number,
        default: 640
      },
      height: {
        type: Number,
        default: 360
      }
    },
    data() {
      return {
        playerReady: false,
        playerError: false
      };
    },
 
    methods: {
      pause(){
        console.log('pause');
        this.onPause();
      },
      play(){
        console.log(this.videoSrc);
        this.onPlay();

      },
      onReady() {
        this.playerReady = true;
        this.$emit('ready');
      },
      onPlay() {
        const video = this.$refs.videoElement;
        video.play();
        this.$emit('play');
      },
      onPause() {
        this.$emit('pause');
      },
      onEnded() {
        this.$emit('ended');
      },
      onTimeupdate() {
        const video = this.$refs.videoElement;
        const data = {
          currentTime: video.currentTime,
          duration: video.duration,
          percent: (video.currentTime / video.duration) * 100
        };
        this.$emit('timeupdate', data);
      },
      onError(e) {
        this.playerError = true;
        this.$emit('error', e);
      },
      setCurrentTime(seconds) {
        const video = this.$refs.videoElement;
        video.currentTime = seconds;
      }
    },
    mounted() {
      const t = this;
      setTimeout(function(){
        t.$refs.videoElement.addEventListener('loadeddata', this.onReady);
      },1500);
    },
    created(){
       
    },
    beforeDestroy() {
      this.$refs.videoElement.removeEventListener('loadeddata', this.onReady);
    }
  };
  </script>
  
  <style scoped>
  .byteark-player-container {
    position: relative;
    width: 100%;
    height: auto;
  }
  video {
    width: 100%;
    height: auto;
  }
  </style>
  