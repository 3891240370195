<template>
        <div class="order-summary-main">
            <div class="content-form-center" style="position: relative">
                <h4 class="header-4">BILLING ADDRESS</h4>
                <div class="form-row" v-if="!isFormShow">
                    <div class="form-group col-md-6">
                        <div class="form-group row">
                            <label for="staticEmail" class="col-sm-4 col-form-label bold-size">BILLING ADDRESS</label>
                            <div class="col-sm-8">
                                <label  class="col-form-label">{{form.billingAddress.full_name}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label bold-size">CURRENT ADDRESS</label>
                            <div class="col-sm-8">
                                <label  class="col-form-label">{{form.first_name}} {{form.last_name}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button  @click="toggleForm" type="button" class="btn bluebtn editprofile" style=" width: 104px;position: absolute;top:10px;right: 10px;">{{text_edit_profile}}</button>
                 
                    <div class="form-row" style=" margin-top: 25px"  v-if="isFormShow"  >
                        <div class="form-row"   >
                            <!-- ที่อยู่ปัจุบัน -->
                            <div class="form-row" style="width: 100%">
                                <form class="info-section" >
                                    <div class="card card-info mb-4"  style="border:none">
                                        <div class="card-body">
                                            <h4 class="header-4">Current Address / ที่อยู่ปัจจุบัน</h4>
                                            <div>

                                                <div class="form-row">
                                                  <div class="col-md-12 mb-3">
                                                    <label class="font-weight-bold d-flex" for="">
                                                        <span class="mr-1">Address / ที่อยู่</span>
                                                        <span class="text-danger">*</span>
                                                    </label>

                                                    <input type="text" class="form-control" v-model="form.address_no"
                                                        :class="[errors.address_no && `is-invalid`]" />

                                                    <div class="invalid-feedback" v-if="errors.address_no">{{
                                                        errors.address_no 
                                                        }}</div>
                                                  </div>
                                                </div>

                                                <div class="form-row">

                                                    <div class="col-md-4 mb-3">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">Province / จังหวัด</span>
                                                            <span class="text-danger">*</span>
                                                        </label>

                                                        <select class="custom-select" v-model="form.address_province_id"
                                                            :class="[errors.address_province_id && `is-invalid`]">
                                                            <option value="" disabled>Please select / โปรดเลือก</option>
                                                            <option :value="item.id" v-for="(item, index) in provinces" :key="index">{{  `${item.name_en}
                                                            / ${item.name_th}`
                                                                }}</option>
                                                        </select>

                                                        <div class="invalid-feedback" v-if="errors.address_province_id">{{
                                                            errors.address_province_id 
                                                            }}</div>
                                                    </div>

                                                    <div class="col-md-4 mb-3">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">District / อำเภอหรือเขต</span>
                                                            <span class="text-danger">*</span>
                                                        </label>

                                                        <select class="custom-select" :disabled="amphureList.length == 0"
                                                            v-model="form.address_amphure_id" :class="[errors.address_amphure_id && `is-invalid`]">
                                                            <option value="" disabled>Please select / โปรดเลือก</option>
                                                            <option :value="item.id" v-for="(item, index) in amphureList" :key="index">{{
                                                                `${item.name_en} / ${item.name_th}` 
                                                                }}</option>
                                                        </select>

                                                        <div class="invalid-feedback" v-if="errors.address_amphure_id">{{
                                                            errors.address_amphure_id 
                                                            }}</div>
                                                    </div>

                                                    <div class="col-md-4 mb-3">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">Sub District / ตำบลหรือแขวง</span>
                                                            <span class="text-danger">*</span>
                                                        </label>

                                                        <select class="custom-select" :disabled="districtList.length == 0"
                                                            v-model="form.address_district_id" :class="[errors.address_district_id && `is-invalid`]">
                                                            <option value="" disabled>Please select / โปรดเลือก</option>
                                                            <option :value="item.id" v-for="(item, index) in districtList" :key="index">{{
                                                                `${item.name_en} / ${item.name_th}` 
                                                                }}</option>
                                                        </select>

                                                        <div class="invalid-feedback" v-if="errors.address_district_id">{{
                                                            errors.address_district_id 
                                                            }}</div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">Postal Code / รหัสไปรษณีย์</span>
                                                            <span class="text-danger">*</span>
                                                        </label>

                                                        <input type="text" class="form-control" v-model="form.address_zip"
                                                            :class="[errors.address_zip && `is-invalid`]" />

                                                        <div class="invalid-feedback" v-if="errors.address_zip">{{
                                                            errors.address_zip 
                                                            }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

 

                            <div class="form-row"   >
                                <div class="form-row">
                                    <form class="info-section" >
                                        <div class="card card-info mb-4"  style="border:none">
                                            <div class="card-body">
                                                <h4 class="header-4">Billing Address / ที่อยู่ในการออกใบเสร็จรับเงิน</h4>

                                                <div class="form-row"   >
                                                    <div class="mb-3"> 
                                                        <div class="custom-control custom-checkbox mb-12">
                                                            <input type="checkbox" id="billing-address-same-current-address" class="custom-control-input"
                                                                v-model="form.billingAddressSameCurrent">
                                                            <label for="billing-address-same-current-address" class="custom-control-label">My billing address is
                                                                the same current address / ที่อยู่ในการออกใบเสร็จรับเงิน
                                                                ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div  v-if="!this.form.billingAddressSameCurrent" >
                                                    <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">Recipient Name / ชื่อผู้รับ</span>
                                                            <span class="text-danger">*</span>
                                                        </label>

                                                        <input type="text" class="form-control" v-model="form.billingAddress.full_name"
                                                            :class="[errors.billing_full_name && `is-invalid`]" />
                                                        <div class="invalid-feedback" v-if="errors.billing_full_name">{{
                                                            errors.billing_full_name 
                                                            }}</div>
                                                    </div>

                                                    <div class="col-md-8 mb-3">
                                                        <label class="font-weight-bold d-flex" for="">
                                                            <span class="mr-1">Address / ที่อยู่</span>
                                                            <span class="text-danger">*</span>
                                                        </label>

                                                        <input type="text" class="form-control" v-model="form.billingAddress.address"
                                                            :class="[errors.billing_address && `is-invalid`]" />
                                                        <div class="invalid-feedback" v-if="errors.billing_address">{{
                                                            errors.billing_address 
                                                            }}</div>
                                                    </div>
                                                    </div>

                                                    <div class="form-row">

                                                        <div class="col-md-4 mb-3">
                                                            <label class="font-weight-bold d-flex" for="">
                                                                <span class="mr-1">Province / จังหวัด</span>
                                                                <span class="text-danger">*</span>
                                                            </label>

                                                            <select class="custom-select" v-model="form.billingAddress.province_id"
                                                                :class="[errors.billing_province_id && `is-invalid`]">
                                                                <option value="" disabled>Please select / โปรดเลือก</option>
                                                                <option :value="item.id" v-for="(item, index) in provinces" :key="index">{{
                                                                    `${item.name_en}
                                                                    / ${item.name_th}`
                                                                    }}</option>
                                                            </select>
                                                            <div class="invalid-feedback" v-if="errors.billing_province_id">{{
                                                                errors.billing_province_id 
                                                                }}</div>
                                                        </div>

                                                        <div class="col-md-4 mb-3">
                                                            <label class="font-weight-bold d-flex" for="">
                                                                <span class="mr-1">District / อำเภอหรือเขต</span>
                                                                <span class="text-danger">*</span>
                                                            </label>

                                                            <select class="custom-select" v-model="form.billingAddress.amphures_id"
                                                                :disabled="billingAmphureList.length == 0"
                                                                :class="[errors.billing_amphures_id && `is-invalid`]">
                                                                <option value="" disabled>Please select / โปรดเลือก</option>
                                                                <option :value="item.id" v-for="(item, index) in billingAmphureList" :key="index">{{
                                                                    `${item.name_en} / ${item.name_th}` 
                                                                    }}</option>
                                                            </select>
                                                            <div class="invalid-feedback" v-if="errors.billing_amphures_id">{{
                                                                errors.billing_amphures_id 
                                                                }}</div>
                                                        </div>

                                                        <div class="col-md-4 mb-3">
                                                            <label class="font-weight-bold d-flex" for="">
                                                                <span class="mr-1">Sub District / ตำบลหรือแขวง</span>
                                                                <span class="text-danger">*</span>
                                                            </label>

                                                            <select class="custom-select" :disabled="billingDistrictList.length == 0"
                                                                v-model="form.billingAddress.district_id"
                                                                :class="[errors.billing_district_id && `is-invalid`]">
                                                                <option value="" disabled>Please select / โปรดเลือก</option>
                                                                <option :value="item.id" v-for="(item, index) in billingDistrictList" :key="index">{{
                                                                    `${item.name_en} / ${item.name_th}` 
                                                                    }}</option>
                                                            </select>
                                                            <div class="invalid-feedback" v-if="errors.billing_district_id">{{
                                                                errors.billing_district_id 
                                                                }}</div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <label class="font-weight-bold d-flex" for="">
                                                                <span class="mr-1">Postal Code / รหัสไปรษณีย์</span>
                                                                <span class="text-danger">*</span>
                                                            </label>

                                                            <input type="text" class="form-control" v-model="form.billingAddress.zipcode"
                                                                :class="[errors.billing_zipcode && `is-invalid`]" />
                                                            <div class="invalid-feedback" v-if="errors.billing_zipcode">{{  errors.billing_zipcode  }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="personal-information-footer-buttons"></div>
                                    </form>
                                </div>
                            </div>
 
                        </div>

 

                    </div>
                 
            </div>
        </div>
</template>

<script>
    const CURRENT_STEP = 1
    const PREFIX_OTHER = 'other'
    
    export default {
    props: ['form', 'config', 'provinces', 'event' ],
    async mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        if (this.form.address_province_id) {
            await this.getAmphureByProvinceId()

            if (this.form.address_amphure_id) {
                await this.getDistrictByAmphureId()
            }
        }

        if (!this.form.billingAddressSameCurrent && this.form.billingAddress.province_id) {
            await this.getBillingAmphure()

            if (this.form.billingAddress.amphures_id) {
                await this.getBillingDistrictByAmphureId()
            }
        }
 
    },

    watch: {
        'form.address_province_id'(val) {
            this.form.address_zip = ''
            if (val) this.getAmphureByProvinceId()

        },
        'form.address_amphure_id'(val) {
            this.form.address_zip = ''

            if (val) this.getDistrictByAmphureId()
        },
        'form.address_district_id'(id) {
            this.form.address_zip = ''

            if (id) {
                const district = this.districtList.find(n => n.id == id)
                this.form.address_zip = district ? district.zip_code : ''
            }
        },

        'form.billingAddress.province_id'(val) {
            this.form.billingAddress.zipcode = ''
            if (val) this.getBillingAmphure()

        },
        'form.billingAddress.amphures_id'(val) {
            this.form.billingAddress.zipcode = ''
            if (val) this.getBillingDistrictByAmphureId()
        },
        'form.billingAddress.district_id'(id) {
            this.form.billingAddress.zipcode = ''

            if (id) {
                const district = this.billingDistrictList.find(n => n.id == id)
                this.form.billingAddress.zipcode = district ? district.zip_code : ''
            }
        }
    },

    data() {
        const subscriptionList = [];
        subscriptionList.push({ id: 1, name: "Newsletter Subscription / ต้องการรับข่าวสาร" });
        subscriptionList.push({ id: 0, name: "I prefer not to receive newsletter / ไม่ต้องการรับข่าวสาร" });

        const channelList = [];
        channelList.push({ id: 1, name: "Email" });
        channelList.push({ id: 2, name: "SMS" });

        return {
            isFormShow : false,
            text_edit_profile : 'Edit',
            amphureList: [],
            amphureCaching: {},

            districtList: [],
            districtCaching: [],

            billingAmphureList: [],
            billingDistrictList: [],

            errors: {},
            isError: false,
            isLoad: false,

            // -- step 4
            subscriptionList,
            channelList,
            verifyPDPA: false,
            isLoadinForm: true,
        }
    },
    methods: {
        showForm(){
            this.isFormShow = true
        },
        docheckform(){
            console.log('docheckform !!!')
        },
        toggleForm(){
                this.isFormShow = !this.isFormShow;
                if(this.isFormShow){this.text_edit_profile = 'Hide'}else{this.text_edit_profile = 'Edit'}
        },
        eventBastUrl(path) {
            return `/${this.event.slug}${path}`
        },
        async saveConfirmation() {
            await this.timeout(3);

            if (!this.validateAddress()) {
                this.isError = true

                await this.timeout(3)
                this.focusInvalid()
            }
            else if (!this.verifyPDPA) {
                this.verifyPDPA = true
            }
            else {
                this.saveInformation()
            }
        },
        async saveInformation(acceptPDPA) {
            this.isLoad = true;

            this.form.acceptPDPA = acceptPDPA
            const titleLoading = this.$t('Loading')
            const swal = this.$swal({
                // text: this.$t('Loading'),
                showConfirmButton: false,
                background: 'transparent',
                backdrop: `rgba(0,0,0,0.9)`,
                title: "<h3 style='color: white;'>" + titleLoading + "...</h3>",
                html: "<div style='color: white;'>เรากำลังบันทึกข้อมูล, กรุณาอย่าปิดหน้านี้</div>",
                showLoaderOnConfirm: false,
                allowOutsideClick: false,
            });

            const payload = { ...this.form, eventId: this.event.id }
            await this.timeout(800);

            swal.close()

            try {
                const { data: response } = await axios.post('/api/personal-info', payload)
                if (response.success) {
                    swal.close()

                    this.$swal({
                        icon: 'success',
                        title: response.intro || this.$t('Success'),
                        text: response.message,
                    }).then(() => {

                        window.location.href = response.redirectUrl || this.eventBastUrl('/')
                    })
                }
            } catch (error) {
                const { data } = error.response
                this.error = data

                this.$swal({
                    icon: 'error',
                    title: data.intro || this.$t('Oops'),
                    text: data.message,
                });
            }

            this.config.agree = false
            this.isLoad = false
        },

        async updateAddress() {
            this.isLoad = true
            this.isError = false

            await this.timeout(3);
            if (this.validateAddress()) {
                this.$emit('done', CURRENT_STEP)
            }
            else {
                this.isError = true

                await this.timeout(3)
                this.focusInvalid()
            }

            this.isLoad = false
        },

        validateAddress() {
            const form = this.form
            this.errors = {}

            const rules = {
                //shipping_name: 'กรุณาระบุชื่อ',
                address_no: 'กรุณาระบุที่อยู่',
                address_province_id: 'กรุณาระบุจังหวัด',
                address_amphure_id: 'กรุณาระบุอำเภอหรือเขต',
                address_district_id: 'กรุณาระบุตำบลหรือแขวง',
                address_zip: 'กรุณาระบุรหัสไปรษณีย์',
            }

            for (const key in rules) {
                if (Object.hasOwnProperty.call(rules, key)) {
                    const message = rules[key];

                    if (!form[key]) {
                        this.errors[key] = message
                    }
                }
            }

            if (!this.form.billingAddressSameCurrent) {

                const billingRules = {
                    full_name: 'กรุณาระบุชื่อ',
                    address: 'กรุณาระบุที่อยู่',
                    province_id: 'กรุณาระบุจังหวัด',
                    amphures_id: 'กรุณาระบุอำเภอหรือเขต',
                    district_id: 'กรุณาระบุตำบลหรือแขวง',
                    zipcode: 'กรุณาระบุรหัสไปรษณีย์',
                }

                for (const key in billingRules) {
                    if (Object.hasOwnProperty.call(billingRules, key)) {
                        const message = billingRules[key];

                        if (!form.billingAddress[key]) {
                            this.errors[`billing_${key}`] = message
                        }
                    }
                }
            }

            return Object.keys(this.errors).length == 0
        },
     
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        // Address
        async getAmphureByProvinceId() {
            this.amphureList = []
            this.districtList = []

            const provinceId = this.form.address_province_id
            if (typeof this.amphureCaching[provinceId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/amphures', { params: { provinceId: provinceId } })

                    if (response.success) {
                        this.amphureCaching[provinceId] = response.data
                    }
                    else {
                        this.form.address_province_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.address_province_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.amphureList = this.amphureCaching[provinceId] || []
        },
        async getDistrictByAmphureId() {
            this.districtList = []

            const amphureId = this.form.address_amphure_id
            if (typeof this.districtCaching[amphureId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/districts', { params: { amphureId: amphureId } })

                    if (response.success) {
                        this.districtCaching[amphureId] = response.data
                    }
                    else {
                        this.form.address_amphure_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.address_amphure_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.districtList = this.districtCaching[amphureId] || []
        },

        // Billing
        async getBillingAmphure() {
            this.billingAmphureList = []
            this.billingDistrictList = []

            const provinceId = this.form.billingAddress.province_id
            if (typeof this.amphureCaching[provinceId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/amphures', { params: { provinceId: provinceId } })

                    if (response.success) {
                        this.amphureCaching[provinceId] = response.data
                    }
                    else {
                        this.form.billingAddress.province_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.billingAddress.province_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.billingAmphureList = this.amphureCaching[provinceId] || []
        },
        async getBillingDistrictByAmphureId() {
            this.billingDistrictList = []

            const amphureId = this.form.billingAddress.amphures_id
            if (typeof this.districtCaching[amphureId] === 'undefined') {
                try {
                    const { data: response } = await axios.get('/api/districts', { params: { amphureId: amphureId } })

                    if (response.success) {
                        this.districtCaching[amphureId] = response.data
                    }
                    else {
                        this.form.billingAddress.amphures_id = ''
                        this.$swal({
                            icon: 'error',
                            title: this.$t('Oops'),
                            text: this.$t('OopsMessage'),
                        });
                    }

                } catch (error) {
                    this.form.billingAddress.amphures_id = ''
                    this.$swal({
                        icon: 'error',
                        title: this.$t('Oops'),
                        text: this.$t('OopsMessage'),
                    });

                }
            }

            this.billingDistrictList = this.districtCaching[amphureId] || []
        },

        focusInvalid() {
            const $invalid = $('.is-invalid')

            if ($invalid.length == 0) return
            $invalid[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        },

        async autoComplete(e) {
            if (!e.ctrlKey || !e.shiftKey) return false;

            //this.form.shipping_name = `ภุชงค์ สวนแจ้ง`
            this.form.address_no = `252 ถ.ลาดพร้าววังหิน`

            this.form.address_province_id = `1`
            this.form.address_amphure_id = `38`
            this.form.address_district_id = `103801`


            await this.timeout(10)
            this.form.address_zip = `10230`

            this.form.billingAddressSameCurrent = true
        },
    }
}
    </script>