<template>
    <div class="card card-info mb-4">
        <div class="card-header">
            <h3 @click="autoComplete">Information / ข้อมูลส่วนตัว</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 mb-3">
                    <label class="font-weight-bold" for="prefixInput">Title / คำนำหน้าชื่อ (ภาษาไทย)</label>

                    <div :class="[config.prefixId == PREFIX_OTHER && `input-group`, errors.prefix && `is-invalid`]">
                        <select id="prefixInput" class="custom-select" v-model="config.prefixId"
                            :class="[isLoad && `disabled`]">
                            <option value="">Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in prefixs" :key="index">{{ item.name }}
                            </option>
                        </select>

                        <input v-if="config.prefixId == PREFIX_OTHER" type="text" class="form-control"
                            v-model="form.prefix" placeholder="ระบุ" autocomplete="off" />
                    </div>

                    <div class="invalid-feedback" v-if="errors.prefix">{{ errors.prefix }}</div>
                </div>
                <div class="col-md">

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label class="font-weight-bold d-flex" for="firstNameInput">
                                <span class="mr-1">Name / ชื่อ (ภาษาไทย)</span>
                                <span class="text-danger">*</span>
                            </label>

                            <input type="text" class="form-control" id="firstNameInput"
                                :class="[isLoad && `disabled`, errors.first_name && `is-invalid`]"
                                v-model="form.first_name" />

                            <div class="invalid-feedback" v-if="errors.first_name">{{ errors.first_name }}</div>
                        </div>

                        <div class="col-md-6 mb-3">
                            <label class="font-weight-bold d-flex" for="lastNameInput">
                                <span class="mr-1">Surname / นามสกุล (ภาษาไทย)</span>
                                <span class="text-danger">*</span>
                            </label>

                            <input type="text" class="form-control" id="lastNameInput"
                                :class="[isLoad && `disabled`, errors.last_name && `is-invalid`]"
                                v-model="form.last_name" />

                            <div class="invalid-feedback" v-if="errors.last_name">{{ errors.last_name }}</div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="row">
                <div class="col-md-4 mb-3 d-none d-md-block">
                    <label class="font-weight-bold" for="prefixEnInput">Title / คำนำหน้าชื่อ (ภาษาอังกฤษ)</label>

                    <div
                        :class="[config.prefixId == PREFIX_OTHER && `input-group`, errors.prefix_en && `is-invalid`]">
                        <select id="prefixInput" class="custom-select" v-model="config.prefixId"
                            :class="[isLoad && `disabled`]">
                            <option value="">Please select / โปรดเลือก</option>
                            <option :value="item.id" v-for="(item, index) in prefixs" :key="index">{{ item.name }}
                            </option>
                        </select>

                        <input v-if="config.prefixId == PREFIX_OTHER" type="text" class="form-control"
                            v-model="form.prefix_en" placeholder="ระบุ" autocomplete="off" />
                    </div>

                    <div class="invalid-feedback" v-if="errors.prefix_en">{{ errors.prefix_en }}</div>
                </div>

                <div class="col-md">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label class="font-weight-bold d-flex" for="firstNameEnInput">
                                <span class="mr-1">Name / ชื่อ (ภาษาอังกฤษ)</span>
                                <span class="text-danger">*</span>
                            </label>

                            <input type="text" class="form-control" id="firstNameEnInput"
                                :class="[isLoad && `disabled`, errors.first_name_en && `is-invalid`]"
                                v-model="form.first_name_en" />

                            <div class="invalid-feedback" v-if="errors.first_name_en">{{ errors.first_name_en }}
                            </div>
                        </div>

                        <div class="col-md-6 mb-3">
                            <label class="font-weight-bold d-flex" for="lastNameEnInput">
                                <span class="mr-1">Surname / นามสกุล (ภาษาอังกฤษ)</span>
                                <span class="text-danger">*</span>
                            </label>

                            <input type="text" class="form-control" id="lastNameEnInput"
                                :class="[isLoad && `disabled`, errors.last_name_en && `is-invalid`]"
                                v-model="form.last_name_en" />

                            <div class="invalid-feedback" v-if="errors.last_name_en">{{ errors.last_name_en }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <label class="font-weight-bold d-flex" for="">
                    <span class="mr-1">Gender / เพศ</span>
                    <span class="text-danger">*</span>
                </label>

                <div class="d-md-flex radio-wrap" :class="[errors.gender && `is-invalid`]">
                    <div v-for="(gender, index) in genders" :key="index" class="custom-control custom-radio mr-3">
                        <input type="radio" name="gender" :id="`gender-${gender.id}`" :value="gender.id"
                            class="custom-control-input" v-model="form.gender" />
                        <label :for="`gender-${gender.id}`" class="custom-control-label">{{ gender.name }}</label>
                    </div>
                </div>
                <div class="invalid-feedback" v-if="errors.gender">{{ errors.gender }}</div>
            </div>

            <div class="mb-3">
                <label class="font-weight-bold d-flex" for="">
                    <span class="mr-1">Date of Birth / วันเกิด</span>
                    <span class="text-danger">*</span>
                </label>

                <div class="row" :class="[errors.birthday && `is-invalid`]">
                    <div class="col-md-3 mb-1 mb-md-0">
                        <select class="custom-select" v-model="config.day">
                            <option value="" disabled>Day / วันเกิด</option>
                            <option :value="item.id" v-for="(item, index) in days" :key="index">{{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 mb-1 mb-md-0">
                        <select class="custom-select" v-model="config.month">
                            <option value="" disabled>Month / เดือนเกิด</option>
                            <option :value="item.id" v-for="(item, index) in months" :key="index">{{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 mb-1 mb-md-0">
                        <select class="custom-select" v-model="config.year">
                            <option value="" disabled>Year / ปีเกิด (ค.ศ.)</option>
                            <option :value="item.id" v-for="(item, index) in years" :key="index">{{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md">
                        <div v-if="age">
                            <span class="fs-80p">Age / อายุ: </span>
                            <strong>{{ age }} Years / ปี</strong>
                        </div>
                    </div>
                </div>

                <div class="invalid-feedback" v-if="errors.birthday">{{ errors.birthday }}</div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label class="font-weight-bold d-flex" for="phoneNumberInput">
                        <span class="mr-1">Mobile No. / เบอร์ติดต่อ</span>
                        <span class="text-danger">*</span>
                    </label>

                    <input type="text" class="form-control" id="phoneNumberInput" readonly
                        v-model="form.phone_number" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const CURRENT_STEP = 1
const PREFIX_OTHER = 'other'

export default {
    props: ['form', 'config', 'member', 'prefixs', 'errors'],

    data() {
        
        const genders = [];
        genders.push({ id: '1', name: "Male (ชาย)" });
        genders.push({ id: '2', name: "Female (หญิง)" });
        genders.push({ id: '3', name: "Not specific (ไม่ระบุ)" });


        const days = []
        for (let index = 1; index <= 31; index++) {
            days.push({ id: index < 10 ? `0${index}` : index, name: index })
        }

        const monthList = ['January / มกราคม', 'February / กุมภาพันธ์', 'March / มีนาคม', 'April / เมษายน', 'May / พฤษภาคม', 'June / มิถุนายน', 'July / กรกฎาคม', 'August / สิงหาคม', 'September / กันยายน', 'October / ตุลาคม', 'November / พฤศจิกายน', 'December / ธันวาคม']
        const months = []
        for (let index = 1; index <= 12; index++) {
            months.push({ id: index < 10 ? `0${index}` : index, name: monthList[index - 1] })
        }

        const theDate = new Date()
        const years = []
        for (let index = theDate.getFullYear() - 13; index >= theDate.getFullYear() - 100; index--) {
            years.push({ id: index, name: index })
        }

        return {
            genders,
            days,
            months,
            years,

            isError: false,
            isLoad: false,

            PREFIX_OTHER,
        }
    },

    computed: {
        birthday() {

            let birthday = '';
            if (this.config.year && this.config.month && this.config.day) {
                birthday = `${this.config.year}-${this.config.month}-${this.config.day}`

                this.form.birthday = birthday
            }

            return birthday
        },

        age() {
            if (!this.birthday) return '';

            var today = new Date();
            var birthDate = new Date(this.birthday);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age
        }
    },

    watch: {
        'config.prefixId'(val) {
            if (val != PREFIX_OTHER) {
                this.form.prefix = val
            }
            else {
                this.form.prefix = ''
            }
        }
    },

    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    methods: {
        autoComplete(e) {
            if (!e.ctrlKey || !e.shiftKey) return false;

            this.form.first_name = `ภุชงค์`
            this.form.last_name = `สวนแจ้ง`

            this.form.first_name_en = `Puchong`
            this.form.last_name_en = `Suanjang`

            this.form.gender = 1
            this.form.phone_number = `0843635952`

            this.config.prefixId = `นาย`
            this.config.day = `18`
            this.config.month = `06`
            this.config.year = `1990`
        },
    }
}
</script>